exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".GpJe2{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;min-height:100vh;width:100%;overflow-x:hidden}@media (min-width:1024px){.GpJe2{-ms-flex-direction:row;flex-direction:row}}.GpJe2 svg path{vector-effect:non-scaling-stroke}._1OVbk{height:70px;height:7rem;width:100%;background:#872c93;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:start;justify-content:flex-start}._6DMQK{width:130px;margin:0 2rem}._1Wo6g{-ms-flex:1 1 auto;flex:1 1 auto;height:100%}._3R97A{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;height:100%}@media (min-width:1024px){._3R97A{-ms-flex-align:center;align-items:center}}._3R97A a{font-weight:700}._3R97A .content{padding:3.5rem 2rem 10rem;font-size:14px;font-size:1.4rem;width:100%;max-width:640px;max-width:64rem}@media (min-width:600px){._3R97A .content{padding:3.5rem 0 10rem;width:80%}}@media (min-width:1024px){._3R97A .content{padding:5rem 0 10rem;width:62%}}@media (min-width:1280px){._3R97A .content{width:50%}}._3R97A .content--fixed{max-width:none!important}@media (min-width:1280px){._3R97A .content--fixed{width:115.6rem!important}}._3R97A .pageHeader{margin-bottom:6rem}._3R97A .pageHeader__intro{font-size:16px;font-size:1.6rem;color:#6f7ea2;line-height:1.2;padding-top:1rem}div:first-child>.alert{border-radius:8px}._3R97A{-ms-flex-align:start;align-items:flex-start}", ""]);

// exports
exports.locals = {
	"root": "GpJe2",
	"mobileLogo": "_1OVbk",
	"mobileLogo__logo": "_6DMQK",
	"container": "_1Wo6g",
	"containerInner": "_3R97A"
};