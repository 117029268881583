exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._23AnM{margin:8px 0;padding:8px;cursor:pointer;border-radius:8px;color:#fff}._23AnM:hover{background:#5570a2}.fcZc1{position:relative}html[dir=ltr] ._3dVJZ{margin-right:8px}html[dir=rtl] ._3dVJZ{margin-left:8px}._2Fcub{opacity:.4}.l8mEC{display:block;position:absolute;bottom:-3px;bottom:-.3rem;background-color:#f65663;height:10px;height:1rem;width:10px;width:1rem;border:2px solid #436198;border-radius:50%}html[dir=ltr] .l8mEC{right:3px;right:.3rem}html[dir=rtl] .l8mEC{left:3px;left:.3rem}", ""]);

// exports
exports.locals = {
	"moreClinicItem": "_23AnM",
	"moreClinicItem__avatarContainer": "fcZc1",
	"moreClinicItem__avatar": "_3dVJZ",
	"moreClinicItem__avatar--inactive": "_2Fcub",
	"moreClinicItem__avatarBadge": "l8mEC"
};