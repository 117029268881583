exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1J7nk{margin-left:5rem;margin-top:-4px;padding:0 1.5rem;color:#30a8ff;font-weight:400;font-size:16px;font-size:1.6rem;line-height:2}._1J7nk .btn__labelWrapper{padding:0}._3-Fi8{color:#30a8ff}._3Ceau{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}.VI8TU{fill:#30a8ff;height:20px;width:20px}._3uFhn{margin-top:-24px}", ""]);

// exports
exports.locals = {
	"toggleFormBtn": "_1J7nk",
	"editGlucoseBtn": "_3-Fi8",
	"icon": "_3Ceau",
	"icon__icon": "VI8TU",
	"form": "_3uFhn"
};