import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import range from 'lodash/range';
import get from 'lodash/get';
import withStyles from 'isomorphic-style-loader/withStyles';
import Draggable from 'react-draggable';
import AutoSizer from 'react-virtualized-auto-sizer';
import moment from 'moment';
import HorizontalArrow from 'svg/directionHorizontalArrow.svg';
import messages from '../../../messages';
import stylesRangesSlider from '../RangesSliders.pcss';
import styles from './CgmRangesSliderPartial.pcss';


/**
 * @DEPRECATED: To use move it and refactor to components/Sliders
 */
class CgmRangesSliderPartial extends React.PureComponent {

  constructor() {
    super();
    this.levels = {
      LOW : 'low',
      HIGH: 'high',
    };
    this.dayTimes = {
      DAY_START : 'dayStart',
      DAY_MIDDLE: 'dayMiddle',
      DAY_END   : 'dayEnd',
    };
    this.state = {};
  }

  static propTypes = {
    // Explicit props
    valueMin     : PropTypes.number,
    valueMax     : PropTypes.number,
    valueStep    : PropTypes.number,
    valueDragStep: PropTypes.number,
    timeStep     : PropTypes.number,
    timeDragStep : PropTypes.number,
    formValues   : PropTypes.object,
    conversion   : PropTypes.object,
    locked       : PropTypes.bool,

    // Explicit actions
    onRangeChange: PropTypes.func,
    onTimeChange : PropTypes.func,
    // Implicit props
  };

  static defaultProps = {
    valueMin     : 0,
    valueMax     : 300,
    valueStep    : 50,
    valueDragStep: 5,
    timeStep     : 3,
    timeDragStep : 0.25,
    locked       : false,
  }

  static getDerivedStateFromProps(props, state) {
    const { valueMax } = props;
    const defaultValues = {
      dayStart        : 9,
      dayEnd          : 20,
      glucoseDayHigh  : valueMax * 0.8,
      glucoseDayLow   : valueMax * 0.2,
      glucoseNightHigh: valueMax * 0.7,
      glucoseNightLow : valueMax * 0.3,
    };
    return {
      ...state,
      defaultValues,
    };
  }

  renderScaleValue() {
    const { valueMax, valueMin, valueStep } = this.props;
    return range(valueMin, valueMax + 1, valueStep).map(
      (value) => (
        <div className="scaleValue__value" key={value}>
          {value}
        </div>
      )
    );
  }


  renderScaleTime() {
    const { timeStep } = this.props;
    return range(0, 25, timeStep).map(
      (value) => (
        <div className="scaleTime__value" key={value}>
          {moment('00:00', 'hh:mm').add(value, 'hours').format('ha')}
        </div>
      )
    );
  }


  renderScaleGrid() {
    const { valueMax, valueMin, valueStep } = this.props;
    return range(valueMin, valueMax + 1, valueStep).map(
      (value) => (<div className="scaleGrid__row" key={value} />)
    );
  }


  renderScales() {
    return (
      <>
        <div className="scaleValue scaleValue--left">
          {this.renderScaleValue()}
        </div>
        <div className="scaleTime">
          {this.renderScaleTime()}
        </div>
        <div className="scaleGrid">
          {this.renderScaleGrid()}
        </div>
        <div className="scaleValue scaleValue--right">
          {this.renderScaleValue()}
        </div>
      </>
    );
  }


  renderRangeSliderHandler({
    dayTime,
    level,
    height,
    value,
  }) {
    return (
      <div
        className={
          cn(
            'rangeSlider',
            {
              'rangeSlider--top'   : level === this.levels.HIGH,
              'rangeSlider--bottom': level === this.levels.LOW,
            }
          )
        }
      >
        <div className="rangeSlider__area" style={{ height: `${height}px` }}>
          <FormattedMessage {...messages.labels[level]} />
        </div>
        <button
          className={
            cn(
              'rangeSlider__dragButton',
              {
                'rangeSlider__dragButton--start': dayTime === this.dayTimes.DAY_START,
                'rangeSlider__dragButton--end'  : dayTime === this.dayTimes.DAY_END,
              }
            )
          }
          type="button"
        >
          {`${value} ${this.props.conversion.unitSymbol}`}
        </button>
      </div>
    );
  }


  renderRangeSliders(width, height) {
    const { valueMax, valueMin, valueDragStep, formValues, locked } = this.props;
    const { defaultValues } = this.state;
    const countValue = (data) => {
      const offset = (height - data.y) / height * (valueMax - valueMin);
      return (Math.floor(offset) - (Math.floor(offset) % valueDragStep));
    };
    const countOffset = (value) => height - (value / (valueMax - valueMin) * height);
    const countTimeOffset = (hour) => hour / 24 * width;
    const values = {
      glucoseNightHigh: get(formValues, 'values.glucoseNightHigh', defaultValues.glucoseNightHigh),
      glucoseNightLow : get(formValues, 'values.glucoseNightLow', defaultValues.glucoseNightLow),
      glucoseDayHigh  : get(formValues, 'values.glucoseDayHigh', defaultValues.glucoseDayHigh),
      glucoseDayLow   : get(formValues, 'values.glucoseDayLow', defaultValues.glucoseDayLow),
    };
    const offsets = {
      glucoseNightHigh: countOffset(values.glucoseNightHigh),
      glucoseNightLow : countOffset(values.glucoseNightLow),
      glucoseDayHigh  : countOffset(values.glucoseDayHigh),
      glucoseDayLow   : countOffset(values.glucoseDayLow),
    };
    return (
      <>
        <div
          className="rangeSegment"
          style={{
            left : 0,
            width: countTimeOffset(get(formValues, 'values.dayStart', defaultValues.dayStart)),
          }}
        >
          <Draggable
            axis="y"
            disabled={locked}
            position={{
              y: offsets.glucoseNightHigh,
              x: 0,
            }}
            onDrag={(e, data) => {
              this.props.onRangeChange({ id: 'glucoseNightHigh', value: countValue(data) });
            }}
            bounds={{
              top   : 20,
              bottom: offsets.glucoseNightLow,
            }}
          >
            {
              this.renderRangeSliderHandler({
                level  : this.levels.HIGH,
                dayTime: this.dayTimes.DAY_START,
                height : offsets.glucoseNightHigh,
                value  : values.glucoseNightHigh,
              })
            }
          </Draggable>
          <Draggable
            axis="y"
            disabled={locked}
            position={{
              y: offsets.glucoseNightLow,
              x: 0,
            }}
            onDrag={(e, data) => {
              this.props.onRangeChange({ id: 'glucoseNightLow', value: countValue(data) });
            }}
            bounds={{
              top   : offsets.glucoseNightHigh,
              bottom: height - 20,
            }}
          >
            {
              this.renderRangeSliderHandler({
                level  : this.levels.LOW,
                dayTime: this.dayTimes.DAY_START,
                height : height - offsets.glucoseNightLow,
                value  : values.glucoseNightLow,
              })
            }
          </Draggable>
        </div>
        <div
          className="rangeSegment"
          style={{
            left : countTimeOffset(get(formValues, 'values.dayStart', defaultValues.dayStart)),
            width: countTimeOffset(get(formValues, 'values.dayEnd', defaultValues.dayEnd))
              - countTimeOffset(get(formValues, 'values.dayStart', defaultValues.dayStart)),
          }}
        >
          <Draggable
            axis="y"
            disabled={locked}
            position={{
              y: offsets.glucoseDayHigh,
              x: 0,
            }}
            onDrag={(e, data) => {
              this.props.onRangeChange({ id: 'glucoseDayHigh', value: countValue(data) });
            }}
            bounds={{
              top   : 20,
              bottom: offsets.glucoseDayLow,
            }}
          >
            {
              this.renderRangeSliderHandler({
                level  : this.levels.HIGH,
                dayTime: this.dayTimes.DAY_MIDDLE,
                height : offsets.glucoseDayHigh,
                value  : values.glucoseDayHigh,
              })
            }
          </Draggable>
          <Draggable
            axis="y"
            disabled={locked}
            position={{
              y: offsets.glucoseDayLow,
              x: 0,
            }}
            onDrag={(e, data) => {
              this.props.onRangeChange({ id: 'glucoseDayLow', value: countValue(data) });
            }}
            bounds={{
              top   : offsets.glucoseDayHigh,
              bottom: height - 20,
            }}
          >
            {
              this.renderRangeSliderHandler({
                level  : this.levels.LOW,
                dayTime: this.dayTimes.DAY_MIDDLE,
                height : height - offsets.glucoseDayLow,
                value  : values.glucoseDayLow,
              })
            }
          </Draggable>
        </div>
        <div
          className="rangeSegment"
          style={{
            right: 0,
            width: width - countTimeOffset(get(formValues, 'values.dayEnd', defaultValues.dayEnd)),
          }}
        >
          <Draggable
            axis="y"
            disabled={locked}
            position={{
              y: offsets.glucoseNightHigh,
              x: 0,
            }}
            onDrag={(e, data) => {
              this.props.onRangeChange({ id: 'glucoseNightHigh', value: countValue(data) });
            }}
            bounds={{
              top   : 20,
              bottom: offsets.glucoseNightLow,
            }}
          >
            {
              this.renderRangeSliderHandler({
                level  : this.levels.HIGH,
                dayTime: this.dayTimes.DAY_END,
                height : offsets.glucoseNightHigh,
                value  : values.glucoseNightHigh,
              })
            }
          </Draggable>
          <Draggable
            axis="y"
            disabled={locked}
            position={{
              y: offsets.glucoseNightLow,
              x: 0,
            }}
            onDrag={(e, data) => {
              this.props.onRangeChange({ id: 'glucoseNightLow', value: countValue(data) });
            }}
            bounds={{
              top   : offsets.glucoseNightHigh,
              bottom: height - 20,
            }}
          >
            {
              this.renderRangeSliderHandler({
                level  : this.levels.LOW,
                dayTime: this.dayTimes.DAY_END,
                height : height - offsets.glucoseNightLow,
                value  : values.glucoseNightLow,
              })
            }
          </Draggable>
        </div>
      </>
    );
  }


  renderTimeSliders(width) {
    const { timeDragStep, formValues, locked } = this.props;
    const { defaultValues } = this.state;
    const gridBase = width / 24 / (1 / timeDragStep);
    const countHour = (data) => {
      const offset = data.x / gridBase / (1 / timeDragStep);
      return Math.floor(offset) + Math.round((1 / timeDragStep) * (offset % 1)) / (1 / timeDragStep);
    };
    const countOffset = (hour) => hour / 24 * width;
    return (
      <>
        <Draggable
          axis="x"
          disabled={locked}
          position={{
            x: countOffset(get(formValues, 'values.dayStart', defaultValues.dayStart)),
            y: 0,
          }}
          onDrag={(e, data) => {
            this.props.onTimeChange({ id: 'dayStart', value: countHour(data) });
          }}
          bounds={{
            left : countOffset(2),
            right: countOffset(get(formValues, 'values.dayEnd', defaultValues.dayEnd) - 3),
          }}
          grid={[gridBase, 0]}


        >
          <div className="timeSlider">
            <span className="timeSlider__label"><FormattedMessage {...messages.labels.dayStart} /></span>
            <button className="timeSlider__dragButton" type="button">
              <HorizontalArrow />
            </button>
          </div>
        </Draggable>
        <Draggable
          axis="x"
          disabled={locked}
          position={{
            x: countOffset(get(formValues, 'values.dayEnd', defaultValues.dayEnd)),
            y: 0,
          }}
          onDrag={(e, data) => {
            this.props.onTimeChange({ id: 'dayEnd', value: countHour(data) });
          }}
          bounds={{
            left : countOffset(get(formValues, 'values.dayStart', defaultValues.dayStart) + 3),
            right: countOffset(23),
          }}
          grid={[gridBase, 0]}

        >
          <div className="timeSlider">
            <span className="timeSlider__label"><FormattedMessage {...messages.labels.dayEnd} /></span>
            <button className="timeSlider__dragButton" type="button">
              <HorizontalArrow />
            </button>
          </div>
        </Draggable>
      </>
    );
  }


  renderSlidersLayer() {
    return (
      <div
        className={cn(
          'sliders',
          {
            'sliders--locked': this.props.locked,
          }
        )}
      >
        <AutoSizer>
          {
            ({ height, width }) => (
              <div style={{
                width : `${width}px`,
                height: `${height}px`,
              }}
              >
                {this.renderRangeSliders(width, height)}
                {this.renderTimeSliders(width, height)}
              </div>
            )
          }
        </AutoSizer>
      </div>
    );
  }


  render() {
    return (
      <div className={cn(stylesRangesSlider.rangesSlider, styles.cgmRangesSlider)}>
        {this.renderScales()}
        {this.renderSlidersLayer()}
      </div>
    );
  }

}

export default withStyles(styles, stylesRangesSlider)(CgmRangesSliderPartial);
