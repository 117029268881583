exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._5ZswB{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center;width:320px}html[dir=ltr] ._5ZswB,html[dir=rtl] ._5ZswB{border-left:2px solid}._5ZswB svg{fill:#0a385a;width:12px;height:12px}._3wArw{border-color:#40bf60;color:#40bf60}._27-JT{border-color:#4299f0;color:#4299f0}.qzw9u{border-color:#f2a65a;color:#f2a65a}._2zRIt{border-color:#f65663;color:#f65663}.DEq6F{padding:0 16px;display:block}._2hbO7{font-size:12px;font-size:1.2rem;font-weight:700;text-transform:uppercase}._3lSBL{color:#0a385a!important;font-size:14px;font-size:1.4rem;font-weight:500}", ""]);

// exports
exports.locals = {
	"toast__wrapper": "_5ZswB",
	"toast__wrapper--success": "_3wArw",
	"toast__wrapper--information": "_27-JT",
	"toast__wrapper--warning": "qzw9u",
	"toast__wrapper--error": "_2zRIt",
	"toast__contentWrapper": "DEq6F",
	"toast__header": "_2hbO7",
	"toast__content": "_3lSBL"
};