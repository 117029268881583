exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".yMoPM{padding:0 0 4rem;line-height:1;height:auto;font-weight:700}.yMoPM,.yMoPM:hover{text-decoration:underline}.yMoPM .btn__labelWrapper{padding:0}", ""]);

// exports
exports.locals = {
	"addAnotherPersonBtn": "yMoPM"
};