exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3V2d-{width:100%;background:#000;color:#fff;font-size:8pt;line-height:.25in;letter-spacing:.08em;text-transform:uppercase;padding:0 .1in}", ""]);

// exports
exports.locals = {
	"sectionTitle": "_3V2d-"
};