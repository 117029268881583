exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._33GsV{-ms-flex-direction:column;flex-direction:column;width:calc(100% - 1.8rem)}._33GsV,._1FeFr{display:-ms-flexbox;display:flex}._1FeFr{-ms-flex-direction:row;flex-direction:row;gap:16px;gap:1.6rem;width:100%}._2DW8P{position:relative;width:50%}.p1IWy{width:100%}._2DW8P label{overflow:hidden;-o-text-overflow:ellipsis;text-overflow:ellipsis;white-space:nowrap;width:100%}._2_Hu9{position:absolute;top:40px;top:4rem;right:12px;right:1.2rem;color:#6f7ea2}.wFlhP{color:#c9cfdc}", ""]);

// exports
exports.locals = {
	"root": "_33GsV",
	"root__row": "_1FeFr",
	"root__cell": "_2DW8P",
	"root__cell--singleCell": "p1IWy",
	"root__unit": "_2_Hu9",
	"root__unit--disabled": "wFlhP"
};