exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3vCzl{font-size:15px;font-size:1.5rem;color:rgba(0,0,0,.25)}.AusoS{font-size:13px;font-size:1.3rem}", ""]);

// exports
exports.locals = {
	"placeholder": "_3vCzl",
	"placeholder__info": "AusoS"
};