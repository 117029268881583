exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1kOJE{padding:15px;color:inherit}._1P6yG{font-size:14px;font-size:1.4rem}._1R2tq,._2gcLA{font-size:11px;font-size:1.1rem}._1R2tq{text-decoration:underline;color:inherit}._1R2tq:hover{color:#fff}", ""]);

// exports
exports.locals = {
	"currentVersion": "_1kOJE",
	"currentVersion__versionName": "_1P6yG",
	"currentVersion__changeLogUrl": "_1R2tq",
	"currentVersion__releaseDate": "_2gcLA"
};