import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { motion } from 'framer-motion';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import reject from 'lodash/reject';
import { AppContext } from 'context';
import Avatar from 'components/Avatar';
import Button from 'components/Form/Button';
import Bgm from 'svg/joined-points.svg';
import Cgm from 'svg/cgm-points.svg';
import ChevronRight from 'svg/chevron-right.svg';
import OneDirectional from 'svg/one-directional.svg';
import TwoDirectional from 'svg/two-directional.svg';
import App from 'modules/App';
import { isNotDisconnectableDataSource } from 'helpers/externalDataSources';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './Settings.pcss';


class Settings extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit actions
    authType                   : PropTypes.string,
    // Implicit props
    dataSources                : PropTypes.arrayOf(shapes.dataSource),
    connectedDataSources       : PropTypes.array,
    localizationResources      : PropTypes.object,
    isConnectInProgress        : PropTypes.bool,
    hasConnectErrors           : PropTypes.bool,
    isDisconnectInProgress     : PropTypes.bool,
    hasDisconnectErrors        : PropTypes.bool,
    isClientInitialized        : PropTypes.bool,
    // Implicit actions
    onAuthorize                : PropTypes.func,
    onDisconnect               : PropTypes.func,
    onFetchConnectedDataSources: PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      expanded: [],
    };

    if (!process.env.BROWSER || props.isClientInitialized) {
      props.onFetchConnectedDataSources();
    }
  }


  onToggleCollapse(dataSourceId) {
    this.setState((prevState) => {
      const { expanded } = prevState;
      if (includes(expanded, dataSourceId)) {
        return { expanded: reject(expanded, (ex) => ex === dataSourceId) };
      }
      return { expanded: [...expanded, dataSourceId] };
    });
  }


  getCapabilityIcon(capability) {
    switch (capability.type) {
      case 'Bgm': return Bgm;
      case 'Cgm': return Cgm;
      default: return null;
    }
  }


  renderReadTag() {
    return (
      <div className="tag tag--info">
        <OneDirectional className="rotate180" />
        <FormattedMessage {...messages.tags.get} />
      </div>
    );
  }


  renderWriteTag() {
    return (
      <div className="tag tag--info">
        <OneDirectional />
        <FormattedMessage {...messages.tags.send} />
      </div>
    );
  }


  renderReadWriteTag() {
    return (
      <div className="tag tag--info">
        <TwoDirectional />
        <FormattedMessage {...messages.tags.getAndSend} />
      </div>
    );
  }


  renderTag(mode) {
    switch (mode) {
      case 'Read': return this.renderReadTag();
      case 'Write': return this.renderWriteTag();
      case 'ReadWrite': return this.renderReadWriteTag();
      default: return null;
    }
  }


  renderCapability(capability) {
    const name = get(this.props.localizationResources, [capability.nameKey, 'value'], capability.type);
    const Icon = this.getCapabilityIcon(capability);
    return (
      <tr key={capability.externalDataSourceCapabilityId}>
        <td>
          <div className="row no-gutters align-items-center">
            { Icon && <div className="col-auto"><Icon className={styles.dataSource__capability__icon} /></div> }
            <div className="col-auto">{ name }</div>
          </div>
        </td>
        <td className="section__table__autoFitCol">{ this.renderTag(capability.defaultMode) }</td>
      </tr>
    );
  }


  renderCapabilities(capabilities) {
    return (
      <table className="section__table mt-4">
        <tbody className="section__table__body">
          { map(capabilities, (capability) => this.renderCapability(capability)) }
        </tbody>
      </table>
    );
  }


  renderConnectionStatus(dataSource, connectedDataSource) {
    if (connectedDataSource) {
      const isNotDisconnectable = isNotDisconnectableDataSource(dataSource);
      return (
        <div className={styles.dataSource__status}>
          <div className="tag tag--success"><FormattedMessage {...messages.tags.connected} /></div>
          <Button
            isDisabled={isNotDisconnectable}
            styleModifier="quaternary"
            labelMessage={messages.buttons.disconnect}
            className={cn('btn--sm px-4 mx-3', { 'text--primary': !isNotDisconnectable })}
            isInProgress={this.props.isDisconnectInProgress}
            onClick={() => this.props.onDisconnect(connectedDataSource.accountExternalDataSourceId)}
          />
        </div>
      );
    }
    const { authType } = this.props;
    return (
      <div className={styles.dataSource__status}>
        <div className="tag tag--error"><FormattedMessage {...messages.tags.notConnected} /></div>
        <Button
          styleModifier="quaternary"
          labelMessage={messages.buttons.connect}
          className="btn--sm px-4 mx-3 text--primary"
          isInProgress={this.props.isConnectInProgress}
          onClick={() => this.props.onAuthorize(dataSource.dataSourceProvider, authType)}
        />
      </div>
    );
  }


  renderErrors() {
    const { hasConnectErrors, hasDisconnectErrors } = this.props;
    if (hasConnectErrors) {
      return <div className="text--error"><FormattedMessage {...messages.errors.connectError} /></div>;
    }
    if (hasDisconnectErrors) {
      return <div className="text--error"><FormattedMessage {...messages.errors.disconnectError} /></div>;
    }
    return null;
  }


  renderContourCloudInfo() {
    const dataSource = find(this.props.dataSources, { externalDataSourceId: 1 });
    if (!dataSource) {
      return null;
    }
    const { externalDataSourceId, dataSourceProvider, nameKey } = dataSource;
    const connectedDataSource = find(this.props.connectedDataSources, { externalDataSourceId });
    const name = get(this.props.localizationResources, [nameKey, 'value'], dataSourceProvider);
    return (
      <div className={`${styles.dataSource} ${styles['dataSource--standalone']}`}>
        <div className="row">
          <div className="col-auto">
            <Avatar
              avatarImg={dataSource.logo}
              name={name}
              className={styles.dataSource__logo}
              imgClassName={styles.dataSource__logo__img}
              initialsClassName={styles.dataSource__logo__initials}
            />
          </div>
          <div className="col">
            <div className={`row align-items-center ${styles.dataSource__headerRow}`}>
              <div className="col">
                <h3 className={styles.dataSource__name}>{ name }</h3>
                <p className={styles.dataSource__info}>
                  <FormattedMessage {...messages.dataSourcesInfos.ContourCloudDefault} />
                </p>
              </div>
              <div className="col-auto d-flex flex-nowrap">
                {
                  connectedDataSource
                    ? <div className="tag tag--success"><FormattedMessage {...messages.tags.connected} /></div>
                    : <div className="tag tag--error"> <FormattedMessage {...messages.tags.notConnected} /></div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


  renderDataSource(dataSource) {
    const { externalDataSourceId, dataSourceProvider, nameKey } = dataSource;
    const { connectedDataSources } = this.props;
    const connectedDataSource = find(connectedDataSources, { externalDataSourceId });
    const isExpanded = includes(this.state.expanded, dataSourceProvider);
    const name = get(this.props.localizationResources, [nameKey, 'value'], dataSourceProvider);
    const dataSourceInfo = messages.dataSourcesInfos[dataSourceProvider];

    return (
      <li key={externalDataSourceId} className={styles.dataSource}>
        <div className="row">
          <div className="col-auto">
            <Avatar
              avatarImg={dataSource.logo}
              name={name}
              className={styles.dataSource__logo}
              imgClassName={styles.dataSource__logo__img}
              initialsClassName={styles.dataSource__logo__initials}
            />
          </div>
          <div className="col">
            <div className={`row align-items-center ${styles.dataSource__headerRow}`}>
              <div className="col">
                <h3 className={styles.dataSource__name}>{ name }</h3>
                {
                  dataSourceInfo
                  && <p className={styles.dataSource__info}><FormattedMessage {...dataSourceInfo} /></p>
                }
              </div>
              <div className="col-auto d-flex flex-nowrap">
                { this.renderConnectionStatus(dataSource, connectedDataSource) }
                <Button
                  className="collapsible__btn ml-3"
                  onClick={() => this.onToggleCollapse(dataSourceProvider)}
                >
                  <ChevronRight
                    className={cn('collapsible__btn__icon', { 'collapsible__btn__icon--collapsed': isExpanded })}
                  />
                </Button>
              </div>
            </div>
            { this.renderErrors() }
          </div>
        </div>
        <motion.div
          animate={{ height: isExpanded ? 'auto' : 0 }}
          transition={{ ease: 'easeOut', duration: 0.3 }}
          className={styles.dataSource__capabilities}
        >
          { this.renderCapabilities(dataSource.capabilities) }
        </motion.div>
      </li>
    );
  }


  renderDataSourcesList() {
    const { dataSources } = this.props;
    return (
      <ul className={styles.dataSources}>{ map(dataSources, (dataSource) => this.renderDataSource(dataSource)) }</ul>
    );
  }


  render() {
    return (
      <div>
        { this.renderContourCloudInfo() }
        <p className={styles.dataSourcesIntro}>
          <FormattedMessage {...messages.intros.dataSourcesSettings} />
        </p>
        <h3 className="form-label text--uppercase mb-3">
          <FormattedMessage {...messages.headers.availablePlatforms} />
        </h3>
        { this.renderDataSourcesList() }
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  dataSources           : selectors.dataSources(state),
  connectedDataSources  : selectors.connectedDataSources(state),
  isConnectInProgress   : selectors.isConnectInProgress(state),
  hasConnectErrors      : selectors.hasConnectErrors(state),
  isDisconnectInProgress: selectors.isDisconnectInProgress(state),
  hasDisconnectErrors   : selectors.hasDisconnectErrors(state),
  localizationResources : App.selectors.localizationResources(state),
  isClientInitialized   : App.selectors.isClientInitialized(state),
});


const mapDispatchToProps = (dispatch) => ({
  onAuthorize                : (provider, authType) => dispatch(actions.authorize(provider, authType)),
  onDisconnect               : (accountDataSourceId) => dispatch(actions.disconnect(accountDataSourceId)),
  onFetchConnectedDataSources: () => dispatch(actions.fetchConnectedDataSources()),
});


const ConnectedSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Settings);


export default withStyles(styles)(ConnectedSettings);
