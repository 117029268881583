exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._28iCC{display:grid;grid-template-columns:50fr 50fr;grid-gap:2rem;min-height:640px}._28iCC .widget{display:-ms-flexbox;display:flex;-ms-flex-direction:column!important;flex-direction:column!important}._28iCC .widget .widget__content{-ms-flex:1 1;flex:1 1;position:relative}.Vnq2k{height:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding:10px}.Vnq2k .statisticsSection{border-top:1px solid #e0e8f2;padding:5px 0}.Vnq2k .statisticsSection:first-child{border-top:none}.Vnq2k .statisticsSection__row{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;margin:10px}.Vnq2k .statisticsSection__element{font-weight:500;font-size:13px;line-height:24px;color:#6f7ea2}.Vnq2k .statisticsSection__elementCaption{font-weight:500;font-size:13px;line-height:18px;color:#6f7ea2;margin:10px 0}.Vnq2k .statisticsSection__elementHighlight{font-weight:600;font-size:15px;line-height:24px;color:#0a385a}", ""]);

// exports
exports.locals = {
	"mixedDistributionWidget": "_28iCC",
	"cgmStatistics": "Vnq2k"
};