exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2nrtQ{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;position:absolute;top:-19px;padding:3px 4px;background:#30a8ff;border-radius:4px 4px 0 0;cursor:default}html[dir=ltr] ._2nrtQ{right:10px;right:1rem}html[dir=rtl] ._2nrtQ{left:10px;left:1rem}._2bR6L{width:12px!important;width:1.2rem!important;height:12px!important;height:1.2rem!important;min-width:12px!important;min-width:1.2rem!important;min-height:12px!important;min-height:1.2rem!important;margin:0!important;fill:#fff!important}.Rik4V{color:#fff;font-size:12px;font-size:1.2rem;font-weight:400;display:inline-block}html[dir=ltr] .Rik4V{margin-left:.6rem}html[dir=rtl] .Rik4V{margin-right:.6rem}", ""]);

// exports
exports.locals = {
	"activeNotingLabel": "_2nrtQ",
	"activeNotingLabel__icon": "_2bR6L",
	"activeNotingLabel__label": "Rik4V"
};