exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1GI9A{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}html[dir=ltr] ._1GI9A{padding-left:1.6rem;margin-left:1.6rem;border-left:1px solid #e0e8f2}html[dir=rtl] ._1GI9A{padding-right:1.6rem;margin-right:1.6rem;border-right:1px solid #e0e8f2}._1iYz4{font-weight:700;margin-bottom:.8rem}._1t6f_{color:#6f7ea2}._3fHmU{position:relative;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:48px;width:4.8rem;height:48px;height:4.8rem;background-color:#fff;border-radius:50%;overflow:hidden}html[dir=ltr] ._3fHmU{margin-right:1.6rem}html[dir=rtl] ._3fHmU{margin-left:1.6rem}._3dEp4{display:block;color:#fff;font-size:24px;font-size:2.4rem;font-weight:700}._3L3Xn{width:auto;max-height:100%}.eipHz{color:#30a8ff;height:auto;line-height:1.6}.eipHz .btn__labelWrapper{padding:0}._2BbK8{width:8px;width:.8rem;height:8px;height:.8rem;display:inline-block;margin-right:.5rem;fill:#30a8ff}.LsOUt{width:430px;width:43rem}._2UGzd{width:4px;width:.4rem;height:20px;height:2rem;fill:#6f7ea2}", ""]);

// exports
exports.locals = {
	"clinic": "_1GI9A",
	"clinic__name": "_1iYz4",
	"clinic__address": "_1t6f_",
	"clinic__logo": "_3fHmU",
	"clinic__logo__initials": "_3dEp4",
	"clinic__logo__img": "_3L3Xn",
	"addHcpBtn": "eipHz",
	"addHcpBtn__icon": "_2BbK8",
	"form": "LsOUt",
	"menu__icon": "_2UGzd"
};