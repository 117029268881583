exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2IbAg{margin-right:-1.6rem}html[dir=ltr] ._27RLb{margin-right:3.2rem}html[dir=rtl] ._27RLb{margin-left:3.2rem}.zhuhL{position:relative;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:24px;width:2.4rem;height:24px;height:2.4rem;background-color:#fff;border-radius:50%;overflow:hidden}.haUNH{width:auto;height:100%;-o-object-fit:cover;object-fit:cover}._15sqi{color:#fff}._15sqi,._2wkIb{font-size:12px;font-size:1.2rem;line-height:16px;line-height:1.6rem;font-weight:700}._2wkIb{color:#0a385a;-o-text-overflow:ellipsis;text-overflow:ellipsis;overflow:hidden;-webkit-line-clamp:2;-webkit-box-orient:vertical;text-align:left}._36JUp{min-height:50px;min-width:105px}", ""]);

// exports
exports.locals = {
	"header__rightSide": "_2IbAg",
	"element": "_27RLb",
	"clinicLogo": "zhuhL",
	"clinicLogo__img": "haUNH",
	"clinicLogo__initials": "_15sqi",
	"clinicLogo__label": "_2wkIb",
	"ascensiaLogo": "_36JUp"
};