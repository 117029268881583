exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1o2xa{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._1o2xa .label{white-space:nowrap;font-weight:500;font-size:13px;color:#6f7ea2}._1o2xa .perPageSelect{height:36px;width:66px;position:relative}._1o2xa .select__selected-field{padding:0;height:100%;display:-ms-flexbox!important;display:flex!important;-ms-flex-align:center;align-items:center;border-radius:5px;border:none;background:none;font-weight:500!important;font-size:13px}", ""]);

// exports
exports.locals = {
	"perPageSelect": "_1o2xa"
};