exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1gXDI{height:350px;width:100%;margin-top:-10px;margin-bottom:-20px}._22Kfn{top:0;bottom:20px;left:0;right:0}.print .nivoChart{height:300px}.targetReportAgpLabel{-webkit-transform:translateY(36px) translateX(-32px) rotate(-90deg);-ms-transform:translateY(36px) translateX(-32px) rotate(-90deg);transform:translateY(36px) translateX(-32px) rotate(-90deg);position:relative;font-size:8pt;font-weight:600}._1gXDI svg text{dominant-baseline:auto!important}._1gXDI svg text[text-anchor=middle]{-webkit-transform:translateY(30px) rotate(0)!important;-ms-transform:translateY(30px) rotate(0)!important;transform:translateY(30px) rotate(0)!important}._1gXDI svg text[text-anchor=end]{-webkit-transform:translate(-10px,4px) rotate(0)!important;-ms-transform:translate(-10px,4px) rotate(0)!important;transform:translate(-10px,4px) rotate(0)!important}._1gXDI svg text[text-anchor=start]{-webkit-transform:translate(10px,4px) rotate(0)!important;-ms-transform:translate(10px,4px) rotate(0)!important;transform:translate(10px,4px) rotate(0)!important}", ""]);

// exports
exports.locals = {
	"root": "_1gXDI",
	"root__inner": "_22Kfn"
};