exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._19qtk{height:auto;color:#0a385a;font-size:14px;font-size:1.4rem;max-width:165px;white-space:normal;margin:0}._19qtk .btn-inner{-ms-flex-pack:left;justify-content:left}.nRmzn{position:relative;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:24px;width:2.4rem;height:24px;height:2.4rem;background-color:#fff;border-radius:50%;overflow:hidden}.SQq8f{width:auto;height:100%;-o-object-fit:cover;object-fit:cover}._2C6FR{display:block;color:#fff;font-weight:700}._3a2Hp,._2C6FR{font-size:12px;font-size:1.2rem;line-height:16px;line-height:1.6rem}._3a2Hp{color:#0a385a;-o-text-overflow:ellipsis;text-overflow:ellipsis;overflow:hidden;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;text-align:left}._3DE-6{color:#6f7ea2}", ""]);

// exports
exports.locals = {
	"root": "_19qtk",
	"logo": "nRmzn",
	"logo__img": "SQq8f",
	"logo__initials": "_2C6FR",
	"label": "_3a2Hp",
	"enrollCode": "_3DE-6"
};