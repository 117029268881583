exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".vYWhg{color:#c9cfdc;font-size:16px;font-size:1.6rem;text-align:center}.IgUWe{display:block;width:36px;width:3.6rem;height:36px;height:3.6rem;margin:0 auto 2rem}", ""]);

// exports
exports.locals = {
	"root": "vYWhg",
	"icon": "IgUWe"
};