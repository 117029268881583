exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._369f8{border-top:1px solid #e0e8f2;border-bottom:1px solid #e0e8f2;overflow:hidden}.Z6c12{overflow:visible}._1XYxR{position:relative;font-size:14px;font-size:1.4rem;font-weight:700;padding:1rem 0;cursor:pointer}._1XYxR .collapsible__btn{position:absolute;top:1px;right:0}._3-PvY{padding-top:2rem}.OcfWM{font-size:14px;font-size:1.4rem;margin:2rem 0}._1OmjD{position:relative;width:250px;width:25rem;height:250px;height:25rem;border:4rem solid rgba(48,168,255,.1);border-radius:50%}.l8Tsa{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;position:absolute;top:-27.5px;top:-2.75rem;left:-27.5px;left:-2.75rem;width:225px;width:22.5rem;height:225px;height:22.5rem;border:1.5rem solid #30a8ff;border-radius:50%}._3q9Zc{width:80px;width:8rem;height:80px;height:8rem;fill:#30a8ff}.GMoxx{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:250px;width:25rem;height:251px;height:25.1rem}._3KH9w{width:225px;width:22.5rem;height:225px;height:22.5rem}._22dGW{width:160px;width:16rem;height:150px;height:15rem;fill:#30a8ff}", ""]);

// exports
exports.locals = {
	"optionalSection": "_369f8",
	"optionalSection--overflow": "Z6c12",
	"optionalSection__header": "_1XYxR",
	"optionalSection__content": "_3-PvY",
	"qrcode__info": "OcfWM",
	"indicatorBackground": "_1OmjD",
	"indicator": "l8Tsa",
	"indicatorIcon": "_3q9Zc",
	"loaderContainer": "GMoxx",
	"loader": "_3KH9w",
	"loaderIcon": "_22dGW"
};