import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Pie } from '@nivo/pie';
import cn from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import withStyles from 'isomorphic-style-loader/withStyles';
import { isAggregatedPreMeal } from 'libs/StatsCalculations';
import App from 'modules/App';
import { getFromGlucoseLevelDistribution, getFromReadings } from './helpers';
import styles from './GlucoseLevelDistributionChart.pcss';


class GlucoseLevelDistributionChart extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { glucoseLevelDistribution, standards, readings, valueKey } = props;

    if (glucoseLevelDistribution && glucoseLevelDistribution !== state.glucoseLevelDistribution) {
      return getFromGlucoseLevelDistribution(glucoseLevelDistribution);
    }

    if (readings && readings !== state.readings) {
      return getFromReadings(readings, valueKey, standards);
    }

    return null;
  }

  static propTypes = {
    // Explicit props
    glucoseLevelDistribution: PropTypes.shape({
      highPostMeal  : PropTypes.number,
      highPreMeal   : PropTypes.number,
      lowPostMeal   : PropTypes.number,
      lowPreMeal    : PropTypes.number,
      targetPostMeal: PropTypes.number,
      targetPreMeal : PropTypes.number,
    }),
    flagFilters: PropTypes.arrayOf(PropTypes.oneOf(['Fasting', 'PreMeal', 'PostMeal', 'None'])),
    standards  : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    conversion    : PropTypes.object,
    isInProgress  : PropTypes.bool,
    verticalLegend: PropTypes.bool,
  };


  constructor(props) {
    super(props);
    this.state = {
      preMeal    : [],
      postMeal   : [],
      preMealSum : 0,
      postMealSum: 0,
      levels     : {
        preMeal: {
          high  : 0,
          target: 0,
          low   : 0,
        },
        postMeal: {
          high  : 0,
          target: 0,
          low   : 0,
        },
      },
      glucoseLevelDistribution: null,
      readings                : [],
    };
  }


  get loaderMockValues() {
    return [
      {
        id   : 'high',
        label: 'high',
        value: 1,
      },
      {
        id   : 'target',
        label: 'target',
        value: 1,
      },
      {
        id   : 'low',
        label: 'low',
        value: 1,
      },
    ];
  }


  get isFlagFilters() {
    const { flagFilters } = this.props;
    const isNoFilters = isEmpty(flagFilters);
    let isPreMealFilter = isNoFilters;
    let isPostMealFilter = isNoFilters;
    let i = 0;

    while ((!isPreMealFilter || !isPostMealFilter) && i < flagFilters.length) {
      if (isAggregatedPreMeal(flagFilters[i])) {
        isPreMealFilter = true;
      } else {
        isPostMealFilter = true;
      }
      i++;
    }

    return { isPreMealFilter, isPostMealFilter };
  }


  renderLegendSubItem(type, isPostMeal, isPreMealFilter, isPostMealFilter) {
    if ((isPostMeal && !isPostMealFilter) || (!isPostMeal && !isPreMealFilter)) {
      return null;
    }
    const { preMealSum, postMealSum, levels } = this.state;
    const sum = isPostMeal ? postMealSum : preMealSum;
    const level = get(levels, [isPostMeal ? 'postMeal' : 'preMeal', type]);
    const flag = isPostMeal ? 'PostMeal' : 'PreMeal';
    const colNumber = (isPostMeal && !isPreMealFilter) || (!isPostMeal && !isPostMealFilter) ? 12 : 6;

    return (
      <div className={`col-${colNumber}`}>
        <span
          className={cn(
            'legend__item__disc',
            `legend__item__disc--${type}`,
            { 'legend__item__disc--double-color': isPostMeal },
          )}
        />
        <p className="text--large text--bold">
          <FormattedMessage {...App.messages.flags[flag]} />{ ' ' }
          ({ sum && Math.round((level / sum) * 100) }%)
        </p>
      </div>
    );
  }


  renderLegendItem(type, isPreMealFilter, isPostMealFilter) {
    return (
      <div className="legend__item">
        <h5 className={styles.glucoseLevel__header}>
          <span className="text--large text--bold"><FormattedMessage {...App.messages.levels[type]} /></span>
        </h5>
        <div className="row">
          { this.renderLegendSubItem(type, false, isPreMealFilter, isPostMealFilter) }
          { this.renderLegendSubItem(type, true, isPreMealFilter, isPostMealFilter) }
        </div>
      </div>
    );
  }


  renderLegend(isPreMealFilter, isPostMealFilter) {
    return (
      <div className="legend">
        <ul className="list--unstyled row">
          <li className="col-4">
            { this.renderLegendItem('low', isPreMealFilter, isPostMealFilter) }
          </li>
          <li className="col-4">
            { this.renderLegendItem('target', isPreMealFilter, isPostMealFilter) }
          </li>
          <li className="col-4">
            { this.renderLegendItem('high', isPreMealFilter, isPostMealFilter) }
          </li>
        </ul>
      </div>
    );
  }


  renderVerticalLegendSubItem(type, isPostMeal) {
    const { preMealSum, postMealSum, levels } = this.state;
    const { standards, conversion } = this.props;
    const sum = isPostMeal ? postMealSum : preMealSum;
    const level = get(levels, [isPostMeal ? 'postMeal' : 'preMeal', type]);
    const mode = isPostMeal ? 'postMeal' : 'preMeal';
    let thresholdLabel;
    switch (type) {
      case 'low': {
        thresholdLabel = `< ${conversion.toDisplay(standards[mode].lowThreshold)} ${conversion.unitSymbol}`;
        break;
      }
      case 'high': {
        thresholdLabel = `> ${conversion.toDisplay(standards[mode].highThreshold)} ${conversion.unitSymbol}`;
        break;
      }
      default:
      case 'target': {
        thresholdLabel = `${conversion.toDisplay(standards[mode].lowThreshold)} - ${conversion.toDisplay(standards[mode].highThreshold)} ${conversion.unitSymbol}`;
        break;
      }
    }

    return (
      <div className="col">
        <div className="legend__item__pad">
          <span
            className={cn(
              'legend__item__rect',
              `legend__item__rect--${type}`,
              { 'legend__item__rect--double-color': isPostMeal },
            )}
          />
        </div>
        <p className="text--large text--bold">
          <span className="text--large text--bold">
            <FormattedMessage {...App.messages.levels[type]} />
            &nbsp;({ sum && Math.round((level / sum) * 100) }%)
          </span>
        </p>
        <p className="text--gray mt-2">
          {thresholdLabel}
        </p>
      </div>
    );
  }


  renderVerticalLegendItem(type, isPostMeal = false) {
    return (
      <div className="legend__item">
        <div className="row">
          { this.renderVerticalLegendSubItem(type, isPostMeal) }
        </div>
      </div>
    );
  }


  renderVerticalLegend(isPreMealFilter, isPostMealFilter) {
    return (
      <div className="mt-3">
        {
          isPreMealFilter && (
            <>
              <h5 className={styles.glucoseLevel__verticalHeader}>
                <FormattedMessage {...App.messages.flags.PreMeal} />
              </h5>
              <ul className="list--unstyled row">
                <li className="col-4">
                  {this.renderVerticalLegendItem('target')}
                </li>
                <li className="col-4">
                  {this.renderVerticalLegendItem('low')}
                </li>
                <li className="col-4">
                  {this.renderVerticalLegendItem('high')}
                </li>
              </ul>
            </>
          )
        }
        { isPreMealFilter && isPostMealFilter && <div className={styles.glucoseLevel__verticalHeader__hr} /> }
        {
          isPostMealFilter && (
          <>
            <h5 className={styles.glucoseLevel__verticalHeader}>
              <FormattedMessage {...App.messages.flags.PostMeal} />
            </h5>
            <ul className="list--unstyled row">
              <li className="col-4">
                {this.renderVerticalLegendItem('target', true)}
              </li>
              <li className="col-4">
                {this.renderVerticalLegendItem('low', true)}
              </li>
              <li className="col-4">
                {this.renderVerticalLegendItem('high', true)}
              </li>
            </ul>
          </>
          )
        }
      </div>
    );
  }


  renderBackgroundChart(sharedProps, isOnlyOneChart) {
    return (
      <div className="nivoPieChartBackground">
        <AutoSizer>
          {({ height, width }) => (
            <Pie
              {...sharedProps}
              data={[{ id: 'background', value: 1, label: 'background' }]}
              colors={['#F5F9FF']}
              innerRadius={isOnlyOneChart ? 0.8 : 0.55}
              borderWidth={30}
              height={height}
              width={width}
            />
          )}
        </AutoSizer>
      </div>
    );
  }


  renderPreMealChart(sharedProps, chartColors, isInProgress) {
    const { preMeal } = this.state;
    return (
      <div className="nivoChart__inner nivoPieChart">
        <AutoSizer>
          {({ height, width }) => (
            <Pie
              {...sharedProps}
              data={isInProgress ? this.loaderMockValues : preMeal}
              colors={chartColors}
              innerRadius={0.8}
              borderWidth={0}
              height={height}
              width={width}
            />
          )}
        </AutoSizer>
      </div>
    );
  }


  renderPostMealChart(sharedProps, chartColors, isOnlyPostMealFilter, isInProgress) {
    const { postMeal } = this.state;
    const margin = isOnlyPostMealFilter ? sharedProps.margin : { top: 65, right: 65, bottom: 65, left: 65 };

    return (
      <div className="nivoChart__inner nivoPieChart">
        <AutoSizer>
          {({ height, width }) => (
            <Pie
              {...sharedProps}
              data={isInProgress ? this.loaderMockValues : postMeal}
              colors={chartColors}
              margin={margin}
              innerRadius={isOnlyPostMealFilter ? 0.8 : 0.75}
              borderWidth={0}
              height={height}
              width={width}
              defs={[
                {
                  id        : 'lines',
                  type      : 'patternLines',
                  background: 'inherit',
                  color     : 'rgba(255, 255, 255, 0.3)',
                  rotation  : -45,
                  lineWidth : 6,
                  spacing   : 10,
                },
              ]}
              fill={[{ match: '*', id: 'lines' }]}
            />
          )}
        </AutoSizer>
      </div>
    );
  }


  render() {
    const { isInProgress } = this.props;
    const { isPreMealFilter, isPostMealFilter } = this.isFlagFilters;
    const isOnlyPreMealFilter = isPreMealFilter && !isPostMealFilter;
    const isOnlyPostMealFilter = isPostMealFilter && !isPreMealFilter;
    const isOnlyOneChart = isOnlyPreMealFilter || isOnlyPostMealFilter;

    const sharedProps = {
      margin            : { top: 40, right: 40, bottom: 40, left: 40 },
      borderColor       : { from: 'color' },
      enableRadialLabels: false,
      enableSlicesLabels: false,
      animate           : true,
      isInteractive     : false,
    };

    const chartColors = isInProgress
      ? ['#E0E8F2', '#E0E8F2', '#E0E8F2']
      : ['#F4C32C', '#1EA98C', '#F74053'];

    return (
      <div className={cn('chartContainer', { 'chartContainer--in-progress': isInProgress })}>
        <div className="nivoChart">
          { this.renderBackgroundChart(sharedProps, isOnlyOneChart) }
          { isPreMealFilter && this.renderPreMealChart(sharedProps, chartColors, isInProgress)}
          { isPostMealFilter && this.renderPostMealChart(sharedProps, chartColors, isOnlyPostMealFilter, isInProgress)}
        </div>
        {
          this.props.verticalLegend
            ? this.renderVerticalLegend(isPreMealFilter, isPostMealFilter)
            : this.renderLegend(isPreMealFilter, isPostMealFilter)
        }
      </div>
    );
  }

}


export default withStyles(styles)(GlucoseLevelDistributionChart);
