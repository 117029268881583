exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._102v5{height:16px;width:16px}._2NtC2{position:relative}.Y8OHx{background:#f5f9ff;border-radius:8px;width:32px;height:40px;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;padding-top:.4rem;fill:#6f7ea2;cursor:pointer}html[dir=ltr] .Y8OHx{margin-right:24px}html[dir=rtl] .Y8OHx{margin-left:24px}.Y8OHx svg{fill:#30a8ff;width:16px;height:16px}.aDcpj{color:#fff;position:absolute;background-color:#f65663;height:16px;height:1.6rem;width:28px;width:2.8rem;bottom:2px;border-radius:.8rem;text-align:center;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;font-size:12px;font-size:1.2rem}._21rJg{background-color:#e0e8f2}", ""]);

// exports
exports.locals = {
	"icon": "_102v5",
	"notifications": "_2NtC2",
	"notifications__content": "Y8OHx",
	"actions__notifications__badge": "aDcpj",
	"actions__notifications__badge--inactive": "_21rJg"
};