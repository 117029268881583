exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Z5g8{-ms-flex:1 1 auto;flex:1 1 auto;height:100%}._257CS{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;height:100%}@media (min-width:1024px){._257CS{-ms-flex-align:center;align-items:center}}._1lFxc{text-align:center;line-height:1.2}._3dfDW{margin:0 auto 3rem}._3dfDW,._3o5Zk{position:relative;width:60px;width:6rem;height:60px;height:6rem}._3o5Zk{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;background-color:#c9cfdc;border-radius:8px;overflow:hidden}._1RLto{width:auto;max-height:100%}._2eORx{display:block;color:#fff;font-size:32px;font-size:3.2rem;font-weight:700;height:41px;height:4.1rem}._3TN2b{color:#30a8ff;font-size:24px;font-size:2.4rem;font-weight:700;padding:3rem 0}@media (min-width:1024px){._3TN2b{font-size:3.6rem}}._3_eYo{font-size:16px;font-size:1.6rem;margin:0 auto 4rem}@media (min-width:1024px){._3_eYo{width:60rem}}._2OZsC{font-size:16px;font-size:1.6rem;margin:4rem auto 0}@media (min-width:1024px){._2OZsC{width:60rem}}", ""]);

// exports
exports.locals = {
	"container": "_3Z5g8",
	"containerInner": "_257CS",
	"root": "_1lFxc",
	"avatar": "_3dfDW",
	"avatar__imgWrapper": "_3o5Zk",
	"avatar__img": "_1RLto",
	"avatar__initials": "_2eORx",
	"clinicName": "_3TN2b",
	"info": "_3_eYo",
	"steps": "_2OZsC"
};