exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".k_Pva{display:-ms-flexbox;display:flex;width:100%;margin:16px 0}._22U_a{margin-top:2px;margin-right:8px}._22U_a svg{width:16px;height:16px;fill:#30a8ff}._1Yj6V{display:block}._2Lkie{color:#30a8ff;font-weight:700;line-height:21px;line-height:2.1rem;margin-bottom:4px;font-size:14px;font-size:1.4rem}._2Rozr{color:#6f7ea2;line-height:21px;line-height:2.1rem;font-size:12px;font-size:1.2rem}", ""]);

// exports
exports.locals = {
	"warningInfoCC": "k_Pva",
	"warningInfoCC__iconWrapper": "_22U_a",
	"warningInfoCC__textWrapper": "_1Yj6V",
	"warningInfoCC__title": "_2Lkie",
	"warningInfoCC__subTitle": "_2Rozr"
};