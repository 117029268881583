exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OjY7x{height:58px;height:5.8rem}.OjY7x,._2hae3{width:48px;width:4.8rem}._2hae3{height:32px;height:3.2rem}.obsSn{border:.1rem solid #e0e8f2;border-radius:.4rem}._2EHzG{text-anchor:middle;font-size:12px;font-size:1.2rem;font-weight:500;line-height:16px;line-height:1.6rem;fill:#f5f9ff}", ""]);

// exports
exports.locals = {
	"meal": "OjY7x",
	"meal__icon": "_2hae3",
	"meal__image": "obsSn",
	"meal__text": "_2EHzG"
};