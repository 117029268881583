import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import App from 'modules/App';
import Widget from 'components/Widget';
import Arrow from 'svg/arrow-down.svg';
import messages from '../../messages';
import styles from './AgpProfileWidget.pcss';


class AgpProfileWidget extends React.Component {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    start                       : PropTypes.number,
    end                         : PropTypes.number,
    isInProgress                : PropTypes.bool,
    // Explicit actions
    onDatesChange               : PropTypes.func,
    // Implicit props
    isDisplayedAgpProfileChart  : PropTypes.bool,
    isDisplayedTimeInRanges     : PropTypes.bool,
    isDisplayedGlucoseStatistics: PropTypes.bool,
    printMode                   : PropTypes.bool,
  };

  constructor() {
    super();
    this.state = {
      isInit: true,
    };
  }

  componentDidMount() {
    this.setState({ isInit: false });
  }

  renderInsufficiencyInfo() {
    const { printMode } = this.props;

    return (
      <>
        {
          !printMode && (
          <div className={styles.insufficiencyDatePopover}>
            <span className="d-block mr-2"><FormattedMessage {...messages.labels.insufficiencyDatesInfo} /></span>
            <Arrow className={`arrow ${styles.arrow}`} />
          </div>
          )
        }
        <div className={styles.insufficiencyInfo}>
          <img className="blob" src="/assets/svg/placeholder-blob.svg" alt="" />
          <p className="text">
            <FormattedMessage {...messages.labels.insufficiencyInfo} />
          </p>-
        </div>
      </>
    );
  }

  render() {
    const {
      isInProgress,
      isDisplayedAgpProfileChart,
      isDisplayedTimeInRanges,
      isDisplayedGlucoseStatistics,
    } = this.props;

    if (isInProgress) {
      return null;
    }

    if (
      (!isDisplayedAgpProfileChart && !isDisplayedTimeInRanges && !isDisplayedGlucoseStatistics)
      && !this.state.isInit
    ) {
      return (
        <div className={styles.insufficiencyInfoWrapper}>
          {this.renderInsufficiencyInfo()}
        </div>
      );
    }

    return (
      <Widget
        headerMessage={messages.headers.ambulatoryGlucoseProfile}
      >
        <AmbulatoryGlucoseProfile.components.ProfileChart
          conversion={this.props.conversion}
          standards={this.props.standards}
          start={this.props.start}
          end={this.props.end}
          onDatesChange={this.props.onDatesChange}
        />
      </Widget>
    );
  }

}


const mapStateToProps = (state) => ({
  isDisplayedAgpProfileChart  : AmbulatoryGlucoseProfile.selectors.isDisplayedAgpProfileChart(state),
  isDisplayedTimeInRanges     : AmbulatoryGlucoseProfile.selectors.isDisplayedTimeInRanges(state),
  isDisplayedGlucoseStatistics: AmbulatoryGlucoseProfile.selectors.isDisplayedGlucoseStatistics(state),
  printMode                   : App.selectors.printMode(state),
});

const ConnectedAgpProfileWidget = connect(
  mapStateToProps,
)(AgpProfileWidget);

export default withStyles(styles)(ConnectedAgpProfileWidget);
