import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import Account from 'modules/Account';


/* eslint-disable global-require */

// The top-level (parent) route
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    // static routes (without auth and checking permissions), components use dumpedState (LocalStorage)
    {
      path    : '',
      children: [
        {
          name: 'reports',
          path: '/reports',
          load: () => import(/* webpackChunkName: 'pwd-results' */ './reports'),
        },
      ],
    },
    // with auth
    {
      path    : '',
      children: [
        {
          name: 'setup',
          path: '/setup',
          load: () => import(/* webpackChunkName: 'account' */ './setup'),
        },

        {
          name: 'clinic-setup',
          path: '/clinic-setup',
          load: () => import(/* webpackChunkName: 'account' */ './clinic-setup'),
        },

        {
          name: 'google-drive',
          path: '/google-drive',
          load: () => import(/* webpackChunkName: 'account' */ './google-drive'),
        },

        {
          name: 'account-settings',
          path: '/account-settings',
          load: () => import(/* webpackChunkName: 'settings' */ './account-settings'),
        },
        {
          name: 'account-settings-view',
          path: '/account-settings/:view',
          load: () => import(/* webpackChunkName: 'settings' */ './account-settings'),
        },
        {
          name: 'central-storage-auth',
          path: '/central-storage-auth/:type',
          load: () => import(/* webpackChunkName: 'settings' */ './central-storage-auth'),
        },

        // {
        //   name: 'app-shell',
        //   path: '/app-shell',
        //   load: () => import(/* webpackChunkName: 'app-shell' */ './app-shell'),
        // },
        // {
        //   name: 'example',
        //   path: '/example',
        //   load: () => import(/* webpackChunkName: 'examples' */ './example'),
        // },

      ],

      async action({ next, store }) {
        // Execute each child route until one of them return the result
        const route = await next();
        if (route) {
          store.dispatch(Account.actions.checkPermissions(['LOGGED_IN']));
        }
        return route;
      },
    },

    // only for pwd and cg
    {
      path    : '/my',
      children: [
        // Patient Results routes have specific isPatientMode param which indicates if it's patient or practitioner view
        {
          name: 'my-results',
          path: '',
          load: () => import(/* webpackChunkName: 'pwd-results' */ './my-results'),
        },
        {
          path    : '/family',
          children: [
            // Patient Results routes have specific isPatientMode param
            // which indicates if it's patient or practitioner view
            {
              name: 'family-member-results',
              path: '/:patientSlug/:familyLinkId',
              load: () => import(/* webpackChunkName: 'pwd-results' */ './family-member-results'),
            },
          ],
        },
      ],

      async action({ next, store }) {
        // Execute each child route until one of them return the result
        const route = await next();
        if (route) {
          store.dispatch(Account.actions.checkPermissions(['LOGGED_IN', 'CG']));
        }
        return route;
      },
    },

    // only for pwd
    {
      path    : '/my',
      children: [
        {
          name: 'data-sources',
          path: '/data-sources',
          load: () => import(/* webpackChunkName: 'patient' */ './data-sources'),
        },
      ],

      async action({ next, store }) {
        // Execute each child route until one of them return the result
        const route = await next();
        if (route) {
          store.dispatch(Account.actions.checkPermissions(['LOGGED_IN', 'PWD']));
        }
        return route;
      },
    },

    // only for hcp
    {
      path    : '/hcp',
      children: [
        {
          path    : '/clinic',
          children: [
            {
              name: 'add-drive',
              path: '/add-drive',
              load: () => import(/* webpackChunkName: 'clinic-mgmt' */ './add-drive'),
            },
          ],
        },

        {
          path    : '/:clinicSlug/:organizationUID/clear-data',
          children: [
            {
              name: 'clinic-clear-data',
              path: '',
              load: () => import(/* webpackChunkName: 'clinic-clear-data' */ './clinic-clear-data'),
            },
          ],

          async action({ next, params, store, redirect, getUrl }) {
            const { clinicSlug, organizationUID } = params;
            const activeClinicMembershipId = get(store.getState(), 'account.activeClinicMembershipId');
            const activeHcpInvitationId = get(store.getState(), 'account.activeHcpInvitationId');
            const clinicHcpMemberships = get(store.getState(), 'account.hcpProfile.clinicHcpMemberships', []);
            const clinicMembership = find(
              clinicHcpMemberships,
              (membership) => membership.clinic.organizationUID === organizationUID,
            );
            if (!clinicMembership) {
              store.dispatch(Account.actions.checkPermissions(['LOGGED_IN', 'HCP']));
              return null;
            }
            if (activeClinicMembershipId !== clinicMembership.clinicHcpMembershipId || activeHcpInvitationId) {
              await store.dispatch(
                Account.actions.setActiveClinicMembershipId(clinicMembership.clinicHcpMembershipId, organizationUID),
              );
            }
            if (!clinicMembership.isAdmin || clinicMembership.clinic.clinicStatus !== 'Deleted') {
              redirect(getUrl('general-population', { clinicSlug, organizationUID }));
              return null;
            }
            const route = await next();
            return route;
          },
        },

        {
          path    : '/:clinicSlug/:organizationUID/settings',
          children: [
            {
              name: 'clinic-settings',
              path: '',
              load: () => import(/* webpackChunkName: 'settings' */ './clinic-settings'),
            },
            {
              name: 'clinic-settings-view',
              path: '/:view',
              load: () => import(/* webpackChunkName: 'settings' */ './clinic-settings'),
            },
          ],

          async action({ next, params, store }) {
            const { organizationUID } = params;
            const activeClinicMembershipId = get(store.getState(), 'account.activeClinicMembershipId');
            const activeHcpInvitationId = get(store.getState(), 'account.activeHcpInvitationId');
            const clinicHcpMemberships = get(store.getState(), 'account.hcpProfile.clinicHcpMemberships', []);
            const clinicMembership = find(
              clinicHcpMemberships,
              (membership) => membership.clinic.organizationUID === organizationUID
                && membership.isAdmin
                && membership.membershipStatus === 'Active'
                && membership.clinic
                && includes(['Active', 'Deleted'], membership.clinic.clinicStatus),
            );
            if (!clinicMembership) {
              store.dispatch(Account.actions.checkPermissions(['LOGGED_IN', 'CLINIC_ADMIN']));
              return null;
            }
            if (activeClinicMembershipId !== clinicMembership.clinicHcpMembershipId || activeHcpInvitationId) {
              await store.dispatch(
                Account.actions.setActiveClinicMembershipId(clinicMembership.clinicHcpMembershipId, organizationUID),
              );
            }
            const route = await next();
            return route;
          },
        },

        {
          path    : '/:clinicSlug/:organizationUID',
          children: [
            {
              name: 'general-population',
              path: '',
              load: () => import(/* webpackChunkName: 'hcp-stats' */ './general-population'),
            },
            {
              name: 'hcp-preview-results',
              path: '/patient-preview-results',
              load: () => import(/* webpackChunkName: 'hcp-results' */ './preview-results'),
            },
            // Patient Results routes have specific isPatientMode param
            // which indicates if it's patient or practitioner view
            {
              name: 'hcp-results',
              path: '/patient/:patientSlug/:patientId',
              load: () => import(/* webpackChunkName: 'hcp-results' */ './hcp-results'),
            },
            {
              name: 'patients-list',
              path: '/patients-list',
              load: () => import(/* webpackChunkName: 'hcp-results' */ './patients-list'),
            },
            {
              name: 'medical-patients-list',
              path: '/medical-patients-list',
              load: () => import(/* webpackChunkName: 'hcp-results' */ './medical-patients-list'),
            },
          ],

          async action({ next, params, store, redirect, getUrl }) {
            const { clinicSlug, organizationUID } = params;
            const activeOrganizationUID = get(store.getState(), 'account.activeOrganizationUID');
            const clinicHcpMemberships = get(store.getState(), 'account.hcpProfile.clinicHcpMemberships', []);
            const organizationMemberships = get(store.getState(), 'information.organizationMemberships', []);
            const clinicMembership = find(
              clinicHcpMemberships,
              (membership) => membership.clinic.organizationUID === organizationUID,
            );
            const organizationMembership = find(
              organizationMemberships,
              (membership) => membership.organization.organizationUID === organizationUID,
            );
            if (!clinicMembership && !organizationMembership) {
              store.dispatch(Account.actions.checkPermissions(['LOGGED_IN', 'HCP']));
              return null;
            }
            const clinicHcpMembershipId = get(clinicMembership, 'clinicHcpMembershipId', null);
            if (activeOrganizationUID !== organizationUID) {
              await store.dispatch(Account.actions.setActiveClinicMembershipId(clinicHcpMembershipId, organizationUID));
            }
            if (clinicMembership && clinicMembership.clinic.clinicStatus === 'Deleted') {
              if (clinicMembership.isAdmin) {
                redirect(getUrl('clinic-clear-data', { clinicSlug, organizationUID }));
              }
              return null;
            }
            const route = await next();
            return route;
          },
        },
      ],

      async action({ next, store }) {
        // Execute each child route until one of them return the result
        const route = await next();
        if (route) {
          store.dispatch(Account.actions.checkPermissions(['LOGGED_IN', 'HCP']));
        }
        return route;
      },
    },

    // open
    {
      name: 'sign-in',
      path: '',
      load: () => import(/* webpackChunkName: 'account' */ './auth'),
    },
    {
      name: 'auth',
      path: '/auth',
      load: () => import(/* webpackChunkName: 'account' */ './auth'),
    },
    {
      name: 'auth-silent',
      path: '/auth-silent',
      load: () => import(/* webpackChunkName: 'account' */ './sign-in-silent'),
    },
    {
      name: 'data-source-auth',
      path: '/data-source-auth',
      load: () => import(/* webpackChunkName: 'account' */ './data-source-auth'),
    },

    {
      name: 'error',
      path: '/error/:code',
      load: () => import(/* webpackChunkName: 'error' */ './error'),
    },

    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      name: 'not-found',
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();
    return route;
  },
};

export default routes;
