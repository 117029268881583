exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1o_Nj{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;margin-bottom:2.4rem;color:#95a1bd}.-dRfr{color:#0a385a}._1Mna9{width:24px;width:2.4rem;height:24px;height:2.4rem;fill:#95a1bd}._3Dxay{fill:#40bf60}._13yJm{fill:#f65663}._2hcvW{padding:1.2rem 3rem}._2hcvW:nth-child(2n){background-color:#f5f9ff}", ""]);

// exports
exports.locals = {
	"summary__item": "_1o_Nj",
	"summary__item--active": "-dRfr",
	"summary__icon": "_1Mna9",
	"summary__icon--success": "_3Dxay",
	"summary__icon--error": "_13yJm",
	"errorsList__item": "_2hcvW"
};