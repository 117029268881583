exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".JYzUo{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;position:relative;-webkit-print-color-adjust:exact!important}.JYzUo .page{margin:.5in .25in;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-pack:justify;justify-content:space-between;color:#000}.JYzUo .page *{font-family:Arial,sans-serif}._29HLZ{width:100%;text-align:right;margin-top:4rem}._29HLZ img{min-height:50px;min-width:105px}._1tyKy{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-direction:column;flex-direction:column;margin:20px 0;height:433px}._1tyKy .blob{-o-object-fit:cover;object-fit:cover}._1tyKy .text{margin:24px 0;font-size:20px;line-height:27px;color:#6f7ea2}", ""]);

// exports
exports.locals = {
	"printableAgpReport": "JYzUo",
	"footer": "_29HLZ",
	"insufficiencyInfo": "_1tyKy"
};