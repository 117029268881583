exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._36j0k{padding:2rem;font-size:14px;font-size:1.4rem}._1SseV{display:-ms-flexbox;display:flex;position:relative}._1SseV:after{content:\" \";position:absolute;top:-2px;top:-.2rem;width:19px;width:1.9rem;height:19px;height:1.9rem;background:#30a8ff;border:3px solid #fff;border-radius:50%}html[dir=ltr] ._1SseV{border-left:1px solid #e0e8f2;padding-left:1.6rem}html[dir=ltr] ._1SseV:after{left:-10px;left:-1rem}html[dir=rtl] ._1SseV{border-right:1px solid #e0e8f2;padding-right:1.6rem}html[dir=rtl] ._1SseV:after{right:-10px;right:-1rem}._2ldyX{display:block;width:110px;width:11rem;font-size:16px;font-size:1.6rem;font-weight:700}._DDA2{display:-ms-flexbox;display:flex;font-weight:700}html[dir=ltr] ._DDA2{padding-left:12.6rem}html[dir=rtl] ._DDA2{padding-right:12.6rem}._7yjR-{width:calc(100% - 11rem)}._1a1KY{display:-ms-flexbox;display:flex;border-radius:5px;color:#6f7ea2;margin-bottom:.8rem}._1a1KY._2Ydx5{background:#f5f9ff}._3SFH3{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;padding:.8rem 1rem;max-width:29%;-ms-flex:0 0 29%;flex:0 0 29%}._3qT9O{max-width:14%;-ms-flex:0 0 14%;flex:0 0 14%}._3SFH3 .uniform-checker{top:-3px;top:-.3rem}._7eJaE{width:53px;width:5.3rem;text-align:right}.F-lBW{width:48px;width:4.8rem;text-align:center}.F-lBW>svg{width:auto;height:100%;max-height:21px;max-height:2.1rem;max-width:40px;max-width:4rem;overflow:hidden}.F-lBW>img{height:21px;height:2.1rem;max-width:40px;max-width:4rem}.FIov3{fill:#30a8ff}._3EfJW{width:48px;width:4.8rem}._3EfJW,._1pE-e{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}._1pE-e{-ms-flex-align:center;align-items:center;width:21px;width:2.1rem;height:21px;height:2.1rem;background:#fff;border:1px solid #e0e8f2;border-radius:5px;overflow:hidden}._1sUTW{width:auto;max-height:100%}._1xPnx{display:block;color:#fff;font-size:10px;font-size:1rem;font-weight:700;line-height:1;height:34px;height:3.4rem}", ""]);

// exports
exports.locals = {
	"root": "_36j0k",
	"month": "_1SseV",
	"month__name": "_2ldyX",
	"table__headers": "_DDA2",
	"month__imports": "_7yjR-",
	"table__row": "_1a1KY",
	"odd": "_2Ydx5",
	"table__col": "_3SFH3",
	"table__col--small": "_3qT9O",
	"readingsCount": "_7eJaE",
	"deviceFigure": "F-lBW",
	"deviceFigure__icon": "FIov3",
	"dataSource": "_3EfJW",
	"dataSource__logo": "_1pE-e",
	"dataSource__logo__img": "_1sUTW",
	"dataSource__logo__initials": "_1xPnx"
};