exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2qCQz{width:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._3YvBq{font-size:8pt;margin-top:5pt}", ""]);

// exports
exports.locals = {
	"agpSectionWrapper": "_2qCQz",
	"agpChartInfo": "_3YvBq"
};