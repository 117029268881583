exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._105T7{grid-template-areas:\"scaleValueLeft sliders\" \"spacel         scaleTime\";grid-template-columns:50px 140px;grid-auto-rows:344px 40px;margin-left:30px}._105T7 .scaleGridLeft{grid-area:gaplt}._105T7 .scaleGridLeft,._105T7 .scaleGridRight{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:justify;justify-content:space-between;z-index:1}._105T7 .scaleGridRight{grid-area:gaprt}", ""]);

// exports
exports.locals = {
	"patientRangesSlider": "_105T7"
};