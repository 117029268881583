exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1lHtf{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;padding:20px}._1lHtf .perPageSelect{width:200px}", ""]);

// exports
exports.locals = {
	"pagination": "_1lHtf"
};