exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2G0gM{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}@keyframes _2G0gM{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}.oKw_R._2djy4 path[fill=white],.oKw_R path:not([fill=white]){fill:#f7d261}._1mEV7._2djy4 path[fill=white],._1mEV7 path:not([fill=white]){fill:#1ea98c}._2HsLn._2djy4 path[fill=white],._2HsLn path:not([fill=white]){fill:#f9707e}._2hV4w{width:auto;height:13px;position:absolute;top:1px;left:8px;overflow:visible}.efBC3{fill:#fff!important}._2CWog{fill:#f7d261}._2_7_I{fill:#1ea98c}._3uAqI{fill:#f9707e}._3hjpb{position:absolute;top:13px;left:50%;-webkit-transform:translate(-50%);-ms-transform:translate(-50%);transform:translate(-50%);font-weight:700;font-size:12px;font-size:1.2rem;line-height:18px;line-height:1.8rem;color:#f5f9ff}", ""]);

// exports
exports.locals = {
	"reading__badge--high": "oKw_R",
	"reading__badge--active": "_2djy4",
	"reading__badge--normal": "_1mEV7",
	"reading__badge--low": "_2HsLn",
	"reading__icon": "_2hV4w",
	"reading__icon--active": "efBC3",
	"reading__icon--high": "_2CWog",
	"reading__icon--normal": "_2_7_I",
	"reading__icon--low": "_3uAqI",
	"reading__text": "_3hjpb",
	"fading": "_2G0gM"
};