exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3SCss{position:relative;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:30px;width:3rem;height:30px;height:3rem}._3SCss:hover{opacity:.75}._3SCss:before{content:\" \";position:absolute;top:0;bottom:0;left:0;right:0;background:#30a8ff;border-radius:8px;opacity:.2}._3_JWx{position:relative;width:16px;width:1.6rem;height:16px;height:1.6rem;fill:#30a8ff}", ""]);

// exports
exports.locals = {
	"link": "_3SCss",
	"link__icon": "_3_JWx"
};