exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Oij7b{margin-top:32px;text-align:center;line-height:1.2}._2Sqis{margin:0 auto 3rem}._2Sqis,._124sw{position:relative;width:60px;width:6rem;height:60px;height:6rem}._124sw{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;background-color:#c9cfdc;border-radius:8px;overflow:hidden}.O5KvW{width:auto;max-height:100%}._2nWij{display:block;color:#fff;font-size:32px;font-size:3.2rem;font-weight:700}._3cwTE{color:#30a8ff;font-size:24px;font-size:2.4rem;font-weight:700;padding:3rem 0}@media (min-width:1024px){._3cwTE{font-size:3.6rem}}._1AnOV{font-size:16px;font-size:1.6rem}", ""]);

// exports
exports.locals = {
	"root": "Oij7b",
	"avatar": "_2Sqis",
	"avatar__imgWrapper": "_124sw",
	"avatar__img": "O5KvW",
	"avatar__initials": "_2nWij",
	"clinicName": "_3cwTE",
	"info": "_1AnOV"
};