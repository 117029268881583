exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3tUHd{height:100px;width:100%}._24md6{top:20px;top:2rem;bottom:20px;bottom:2rem;left:20px;left:2rem;right:20px;right:2rem}.print .nivoChart{height:300px}._3tUHd svg text{dominant-baseline:auto!important}._3tUHd svg text[text-anchor=middle]{-webkit-transform:translateY(18px) rotate(0)!important;-ms-transform:translateY(18px) rotate(0)!important;transform:translateY(18px) rotate(0)!important}._3tUHd svg text[text-anchor=end]{-webkit-transform:translate(-10px,4px) rotate(0)!important;-ms-transform:translate(-10px,4px) rotate(0)!important;transform:translate(-10px,4px) rotate(0)!important}._3tUHd svg text[text-anchor=start]{-webkit-transform:translate(10px,4px) rotate(0)!important;-ms-transform:translate(10px,4px) rotate(0)!important;transform:translate(10px,4px) rotate(0)!important}._3VlPQ{font-size:14px;font-size:1.4rem;line-height:1;background:#fff;padding:9px 12px;border-radius:8px;-webkit-box-shadow:0 4px 16px rgba(50,66,121,.1);box-shadow:0 4px 16px rgba(50,66,121,.1);white-space:nowrap}._3VlPQ p{margin-top:.4em;margin-bottom:.4em}", ""]);

// exports
exports.locals = {
	"root": "_3tUHd",
	"root__inner": "_24md6",
	"tooltip": "_3VlPQ"
};