import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import range from 'lodash/range';
import get from 'lodash/get';
import withStyles from 'isomorphic-style-loader/withStyles';
import Draggable from 'react-draggable';
import AutoSizer from 'react-virtualized-auto-sizer';
import messages from '../../../messages';
import stylesRangesSlider from '../RangesSliders.pcss';
import styles from './BgmRangesSliderPartial.pcss';


/**
 * @DEPRECATED: To use move it and refactor to components/Sliders
 */
class BgmRangesSliderPartial extends React.PureComponent {


  static getDerivedStateFromProps(props, state) {
    const { valueMax, conversion } = props;
    const defaultValues = {
      glucosePostMealHigh: conversion.toDisplay(valueMax * 0.8),
      glucosePostMealLow : conversion.toDisplay(valueMax * 0.3),
      glucosePreMealHigh : conversion.toDisplay(valueMax * 0.8),
      glucosePreMealLow  : conversion.toDisplay(valueMax * 0.3),
    };
    return {
      ...state,
      defaultValues,
    };
  }


  static propTypes = {
    // Explicit props
    valueMin     : PropTypes.number,
    valueMax     : PropTypes.number,
    valueStep    : PropTypes.number,
    valueDragStep: PropTypes.number,
    formValues   : PropTypes.object,
    conversion   : PropTypes.object,
    locked       : PropTypes.bool,

    // Explicit actions
    onRangeChange: PropTypes.func,
    // Implicit props
  };


  static defaultProps = {
    valueMin     : 0,
    valueMax     : 300,
    valueStep    : 50,
    valueDragStep: 5,
    locked       : false,
  }


  constructor() {
    super();
    this.levels = {
      LOW   : 'low',
      TARGET: 'target',
      HIGH  : 'high',
    };
    this.bars = {
      POST_MEAL: 'postMeal',
      PRE_MEAL : 'preMeal',
    };
    this.barWidth = 100;
    this.state = {};
  }

  renderScaleValue() {
    const { valueMax, valueMin, valueStep } = this.props;
    return range(valueMin, valueMax + 1, valueStep).map(
      (value) => (
        <div className="scaleValue__value" key={value}>
          {value}
        </div>
      )
    );
  }


  renderScaleGrid() {
    const { valueMax, valueMin, valueStep } = this.props;
    return range(valueMin, valueMax + 1, valueStep).map(
      (value) => (<div className="scaleGrid__row" key={value} />)
    );
  }


  renderScales() {
    return (
      <>
        <div className="scaleValue scaleValue--left">
          {this.renderScaleValue()}
        </div>
        <div className="scaleGridLeft">
          {this.renderScaleGrid()}
        </div>
        <div className="scaleGridRight">
          {this.renderScaleGrid()}
        </div>
        <div className="scaleValue scaleValue--right">
          {this.renderScaleValue()}
        </div>
      </>
    );
  }


  renderRangeSliderHandler({
    level,
    height,
    value,
    bar,
    top,
  }) {
    return (
      <div
        className={
          cn(
            'rangeSlider',
            {
              'rangeSlider--top'   : level === this.levels.HIGH,
              'rangeSlider--bottom': level === this.levels.LOW,
            }
          )
        }
      >
        <div className="rangeSlider__area" style={{ height: `${height}px`, top: top && `${top}px` }}>
          <FormattedMessage {...messages.labels[level]} />
        </div>
        <button
          className={
            cn(
              'rangeSlider__dragButton',
              {
                'rangeSlider__dragButton--start': bar === this.bars.POST_MEAL,
                'rangeSlider__dragButton--end'  : bar === this.bars.PRE_MEAL,
              }
            )
          }
          type="button"
        >
          {`${value} ${this.props.conversion.unitSymbol}`}
        </button>
      </div>
    );
  }


  renderRangeSliders(height) {
    const { valueMax, valueMin, valueDragStep, formValues, locked } = this.props;
    const { defaultValues } = this.state;
    const countValue = (data) => {
      const offset = (height - data.y) / height * (valueMax - valueMin);
      return (Math.floor(offset) - (Math.floor(offset) % valueDragStep));
    };
    const countOffset = (value) => height - (value / (valueMax - valueMin) * height);

    const values = {
      glucosePostMealHigh: get(formValues, 'values.glucosePostMealHigh', defaultValues.glucosePostMealHigh),
      glucosePostMealLow : get(formValues, 'values.glucosePostMealLow', defaultValues.glucosePostMealLow),
      glucosePreMealHigh : get(formValues, 'values.glucosePreMealHigh', defaultValues.glucosePreMealHigh),
      glucosePreMealLow  : get(formValues, 'values.glucosePreMealLow', defaultValues.glucosePreMealLow),
    };
    const offsets = {
      glucosePostMealHigh: countOffset(values.glucosePostMealHigh),
      glucosePostMealLow : countOffset(values.glucosePostMealLow),
      glucosePreMealHigh : countOffset(values.glucosePreMealHigh),
      glucosePreMealLow  : countOffset(values.glucosePreMealLow),
    };
    return (
      <>
        <div
          className="rangeSegment"
          style={{
            left : 0,
            width: this.barWidth,
          }}
        >
          <Draggable
            axis="y"
            disabled={locked}
            position={{
              y: offsets.glucosePreMealHigh,
              x: 0,
            }}
            onDrag={(e, data) => {
              this.props.onRangeChange({ id: 'glucosePreMealHigh', value: countValue(data) });
            }}
            bounds={{
              top   : 20,
              bottom: offsets.glucosePreMealLow,
            }}
          >
            {
              this.renderRangeSliderHandler({
                level : this.levels.HIGH,
                bar   : this.bars.PRE_MEAL,
                height: offsets.glucosePreMealHigh,
                value : values.glucosePreMealHigh,
              })
            }
          </Draggable>
          <Draggable
            axis="y"
            disabled={locked}
            position={{
              y: offsets.glucosePreMealLow,
              x: 0,
            }}
            onDrag={(e, data) => {
              this.props.onRangeChange({ id: 'glucosePreMealLow', value: countValue(data) });
            }}
            bounds={{
              top   : offsets.glucosePreMealHigh,
              bottom: height - 20,
            }}
          >
            {
              this.renderRangeSliderHandler({
                level : this.levels.LOW,
                bar   : this.bars.PRE_MEAL,
                height: height - offsets.glucosePreMealLow,
                value : values.glucosePreMealLow,
              })
            }
          </Draggable>
          <div
            className="targetArea"
            style={{ top: `${offsets.glucosePreMealHigh}px`, bottom: `${height - offsets.glucosePreMealLow}px` }}
          >
            <FormattedMessage {...messages.labels.target} />
          </div>
        </div>
        <div
          className="rangeSegment"
          style={{
            right: 0,
            width: this.barWidth,
          }}
        >
          <Draggable
            axis="y"
            disabled={locked}
            position={{
              y: offsets.glucosePostMealHigh,
              x: 0,
            }}
            onDrag={(e, data) => {
              this.props.onRangeChange({ id: 'glucosePostMealHigh', value: countValue(data) });
            }}
            bounds={{
              top   : 20,
              bottom: offsets.glucosePostMealLow,
            }}
          >
            {
              this.renderRangeSliderHandler({
                level : this.levels.HIGH,
                bar   : this.bars.POST_MEAL,
                height: offsets.glucosePostMealHigh,
                value : values.glucosePostMealHigh,
              })
            }
          </Draggable>
          <Draggable
            axis="y"
            disabled={locked}
            position={{
              y: offsets.glucosePostMealLow,
              x: 0,
            }}
            onDrag={(e, data) => {
              this.props.onRangeChange({ id: 'glucosePostMealLow', value: countValue(data) });
            }}
            bounds={{
              top   : offsets.glucosePostMealHigh,
              bottom: height - 20,
            }}
          >
            {
              this.renderRangeSliderHandler({
                level : this.levels.LOW,
                bar   : this.bars.POST_MEAL,
                height: height - offsets.glucosePostMealLow,
                value : values.glucosePostMealLow,
              })
            }
          </Draggable>
          <div
            className="targetArea"
            style={{ top: `${offsets.glucosePostMealHigh}px`, bottom: `${height - offsets.glucosePostMealLow}px` }}
          >
            <FormattedMessage {...messages.labels.target} />
          </div>
        </div>
      </>
    );
  }


  renderSlidersLayer() {
    return (
      <div
        className={cn(
          'sliders',
          {
            'sliders--locked': this.props.locked,
          }
        )}
      >
        <AutoSizer>
          {
            ({ height, width }) => (
              <div style={{
                width : `${width}px`,
                height: `${height}px`,
              }}
              >
                {this.renderRangeSliders(height)}
              </div>
            )
          }
        </AutoSizer>
      </div>
    );
  }


  render() {
    return (
      <div className={cn(stylesRangesSlider.rangesSlider, styles.bgmRangesSlider)}>
        {this.renderScales()}
        {this.renderSlidersLayer()}
      </div>
    );
  }

}

export default withStyles(styles, stylesRangesSlider)(BgmRangesSliderPartial);
