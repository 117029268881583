exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".V9wl_{height:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding:10px}.V9wl_ .statisticsSection{border-top:1px solid #e0e8f2;padding:5px 0}.V9wl_ .statisticsSection:first-child{border-top:none}.V9wl_ .statisticsSection__row{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;margin:10px}.V9wl_ .statisticsSection__element{font-weight:500;font-size:13px;line-height:24px;color:#6f7ea2}.V9wl_ .statisticsSection__elementCaption{font-weight:500;font-size:13px;line-height:18px;color:#6f7ea2;margin:10px 0}.V9wl_ .statisticsSection__elementHighlight{font-weight:600;font-size:15px;line-height:24px;color:#0a385a}", ""]);

// exports
exports.locals = {
	"cgmStatistics": "V9wl_"
};