exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".vpwW1{display:grid;grid-template-columns:repeat(7,1fr)}._3dOGB{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;border:1px solid #e0e8f2;border-top:none}._2Ee_d{display:inline-block;padding:5px;margin:1rem;border:none;text-align:left;height:auto}._2Ee_d span{display:block}._2Ee_d span:first-child{font-size:15px;line-height:20px;font-weight:800;vertical-align:middle}._2Ee_d span:nth-child(2){font-size:13px;line-height:18px;font-weight:500;vertical-align:middle;color:#6f7ea2}._3s2Ib span:nth-child(2){color:#fff}._1xgeS{color:#6f7ea2;font-size:14px;font-size:1.4rem;font-weight:700;margin-bottom:2rem}._1nRdo{font-weight:800;letter-spacing:.05em;text-transform:uppercase;font-size:10px;color:#6f7ea2;margin:3rem 0 1rem}.VeMPU{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-direction:column;flex-direction:column;margin:20px 0;height:433px}.VeMPU .blob{-o-object-fit:cover;object-fit:cover}.VeMPU .text{margin:24px 0;font-size:20px;line-height:27px;color:#6f7ea2}", ""]);

// exports
exports.locals = {
	"daySelector": "vpwW1",
	"daySelector__day": "_3dOGB",
	"daySelector__dayButton": "_2Ee_d",
	"daySelector__dayButton--active": "_3s2Ib",
	"unit": "_1xgeS",
	"subsection": "_1nRdo",
	"insufficiencyInfo": "VeMPU"
};