import { MODULE_ID } from './constants';


export const SET_PHI_SET = `${MODULE_ID}/SET_PHI_SET`;
export const SET_READINGS = `${MODULE_ID}/SET_READINGS`;
export const SET_IMPORTED_READINGS = `${MODULE_ID}/SET_IMPORTED_READINGS`;
export const SET_IMPORTED_MEASUREMENTS = `${MODULE_ID}/SET_IMPORTED_MEASUREMENTS`;
export const SET_NOTES = `${MODULE_ID}/SET_NOTES`;
export const CLEAR_NOTES_BATCHES = `${MODULE_ID}/CLEAR_NOTES_BATCHES`;

// SHARING REQUESTS ----------------------------------------------------------------------------------------------------

export const SET_SHARING_REQUEST = `${MODULE_ID}/SET_SHARING_REQUEST`;
export const UNSET_SHARING_REQUEST = `${MODULE_ID}/UNSET_SHARING_REQUEST`;

export const SET_BEING_APPROVED_SHARING_REQUEST_ID = `${MODULE_ID}/SET_BEING_APPROVED_SHARING_REQUEST_ID`;
export const UNSET_BEING_APPROVED_SHARING_REQUEST_ID = `${MODULE_ID}/UNSET_BEING_APPROVED_SHARING_REQUEST_ID`;

export const FETCH_SHARING_REQUESTS = `${MODULE_ID}/FETCH_SHARING_REQUESTS`;
export const FETCH_SHARING_REQUESTS_SUCCESS = `${MODULE_ID}/FETCH_SHARING_REQUESTS_SUCCESS`;
export const FETCH_SHARING_REQUESTS_ERROR = `${MODULE_ID}/FETCH_SHARING_REQUESTS_ERROR`;

export const ENROLL_IN_CLINIC = `${MODULE_ID}/ENROLL_IN_CLINIC`;
export const ENROLL_IN_CLINIC_SUCCESS = `${MODULE_ID}/ENROLL_IN_CLINIC_SUCCESS`;
export const ENROLL_IN_CLINIC_ERROR = `${MODULE_ID}/ENROLL_IN_CLINIC_ERROR`;

export const APPROVE_SHARING_REQUEST = `${MODULE_ID}/APPROVE_SHARING_REQUEST`;
export const APPROVE_SHARING_REQUEST_SUCCESS = `${MODULE_ID}/APPROVE_SHARING_REQUEST_SUCCESS`;
export const APPROVE_SHARING_REQUEST_ERROR = `${MODULE_ID}/APPROVE_SHARING_REQUEST_ERROR`;

export const REVOKE_SHARING_REQUEST = `${MODULE_ID}/REVOKE_SHARING_REQUEST`;
export const REVOKE_SHARING_REQUEST_SUCCESS = `${MODULE_ID}/REVOKE_SHARING_REQUEST_SUCCESS`;
export const REVOKE_SHARING_REQUEST_ERROR = `${MODULE_ID}/REVOKE_SHARING_REQUEST_ERROR`;

// FAMILY MEMBERS ------------------------------------------------------------------------------------------------------

export const INVITE_FAMILY_MEMBERS = `${MODULE_ID}/INVITE_FAMILY_MEMBERS`;
export const INVITE_FAMILY_MEMBERS_SUCCESS = `${MODULE_ID}/INVITE_FAMILY_MEMBERS_SUCCESS`;
export const INVITE_FAMILY_MEMBERS_ERROR = `${MODULE_ID}/INVITE_FAMILY_MEMBERS_ERROR`;
export const SYNC = `${MODULE_ID}/SYNC`;
export const SYNC_SUCCESS = `${MODULE_ID}/SYNC_SUCCESS`;
export const SYNC_ERROR = `${MODULE_ID}/SYNC_ERROR`;

export const FETCH_FAMILY_SHARING_LINKS = `${MODULE_ID}/FETCH_FAMILY_SHARING_LINKS`;
export const FETCH_FAMILY_SHARING_LINKS_SUCCESS = `${MODULE_ID}/FETCH_FAMILY_SHARING_LINKS_SUCCESS`;
export const FETCH_FAMILY_SHARING_LINKS_ERROR = `${MODULE_ID}/FETCH_FAMILY_SHARING_LINKS_ERROR`;

export const FETCH_FAMILY_SHARING_LINK_REQUESTS = `${MODULE_ID}/FETCH_FAMILY_SHARING_LINK_REQUESTS`;
export const FETCH_FAMILY_SHARING_LINK_REQUESTS_SUCCESS = `${MODULE_ID}/FETCH_FAMILY_SHARING_LINK_REQUESTS_SUCCESS`;
export const FETCH_FAMILY_SHARING_LINK_REQUESTS_ERROR = `${MODULE_ID}/FETCH_FAMILY_SHARING_LINK_REQUESTS_ERROR`;

export const REVOKE_FAMILY_ACCESS = `${MODULE_ID}/REVOKE_FAMILY_ACCESS`;
export const REVOKE_FAMILY_ACCESS_SUCCESS = `${MODULE_ID}/REVOKE_FAMILY_ACCESS_SUCCESS`;
export const REVOKE_FAMILY_ACCESS_ERROR = `${MODULE_ID}/REVOKE_FAMILY_ACCESS_ERROR`;

export const ACTIVATE_FAMILY_MEMBER = `${MODULE_ID}/ACTIVATE_FAMILY_MEMBER`;
export const ACTIVATE_FAMILY_MEMBER_SUCCESS = `${MODULE_ID}/ACTIVATE_FAMILY_MEMBER_SUCCESS`;
export const ACTIVATE_FAMILY_MEMBER_ERROR = `${MODULE_ID}/ACTIVATE_FAMILY_MEMBER_ERROR`;

export const DEACTIVATE_FAMILY_MEMBER = `${MODULE_ID}/DEACTIVATE_FAMILY_MEMBER`;

//----------------------------------------------------------------------------------------------------------------------

export const STORE_NOTES = `${MODULE_ID}/STORE_NOTES`;
export const STORE_NOTES_SUCCESS = `${MODULE_ID}/STORE_NOTES_SUCCESS`;
export const STORE_NOTES_ERROR = `${MODULE_ID}/STORE_NOTES_ERROR`;

//----------------------------------------------------------------------------------------------------------------------

export const ON_STORE_READINGS_SUCCESS = `${MODULE_ID}/ON_STORE_READINGS_SUCCESS`;
export const ON_STORE_READINGS_SUCCESS_SUCCESS = `${MODULE_ID}/ON_STORE_READINGS_SUCCESS_SUCCESS`;
export const ON_STORE_READINGS_SUCCESS_ERROR = `${MODULE_ID}/ON_STORE_READINGS_SUCCESS_ERROR`;

export const ON_STORE_MEASUREMENT_SUCCESS = `${MODULE_ID}/ON_STORE_MEASUREMENT_SUCCESS`;
export const ON_STORE_MEASUREMENT_SUCCESS_SUCCESS = `${MODULE_ID}/ON_STORE_MEASUREMENT_SUCCESS_SUCCESS`;
export const ON_STORE_MEASUREMENT_SUCCESS_ERROR = `${MODULE_ID}/ON_STORE_MEASUREMENT_SUCCESS_ERROR`;

export const SET_IMPORTED_RELATED_DATA = `${MODULE_ID}/SET_IMPORTED_RELATED_DATA`;

export const EXTERNAL_DATA_SOURCES_MIGRATION = `${MODULE_ID}/EXTERNAL_DATA_SOURCES_MIGRATION`;

export const REAUTH_CONTOUR_CLOUD = `${MODULE_ID}/REAUTH_CONTOUR_CLOUD`;
export const REAUTH_CONTOUR_CLOUD_SUCCESS = `${MODULE_ID}/REAUTH_CONTOUR_CLOUD_SUCCESS`;
export const REAUTH_CONTOUR_CLOUD_ERROR = `${MODULE_ID}/REAUTH_CONTOUR_CLOUD_ERROR`;

export const MIGRATION_SHARING_REQUEST = `${MODULE_ID}/MIGRATION_SHARING_REQUEST`;
export const MIGRATION_SHARING_REQUEST_SUCCESS = `${MODULE_ID}/MIGRATION_SHARING_REQUEST_SUCCESS`;
export const MIGRATION_SHARING_REQUEST_ERROR = `${MODULE_ID}/MIGRATION_SHARING_REQUEST_ERROR`;
