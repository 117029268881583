exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Nqp4{position:relative;color:#6f7ea3;padding:32px 40px}", ""]);

// exports
exports.locals = {
	"patientsGestationalReport": "_3Nqp4"
};