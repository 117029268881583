exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PYaEL{position:relative}.PYaEL>:first-child{opacity:1}.PYaEL>:nth-child(2){opacity:.5}.PYaEL>:nth-child(3){opacity:.3}.PYaEL>:nth-child(4){opacity:.2}.PYaEL>:nth-child(n+5){opacity:.1}.c6KoK{position:relative}._1VneR{opacity:1!important}", ""]);

// exports
exports.locals = {
	"stack": "PYaEL",
	"stack__element": "c6KoK",
	"stack__element--isActive": "_1VneR"
};