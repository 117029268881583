exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1C0BS{position:fixed;z-index:2147483645;background-color:#fff;width:26%;max-width:375px;border-radius:2.5px;bottom:1em;right:1em;max-height:90%;overflow-x:hidden;overflow-y:auto;-webkit-box-shadow:0 0 18px rgba(0,0,0,.2);box-shadow:0 0 18px rgba(0,0,0,.2);padding:55px 25px 25px}._1T8e0{position:absolute;padding:20px;top:0;right:0;cursor:pointer}.WRZkO{width:10px;height:10px}._1j-kG{font-size:13px;line-height:1.5;color:#0a385a;margin-bottom:0}", ""]);

// exports
exports.locals = {
	"cookiesBox": "_1C0BS",
	"cookiesBox__closeWrapper": "_1T8e0",
	"cookiesBox__closeIcon": "WRZkO",
	"cookiesBox__content": "_1j-kG"
};