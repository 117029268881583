import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import App from 'modules/App';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import Widget from 'components/Widget';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from '../Results/Results.pcss';


class AgpTrendChartWidget extends React.PureComponent {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }).isRequired,
    className    : PropTypes.string,
    start        : PropTypes.number,
    end          : PropTypes.number,
    data         : PropTypes.array,
    isInProgress : PropTypes.bool,
    // Explicit actions
    onDatesChange: PropTypes.func,
    // Implicit props
    mode         : PropTypes.oneOf(constants.MODES),
    // Implicit actions
  };


  render() {
    const {
      className,
      data,
    } = this.props;

    return (
      <Widget
        headerMessage={data.length >= constants.READING_COUNT_TO_SHOW_AGP_BGM
          ? messages.headers.ambulatoryGlucoseProfile : messages.headers.modalDay
        }
        className={cn(className, 'mb-1')}
      >
        <div className={styles.wrapper}>
          <AmbulatoryGlucoseProfile.components.AgpProfileChart
            conversion={this.props.conversion}
            standards={this.props.standards}
            mode={this.props.mode}
            start={this.props.start}
            end={this.props.end}
            onDatesChange={this.props.onDatesChange}
            data={data}
            isInProgress={this.props.isInProgress}
          />
        </div>
      </Widget>
    );
  }

}


const mapStateToProps = (state) => ({
  mode              : selectors.mode(state),
  deviceMode        : selectors.deviceMode(state),
  calculationFormula: selectors.calculationFormula(state),
  aggregateBy       : selectors.aggregateBy(state),
  groupBy           : selectors.groupBy(state),
  printMode         : App.selectors.printMode(state),
});


const mapDispatchToProps = (dispatch) => ({
  onSetMode              : (mode) => dispatch(actions.setMode(mode)),
  onSetDeviceMode        : (mode) => dispatch(actions.setDeviceMode(mode)),
  onSetCalculationFormula: (calculationFormula) => dispatch(actions.setCalculationFormula(calculationFormula)),
  onSetAggregateBy       : (aggregateBy) => dispatch(actions.setAggregateBy(aggregateBy)),
  onSetGroupBy           : (groupBy) => dispatch(actions.setGroupBy(groupBy)),
});


const ConnectedAgpTrendChartWidget = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AgpTrendChartWidget));


export default ConnectedAgpTrendChartWidget;
