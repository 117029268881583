exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._20w07{height:420px;width:100%}._2303X{top:20px;top:2rem;bottom:20px;bottom:2rem;left:20px;left:2rem;right:20px;right:2rem}.print .nivoChart{height:300px}._20w07 svg text{dominant-baseline:auto!important}._20w07 svg text[text-anchor=middle]{-webkit-transform:translateY(18px) rotate(0)!important;-ms-transform:translateY(18px) rotate(0)!important;transform:translateY(18px) rotate(0)!important}._20w07 svg text[text-anchor=end]{-webkit-transform:translate(-10px,4px) rotate(0)!important;-ms-transform:translate(-10px,4px) rotate(0)!important;transform:translate(-10px,4px) rotate(0)!important}._20w07 svg text[text-anchor=start]{-webkit-transform:translate(10px,4px) rotate(0)!important;-ms-transform:translate(10px,4px) rotate(0)!important;transform:translate(10px,4px) rotate(0)!important}", ""]);

// exports
exports.locals = {
	"root": "_20w07",
	"root__inner": "_2303X"
};