import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import AutoSizer from 'react-virtualized-auto-sizer';
import cn from 'classnames';
import { motionConfigContext, SmartMotion } from '@nivo/core';
import { Line } from '@nivo/line';
import range from 'lodash/range';
import keys from 'lodash/keys';
import maxBy from 'lodash/maxBy';
import moment from 'moment';
import * as patientResultsConstants from 'modules/PatientResults/constants';
import styles from './BloodGlucoseProfileAgpMiniChart.pcss';


class BloodGlucoseProfileAgpMiniChart extends React.PureComponent {

  static getDerivedStateFromProps(props) {
    const {
      records,
    } = props;

    const maxRecord = maxBy(records, 'value');

    return {
      maxValue: maxRecord ? maxRecord.value : 0,
    };
  }

  static propTypes = {
    // Explicit props
    conversion  : PropTypes.object.isRequired,
    records     : PropTypes.array,
    direction   : PropTypes.string,
    isInProgress: PropTypes.bool,
  };


  constructor(props) {
    super(props);
    this.state = {
      maxValue: 0,
    };

    this.colors = {
      stroke          : '#1EA98C',
      target          : '#CBEBE5',
      targetZone      : '#F4F7FA',
      targetZoneBorder: '#e0e8f2',
      high            : '#F4C32C',
      low             : '#F74053',
    };
  }


  get chartData() {
    const { records, conversion } = this.props;
    if (!records) {
      return [{
        id  : 'Glucose Profile',
        data: [],
      }];
    }

    return [
      {
        id  : 'Glucose Profile',
        data: records.map(({ value, timestamp }) => {
          const recordMoment = moment.unix(timestamp).utc();
          return {
            x: recordMoment.hours() + (recordMoment.minutes() / 60),
            y: conversion.toDisplay(value),
          };
        }),
      },
    ];
  }


  renderCircles({ series, xScale, yScale }) {
    const { conversion, isInProgress } = this.props;
    const lowThreshold = patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].target;
    const highThreshold = patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].high;
    return (
      <g>
        {series[0].data.map((d) => {
          let fill = isInProgress ? this.colors.targetZoneBorder : this.colors.stroke;
          if (d.data.y > highThreshold) {
            fill = isInProgress ? this.colors.targetZoneBorder : this.colors.high;
          } else if (d.data.y < lowThreshold) {
            fill = isInProgress ? this.colors.targetZoneBorder : this.colors.low;
          }
          return (
            <circle
              key={`${d.data.x}-${d.data.y}`}
              fill={fill}
              cx={xScale(d.data.x)}
              cy={yScale(d.data.y)}
              r="4px"
            />
          );
        })}
      </g>
    );
  }


  renderTargetZone(props) {
    const { conversion } = this.props;
    const lowThreshold = patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].target;
    const highThreshold = patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].high;
    const width = props.width - props.margin.right - props.margin.left;
    const yTop = props.yScale(highThreshold);
    const yBottom = props.yScale(lowThreshold);

    return (
      <motionConfigContext.Consumer>
        {
          (springConfig) => (
            <SmartMotion
              key="bloodGlucoseConcentrationTargetZone"
              style={(spring) => ({
                width: spring(width, springConfig),
              })}
            >
              {
                (style) => (
                  <g>
                    <polygon
                      points={`0,${yTop} ${style.width},${yTop} ${style.width},${yBottom} 0,${yBottom}`}
                      fill={this.colors.targetZone}
                    />-
                    <line
                      x1="0"
                      y1={yTop}
                      x2={style.width}
                      y2={yTop}
                      style={{ stroke: this.colors.targetZoneBorder }}
                    />
                    <line
                      x1="0"
                      y1={yBottom}
                      x2={style.width}
                      y2={yBottom}
                      style={{ stroke: this.colors.targetZoneBorder }}
                    />
                  </g>
                )
              }
            </SmartMotion>
          )
        }
      </motionConfigContext.Consumer>
    );
  }


  renderAxes(props) {
    const { width, height } = props;
    return (
      <motionConfigContext.Consumer>
        {
          (springConfig) => (
            <SmartMotion
              key="bloodGlucoseConcentrationTargetZone"
              style={(spring) => ({
                width : spring(width, springConfig),
                height: spring(height, springConfig),
              })}
            >
              {
                (style) => (
                  <g>
                    {
                      keys(Array(4)).map((number) => (
                        <line
                          key={`line-left-${number}`}
                          x1="0"
                          y1={((style.height) / (4)) * number + 10}
                          x2="8"
                          y2={((style.height) / (4)) * number + 10}
                          style={{ stroke: this.colors.targetZoneBorder }}
                        />
                      ))
                    }
                    {
                    keys(Array(4)).map((number) => (
                      <line
                        key={`line-left-${number}`}
                        x1={style.width - 8}
                        y1={((style.height) / (4)) * number + 10}
                        x2={style.width}
                        y2={((style.height) / (4)) * number + 10}
                        style={{ stroke: this.colors.targetZoneBorder }}
                      />
                    ))
                    }
                    <line
                      x1={style.width / 2}
                      y1={style.height}
                      x2={style.width / 2}
                      y2={style.height - 8}
                      style={{ stroke: this.colors.targetZoneBorder }}
                    />
                    <line
                      x1={style.width / 2}
                      y1="0"
                      x2={style.width / 2}
                      y2="8"
                      style={{ stroke: this.colors.targetZoneBorder }}
                    />
                  </g>
                )
              }
            </SmartMotion>
          )
        }
      </motionConfigContext.Consumer>
    );
  }


  render() {
    const { maxValue } = this.state;
    const { direction, conversion, isInProgress } = this.props;
    const margin = {
      top   : 0,
      right : 0,
      bottom: 0,
      left  : 0,
    };
    /*
      IMPORTANT: Because Edge doesn't support `dominant-baseline` and RTL support is poor
      hardcoded styles overwrite ticks labels transform for current tickPadding and tickRotation values and
      must be correlated if change
     */
    return (
      <div className={cn(styles.root, { fadingLoader: isInProgress })}>
        <AutoSizer>
          {
              ({ height, width }) => (
                <Line
                  data={this.chartData}
                  height={height}
                  width={width}
                  margin={margin}
                  xScale={{ type: 'linear', min: 0, max: 24, reverse: direction === 'rtl' }}
                  yScale={{ type: 'linear',
                    min : conversion.toDisplay(0),
                    max : conversion.toDisplay(Math.max(350, maxValue)) }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize    : 5,
                    tickPadding : 5,
                    tickRotation: 0,
                    tickValues  : range(24),
                  }}
                  axisLeft={null}
                  colors={[isInProgress ? this.colors.targetZoneBorder : this.colors.stroke]}
                  lineWidth={2}
                  enableCrosshair={false}
                  curve="monotoneX"
                  layers={[
                    'markers',
                    'axes',
                    'areas',
                    'slices',
                    'legends',
                    this.renderTargetZone.bind(this),
                    this.renderCircles.bind(this),
                    this.renderAxes.bind(this),
                  ]}
                />
              )
            }
        </AutoSizer>
      </div>
    );
  }

}


export default withStyles(styles)(BloodGlucoseProfileAgpMiniChart);
