exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3kKk8{display:block;position:relative}._3-Pfp{margin-bottom:1.5rem}._2pm2l{height:36px;height:3.6rem;line-height:1;font-size:12px;font-size:1.2rem;background:#f5f9ff;border:none;border-radius:10rem}html[dir=ltr] ._2pm2l{padding-right:5rem}html[dir=rtl] ._2pm2l{padding-left:5rem}._2pm2l::-webkit-input-placeholder{color:#6f7ea2}._2pm2l::-moz-placeholder{color:#6f7ea2}._2pm2l:-ms-input-placeholder{color:#6f7ea2}._2pm2l::-ms-input-placeholder{color:#6f7ea2}._2pm2l::placeholder{color:#6f7ea2}._1VRmG{position:absolute;top:50%;width:14px;width:1.4rem;height:14px;height:1.4rem;margin-top:-.7rem;fill:#6f7ea2}html[dir=ltr] ._1VRmG{right:20px;right:2rem}html[dir=rtl] ._1VRmG{left:20px;left:2rem}", ""]);

// exports
exports.locals = {
	"searchPatients": "_3kKk8",
	"searchPatients__formGroup": "_3-Pfp",
	"searchPatients__formGroup__input": "_2pm2l",
	"searchPatients__icon": "_1VRmG"
};