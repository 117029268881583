exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1mISt{opacity:.1}._1mISt,.t4Y93{stroke:#30a8ff;fill:rgba(0,0,0,0)}.t4Y93{opacity:.3}._1TToc{stroke:#30a8ff;fill:rgba(0,0,0,0);-webkit-transition:stroke-dashoffset .3s;-o-transition:stroke-dashoffset .3s;transition:stroke-dashoffset .3s;-webkit-transform:rotate(-90deg);-ms-transform:rotate(-90deg);transform:rotate(-90deg);-webkit-transform-origin:50% 50%;-ms-transform-origin:50% 50%;transform-origin:50% 50%}.rRCxy{fill:#30a8ff;font-weight:800}._36Mp4{stroke:#30a8ff;stroke-width:4px;width:50px;width:5rem;height:50px;height:5rem}._3seev{stroke:#30a8ff;fill:rgba(0,0,0,0);stroke-linejoin:round;stroke-linecap:round;stroke-miterlimit:10;-webkit-transition:stroke-dashoffset .5s ease-out;-o-transition:stroke-dashoffset .5s ease-out;transition:stroke-dashoffset .5s ease-out}", ""]);

// exports
exports.locals = {
	"backgroundCircle": "_1mISt",
	"pathCircle": "t4Y93",
	"progressCircle": "_1TToc",
	"percentText": "rRCxy",
	"startIcon": "_36Mp4",
	"tick": "_3seev"
};