import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware, startListener } from 'redux-first-routing';
import createSagaMiddleware from 'redux-saga';
import createReduxWaitForMiddleware from 'redux-wait-for-action';

import includes from 'lodash/includes';
import keysIn from 'lodash/keysIn';
import forEach from 'lodash/forEach';

import { name, version } from '../../package.json';
import createReducer, { getCoreReducers } from './reducers';
// import createHelpers from './createHelpers';
import createLogger from './logger';


export default function configureStore(initialState, context, history) {
  // const helpers = createHelpers(helpersConfig);
  const sagaMiddleware = createSagaMiddleware();
  sagaMiddleware.setContext(context);
  const middleware = [
    routerMiddleware(history),
    sagaMiddleware,
    createReduxWaitForMiddleware(),
  ];

  let enhancer;

  if (__DEV__) {
    middleware.push(createLogger());

    // https://github.com/zalmoxisus/redux-devtools-extension#14-using-in-production
    // eslint-disable-next-line global-require
    const { composeWithDevTools } = require('redux-devtools-extension/developmentOnly');
    const composeEnhancers = composeWithDevTools({
      // Options: https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#options
      name          : `${name}@${version}`,
      trace         : true,
      traceLimit    : 25,
      stateSanitizer: (state) => ({
        ...state,
        notifications: {
          ...state.notifications,
          notifications: `<<LIST OF NOTIFICATIONS: ${state.notifications.notifications.length}>>`,
        },
      }),
    });

    // https://redux.js.org/docs/api/applyMiddleware.html
    enhancer = composeEnhancers(applyMiddleware(...middleware));
  } else {
    enhancer = applyMiddleware(...middleware);
  }

  // Because reducers are created dynamically shape of the state rendered by server,
  // which is final for route, is different core reducers expect. To avoid error we
  // create pseudo-reducers for initial store creation. In next step routes registering
  // own async reducers and overwrite initial pseudo-reducers.
  const initialReducers = {};
  const coreReducersKeys = keysIn(getCoreReducers());
  forEach(initialState, (state, key) => {
    if (!includes(coreReducersKeys, key)) {
      initialReducers[key] = (initState = state) => initState;
    }
    return state;
  });
  // See https://github.com/rackt/redux/releases/tag/v3.1.0
  const store = createStore(createReducer(initialReducers), initialState, enhancer);
  if (history) {
    startListener(history, store);
  }
  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.asyncReducers = initialReducers; // Async reducer registry
  store.asyncSagas = []; // Async sagas registry

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (__DEV__ && module.hot) {
    module.hot.accept('./reducers', () =>
      // eslint-disable-next-line global-require
      store.replaceReducer(require('./reducers').default),
    );
  }

  return store;
}
