exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cClYj{grid-template-areas:\"scaleValueLeft gaplt spacectl sliders   spacectr   gaprt  scaleValueRight\" \"spacel         gaplb spacecbl scaleTime spacecbr   gaprb  spacer\";grid-template-columns:40px 1fr 20px 200px 20px 1fr 40px;grid-auto-rows:250px 40px}.cClYj .scaleGridLeft{grid-area:gaplt}.cClYj .scaleGridLeft,.cClYj .scaleGridRight{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:justify;justify-content:space-between;z-index:1}.cClYj .scaleGridRight{grid-area:gaprt}", ""]);

// exports
exports.locals = {
	"bgmRangesSlider": "cClYj"
};