exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3HsSK{display:block;position:relative;max-width:430px}._2NnCk{margin-bottom:1.5rem}.wtejs{height:36px;height:3.6rem;line-height:1;font-size:12px;font-size:1.2rem;background:#f5f9ff;border:none;border-radius:8px}html[dir=ltr] .wtejs{padding-right:5rem}html[dir=rtl] .wtejs{padding-left:5rem}.wtejs::-webkit-input-placeholder{color:#6f7ea2}.wtejs::-moz-placeholder{color:#6f7ea2}.wtejs:-ms-input-placeholder{color:#6f7ea2}.wtejs::-ms-input-placeholder{color:#6f7ea2}.wtejs::placeholder{color:#6f7ea2}._7w0nl{position:absolute;top:50%;width:14px;width:1.4rem;height:14px;height:1.4rem;margin-top:-.7rem;fill:#6f7ea2}html[dir=ltr] ._7w0nl{right:20px;right:2rem}html[dir=rtl] ._7w0nl{left:20px;left:2rem}", ""]);

// exports
exports.locals = {
	"searchPatients": "_3HsSK",
	"searchPatients__formGroup": "_2NnCk",
	"searchPatients__formGroup__input": "wtejs",
	"searchPatients__icon": "_7w0nl"
};