exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".pAyGG{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._3p79S{width:160px;width:16rem;height:160px;height:16rem;fill:#30a8ff}", ""]);

// exports
exports.locals = {
	"loaderContainer": "pAyGG",
	"loader": "_3p79S"
};