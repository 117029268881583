exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2L1zb{cursor:pointer;color:#95a1bd;font-size:14px;font-size:1.4rem;font-weight:700}._2L1zb svg{width:20px;height:20px;fill:#95a1bd;margin-right:10px}", ""]);

// exports
exports.locals = {
	"locked": "_2L1zb"
};