import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Account from 'modules/Account';
import { useConnectorConsentTemplate } from '../hooks';

import DownloadDataModal from './DownloadDataModal';

import Initializing from './DownloadDataModal/Initializing';
import Consents from './DownloadDataModal/Consents';

import Connect from './DownloadDataModal/Connect';
import ConnectionFailed from './DownloadDataModal/ConnectionFailed';
import NoLicense from './DownloadDataModal/NoLicense';

import Waiting from './DownloadDataModal/Waiting';
import Downloading from './DownloadDataModal/Downloading';
import Timeout from './DownloadDataModal/Timeout';
import DownloadSummary from './DownloadDataModal/DownloadSummary';

import AddPatient from './DownloadDataModal/AddPatient';
import ChoosePatient from './DownloadDataModal/ChoosePatient';


const components = {
  Initializing,
  Consents,

  Connect,
  ConnectionFailed,
  NoLicense,

  Waiting,
  Downloading,
  Timeout,

  DownloadSummary,

  AddPatient,
  ChoosePatient,
};


const HcpDownloadDataModal: FC = () => {
  const activeClinicMembership: ClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const connectorType: ConnectorType = get(activeClinicMembership, 'clinic.settings.connectors.default', 'MDA');
  const connectorConsentTemplate = useConnectorConsentTemplate(connectorType);
  const defaultComponentName = connectorConsentTemplate.length ? 'Consents' : 'Initializing';
  return <DownloadDataModal {...{ connectorType, components, defaultComponentName }} />;
};

export default HcpDownloadDataModal;
