exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BHmP9{min-height:100vh;width:100%}.BHmP9,.IG3x9{display:-ms-flexbox;display:flex}.IG3x9{margin:auto;-ms-flex-direction:column;flex-direction:column}", ""]);

// exports
exports.locals = {
	"root": "BHmP9",
	"root__container": "IG3x9"
};