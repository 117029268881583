exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1ZJoN{width:32px;height:18px;border-radius:4px;background:#fff;position:relative;cursor:pointer;-webkit-transition:all .3s ease;-o-transition:all .3s ease;transition:all .3s ease;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:start;justify-content:flex-start;padding:3px;border:1px solid #30a8ff}html[dir=ltr] ._1ZJoN{margin-right:8px}html[dir=rtl] ._1ZJoN{margin-left:8px}._18QK5{background:#30a8ff}html[dir=ltr] ._18QK5{padding-left:16px}html[dir=rtl] ._18QK5{padding-right:16px}._2d1mA{background:#f5f9ff;border:1px solid #e0e8f2}._1Wm2h{width:12px;height:12px;background:#30a8ff;border-radius:2px}._118HT{background:#fff}._3ZP_3{background:#e0e8f2}._1u6Di{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}.EWEY4{font-size:14px;font-size:1.4rem;color:#0a385a}", ""]);

// exports
exports.locals = {
	"switch": "_1ZJoN",
	"switch--on": "_18QK5",
	"switch--disabled": "_2d1mA",
	"switch__cursor": "_1Wm2h",
	"switch__cursor--on": "_118HT",
	"switch__cursor--disabled": "_3ZP_3",
	"switch__container": "_1u6Di",
	"switch__label": "EWEY4"
};