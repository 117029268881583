exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2gHtC{height:80px;width:100%;position:relative}._2gHtC:after,._2gHtC:before{content:\"\";position:absolute;left:50%;height:5px;border-right:1px solid #000}._2gHtC:before{top:0}._2gHtC:after{bottom:0}._2WXSJ{top:20px;top:2rem;bottom:20px;bottom:2rem;left:20px;left:2rem;right:20px;right:2rem}.print .nivoChart{height:300px}._2gHtC svg text{dominant-baseline:auto!important}._2gHtC svg text[text-anchor=middle]{-webkit-transform:translateY(18px) rotate(0)!important;-ms-transform:translateY(18px) rotate(0)!important;transform:translateY(18px) rotate(0)!important}._2gHtC svg text[text-anchor=end]{-webkit-transform:translate(-10px,4px) rotate(0)!important;-ms-transform:translate(-10px,4px) rotate(0)!important;transform:translate(-10px,4px) rotate(0)!important}._2gHtC svg text[text-anchor=start]{-webkit-transform:translate(10px,4px) rotate(0)!important;-ms-transform:translate(10px,4px) rotate(0)!important;transform:translate(10px,4px) rotate(0)!important}", ""]);

// exports
exports.locals = {
	"root": "_2gHtC",
	"root__inner": "_2WXSJ"
};