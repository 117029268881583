import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import LogoutBtn from 'modules/Account/partials/LogoutBtn';
import { AppContext } from 'context';
import Link from 'components/Link';
import urlRouteShape from 'shapes/urlRouteShape';
import App from 'modules/App';
import * as accountSelectors from 'modules/Account/selectors';
import * as selectors from '../../selectors';
import messages from '../../messages';
import Settings from '../../partials/Settings';
import styles from './Setup.pcss';


class Setup extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Implicit props
    connectedDataSources     : PropTypes.array,
    activeProfileMainUrlRoute: urlRouteShape,
  };


  renderFooter() {
    if (isEmpty(this.props.connectedDataSources)) {
      return (
        <p className="text--right">
          <FormattedMessage {...messages.infos.doItLater} />
          {' '}
          <Link to={this.context.getUrl('invite-family')}><FormattedMessage {...messages.infos.skipForNow} /></Link>
        </p>
      );
    }
    return (
      <p className="text--right">
        <Link to={this.context.getUrl('invite-family')}><FormattedMessage {...messages.infos.goNext} /></Link>
      </p>
    );
  }


  render() {
    return (
      <div className={styles.root}>
        <LogoutBtn />
        <header className="pageHeader">
          <h1 className="text--h1"><FormattedMessage {...messages.headers.dataSourcesSetup} /></h1>
          {/* <p className="pageHeader__intro"><FormattedMessage {...messages.intros.dataSourcesSetup} /></p> */}
        </header>
        <App.components.AlertsBus className="mb-5" />
        <Settings authType="setup" />
        { this.renderFooter() }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  connectedDataSources     : selectors.connectedDataSources(state),
  activeProfileMainUrlRoute: accountSelectors.activeProfileMainUrlRoute(state),
});


const ConnectedSetup = connect(
  mapStateToProps,
)(Setup);


export default ConnectedSetup;
