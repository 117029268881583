import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/withStyles';
import { round } from 'mathjs';
import AutoSizer from 'react-virtualized-auto-sizer';
import { motionConfigContext, SmartMotion } from '@nivo/core';
import { Line } from '@nivo/line';
import range from 'lodash/range';
import maxBy from 'lodash/maxBy';
import * as patientResultsConstants from 'modules/PatientResults/constants';
import * as constants from '../constants';
import styles from './BloodGlucoseProfileAgpChart.pcss';


class BloodGlucoseProfileAgpChart extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const {
      highlightedHourlyRecords, isInProgress,
    } = props;

    if (
      highlightedHourlyRecords === state.highlightedHourlyRecords
        && isInProgress === state.isInProgress && !state.isInProgress
    ) {
      return null;
    }

    const data = [];
    let maxValue = 0;

    for (let i = 0; i < 24; i++) {
      const hourlyRecord = highlightedHourlyRecords.records.find((record) => parseInt(record.hour, 10) === i);
      if (hourlyRecord) {
        data.push(hourlyRecord);
        const maxRecord = maxBy(hourlyRecord.records, 'value');
        maxValue = Math.max(maxValue, maxRecord ? maxRecord.value : 0);
      } else {
        data.push({
          hour                : String(i).padStart(2, '0'),
          records             : null,
          percentileStatistics: {
            5 : null,
            10: null,
            25: null,
            50: null,
            75: null,
            90: null,
            95: null,
          },
        });
      }
    }

    return {
      isInProgress: props.isInProgress,
      data,
      otherState  : data,
      highlightedHourlyRecords,
      maxValue,
    };
  }

  static propTypes = {
    // Explicit props
    showMedian: PropTypes.bool,
    showIQR   : PropTypes.bool,
    showP10P90: PropTypes.bool,
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      minValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    direction   : PropTypes.string,
    showModalDay: PropTypes.bool,
    isInProgress: PropTypes.bool,
  };


  static defaultProps = {
    showMedian: true,
    showIQR   : true,
    showP10P90: true,
  }


  constructor(props) {
    super(props);
    this.state = {
      data                    : [],
      highlightedHourlyRecords: {},
      isInProgress            : props.isInProgress,
      maxValue                : 0,
    };
    this.colors = {
      high  : '#F4C32C',
      target: '#1EA98C',
      low   : '#F74053',
    };
  }


  get chartData() {
    const { data } = this.state;
    const { conversion, showMedian, showIQR, showP10P90, showModalDay } = this.props;
    const median = [];
    const p10 = [];
    const p25 = [];
    const p75 = [];
    const p90 = [];
    let records = [];
    data.forEach((hourlyRecord) => {
      const x = Number(hourlyRecord.hour) + 0.5;
      const { hour } = hourlyRecord;
      const fullDataInfo = {
        p10: conversion.toDisplay(hourlyRecord.percentileStatistics[10]),
        p25: conversion.toDisplay(hourlyRecord.percentileStatistics[25]),
        p50: conversion.toDisplay(hourlyRecord.percentileStatistics[50]),
        p75: conversion.toDisplay(hourlyRecord.percentileStatistics[75]),
        p90: conversion.toDisplay(hourlyRecord.percentileStatistics[90]),
      };

      if (hourlyRecord.records) {
        records = records.concat(
          hourlyRecord.records.map((record) => ({
            fullDataInfo,
            hour,
            minutes: record.time.minutes,
            y      : conversion.toDisplay(record.value),
            x      : record.time.hour + round(record.time.minutes / 60, 2),
          }))
        );
      }
      median.push({
        fullDataInfo,
        hour,
        x,
        y: conversion.toDisplay(hourlyRecord.percentileStatistics[50]),
      });
      p10.push({
        fullDataInfo,
        hour,
        x,
        y: conversion.toDisplay(hourlyRecord.percentileStatistics[10]),
      });
      p25.push({
        fullDataInfo,
        hour,
        x,
        y: conversion.toDisplay(hourlyRecord.percentileStatistics[25]),
      });
      p75.push({
        fullDataInfo,
        hour,
        x,
        y: conversion.toDisplay(hourlyRecord.percentileStatistics[75]),
      });
      p90.push({
        fullDataInfo,
        hour,
        x,
        y: conversion.toDisplay(hourlyRecord.percentileStatistics[90]),
      });
    });

    const chartData = [{ id: 'records', data: records, color: 'transparent' }];
    if (!showModalDay) {
      if (showMedian) {
        chartData.push({ id: 'median', data: median, color: constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.MEDIAN });
      }
      if (showIQR) {
        chartData.push({ id: 'p25', data: p25, color: constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.IQR });
        chartData.push({ id: 'p75', data: p75, color: constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.IQR });
      }
      if (showP10P90) {
        chartData.push({ id: 'p10', data: p10, color: constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.P10P90 });
        chartData.push({ id: 'p90', data: p90, color: constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.P10P90 });
      }
    }
    return chartData;
  }


  renderTooltip(point) {
    const { hour, minutes, y, fullDataInfo } = point.data;
    const { p10, p25, p50, p75, p90 } = fullDataInfo;
    const { conversion, direction, showModalDay } = this.props;
    const { unitSymbol } = conversion;

    if (showModalDay) {
      return (
        <div className={styles.tooltip}>
          <p className="text--light row flex-nowrap justify-content-between" style={{ direction }}>
            <span className="col-auto">
              { `${hour}:${String(minutes).padStart(2, '0')}` }
            </span>
          </p>
          <div className="p">
            <p className="text--large text--bold text--primary">{ y } { unitSymbol }</p>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.tooltip}>
        <p className="text--light row flex-nowrap justify-content-between" style={{ direction }}>
          <span className="col-auto">
            { `${String(+hour).padStart(2, '0')}:00 - ${String(+hour + 1).padStart(2, '0')}:00` }
          </span>
        </p>
        <div className="p">
          <p className="text--large text--bold text--primary">P10 = { p10 } { unitSymbol }</p>
          <p className="text--large text--bold text--primary">P25 = { p25 } { unitSymbol }</p>
          <p className="text--large text--bold text--primary">P50 = { p50 } { unitSymbol }</p>
          <p className="text--large text--bold text--primary">P75 = { p75 } { unitSymbol }</p>
          <p className="text--large text--bold text--primary">P90 = { p90 } { unitSymbol }</p>
        </div>
      </div>
    );
  }


  getPointColor(line) {
    if (this.props.isInProgress) return constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.PLACEHOLDER;
    if (line.id === 'median') { return constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.MEDIAN; }
    if (line.id === 'p10') { return constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.P10P90; }
    if (line.id === 'p25') { return constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.IQR; }
    if (line.id === 'p75') { return constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.IQR; }
    if (line.id === 'p90') { return constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.P10P90; }
    if (line.id === 'records') { return constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.RECORDS; }
    return constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.PLACEHOLDER;
  }


  getRecordColor(y) {
    const { conversion, isInProgress } = this.props;
    if (isInProgress) return constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.PLACEHOLDER;
    if (y > patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].high) return this.colors.high;
    if (y < patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].target) return this.colors.low;
    return this.colors.target;
  }


  renderPoint({ size, color, borderColor, borderWidth, datum }) {
    if (color !== constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.RECORDS) {
      return null;
    }
    const { y } = datum;
    const point = (
      <circle
        r={size / 2}
        fill={this.getRecordColor(y)}
        stroke={borderColor}
        strokeWidth={borderWidth}
        style={{ pointerEvents: 'none' }}
      />
    );
    return (
      <g>
        { point }
      </g>
    );
  }


  renderDashedLine({ series, lineGenerator, xScale, yScale }) {
    const { isInProgress } = this.props;
    const styleById = {
      p10: {
        strokeDasharray: '12, 6',
        strokeWidth    : 1,
      },
      p90: {
        strokeDasharray: '12, 6',
        strokeWidth    : 1,
      },
      median: {
        strokeWidth: 3,
      },
      default: {
        strokeWidth: 1,
      },
    };

    return series.map(({ id, data, color }) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: d.data.y ? yScale(d.data.y) : null,
          }))
        )}
        fill="none"
        stroke={isInProgress ? constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.PLACEHOLDER : color}
        style={styleById[id] || styleById.default}
      />
    ));
  }


  renderTargetZone(props) {
    if (this.props.isInProgress) {
      return null;
    }
    const { conversion } = this.props;
    const lowThreshold = conversion.toDisplay(70);
    const highThreshold = conversion.toDisplay(180);
    const width = props.width - props.margin.right - props.margin.left;
    const yTop = props.yScale(highThreshold);
    const yBottom = props.yScale(lowThreshold);

    return (
      <motionConfigContext.Consumer>
        {
          (springConfig) => (
            <SmartMotion
              key="bloodGlucoseConcentrationTargetZone"
              style={(spring) => ({
                width: spring(width, springConfig),
              })}
            >
              {(style) => (
                <g>
                  <rect
                    rx={8}
                    ry={8}
                    y={yTop}
                    x={-80}
                    width={style.width + 80}
                    height={yBottom - yTop}
                    fill="#F5F9FF"
                  />
                  <text
                    x={-60}
                    y={yTop + 14}
                    fontSize="12"
                    fill="#0A385A"
                    style={{ fontWeight: 'bold' }}
                  >
                    {patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].high}
                  </text>
                  <text
                    x={-60}
                    y={yBottom - 4}
                    fontSize="12"
                    fill="#0A385A"
                    style={{ fontWeight: 'bold' }}
                  >
                    {patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].target}
                  </text>
                </g>
              )}
            </SmartMotion>
          )
        }
      </motionConfigContext.Consumer>
    );
  }


  percentiles({ series, xScale, yScale }) {
    const { showModalDay, showMedian, showIQR, showP10P90, conversion } = this.props;
    const lastPoint = series[0].data[series[0].data.length - 1];

    if (!lastPoint || showModalDay) {
      return null;
    }

    const values = {
      p10   : lastPoint.data.fullDataInfo.p10,
      p25   : lastPoint.data.fullDataInfo.p25,
      median: lastPoint.data.y,
      p75   : lastPoint.data.fullDataInfo.p75,
      p90   : lastPoint.data.fullDataInfo.p90,
    };

    const fontSizeWithGap = 16;

    if (values.p25 + conversion.toDisplay(fontSizeWithGap) > values.median) {
      values.p25 = values.median - conversion.toDisplay(fontSizeWithGap);
    }

    if (values.p10 + conversion.toDisplay(fontSizeWithGap) > values.p25) {
      values.p10 = values.p25 - conversion.toDisplay(fontSizeWithGap);
    }

    if (values.p75 - conversion.toDisplay(fontSizeWithGap) < values.median) {
      values.p75 = values.median + conversion.toDisplay(fontSizeWithGap);
    }

    if (values.p90 - conversion.toDisplay(fontSizeWithGap) < values.p75) {
      values.p90 = values.p75 + conversion.toDisplay(fontSizeWithGap);
    }


    return (
      <g opacity={1}>
        {
          lastPoint && (
            <>
              {
                showP10P90
                  && (
                    <text
                      x={xScale(24) + 8}
                      y={yScale(values.p10) + 4}
                    >10%
                    </text>
                  )
              }

              {
                showIQR
                  && (
                    <text
                      x={xScale(24) + 8}
                      y={yScale(values.p25) + 4}
                    >25%
                    </text>
                  )
              }
              {
                showMedian
                  && (
                    <text
                      x={xScale(24) + 8}
                      y={yScale(values.median) + 4}
                    >50%
                    </text>
                  )
              }

              {
                showIQR
                  && (
                    <text
                      x={xScale(24) + 8}
                      y={yScale(values.p75) + 4}
                    >75%
                    </text>
                  )
              }

              {
                showP10P90
                  && (
                    <text
                      x={xScale(24) + 8}
                      y={yScale(values.p90) + 4}
                    >90%
                    </text>
                  )
              }
            </>
          )
        }
      </g>
    );
  }


  render() {
    const { maxValue } = this.state;
    const { direction, conversion, standards } = this.props;
    const margin = {
      top   : 30,
      right : direction === 'rtl' ? 80 : 40,
      bottom: 30,
      left  : direction === 'ltr' ? 80 : 40,
    };
    const axisY = {
      tickSize    : 5,
      tickPadding : 5,
      tickRotation: 0,
      tickValues  : 5,
    };

    return (
      <div className={`nivoChart ${styles.root}`}>
        <div
          className={`nivoChart__inner ${styles.root__inner}`}
        >
          <AutoSizer>
            {
              ({ height, width }) => (
                <Line
                  data={this.chartData}
                  height={height}
                  width={width}
                  margin={margin}
                  xScale={{ type: 'linear', min: 0, max: 24, reverse: direction === 'rtl' }}
                  yScale={{
                    type   : 'linear',
                    stacked: false,
                    min    : conversion.toDisplay(standards.minValue),
                    max    : conversion.toDisplay(Math.max(standards.maxValue, maxValue)),
                  }}
                  axisTop={null}
                  axisRight={direction === 'ltr' ? null : axisY}
                  axisLeft={direction === 'rtl' ? null : axisY}
                  axisBottom={{
                    tickSize  : 0,
                    tickValues: range(24.5),
                    format    : (value) => (value % 3 ? null : moment(value, 'hh').format('LT')),
                  }}
                  enableGridX={false}
                  lineWidth={2}
                  pointSize={8}
                  pointColor={(line) => this.getPointColor(line)}
                  colors={(line) => line.color}
                  pointSymbol={(props) => this.renderPoint(props)}
                  crosshairType={direction === 'ltr' ? 'bottom-left' : 'bottom-right'}
                  tooltip={({ point }) => this.renderTooltip(point)}
                  // curve="monotoneX"
                  useMesh
                  layers={[
                    this.renderTargetZone.bind(this),
                    this.percentiles.bind(this),
                    'grid', 'markers', 'axes', 'points',
                    'areas',
                    this.renderDashedLine.bind(this),
                    'slices',
                    'mesh',
                    'legends',
                  ]}
                />
              )
            }
          </AutoSizer>
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(BloodGlucoseProfileAgpChart);
