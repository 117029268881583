exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3ylcg{-ms-flex:1 1 auto;flex:1 1 auto;height:100%}.FNrBj{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;height:100%}._2Mq0Z{font-size:32px;font-size:3.2rem;font-weight:700;text-align:center}.Cu1VV{margin-left:.4rem;fill:#30a8ff;width:16px;width:1.6rem;height:16px;height:1.6rem}", ""]);

// exports
exports.locals = {
	"pairingCodeContainer": "_3ylcg",
	"pairingCodeContainerInner": "FNrBj",
	"pairingCode": "_2Mq0Z",
	"copyIcon": "Cu1VV"
};