import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import AutoSizer from 'react-virtualized-auto-sizer';
import { motionConfigContext, SmartMotion } from '@nivo/core';
import { Line } from '@nivo/line';
import { curveMonotoneX, line, area } from 'd3-shape';
import styles from './BloodGlucoseProfileReportMiniChart.pcss';


class BloodGlucoseProfileMiniChart extends React.PureComponent {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    records  : PropTypes.arrayOf(PropTypes.object),
    direction: PropTypes.string,
  };


  constructor(props) {
    super(props);
    this.colors = {
      stroke: '#1EA98C',
      target: '#C7C8CA',
      high  : '#FFF200',
      low   : '#ED1C24',
      line  : '#000',
    };
  }


  get chartData() {
    const { records, conversion } = this.props;
    if (!records) {
      return [{
        id  : 'Glucose Profile',
        data: [],
      }];
    }
    const hourlyRecords = records.map(({ hour, percentileStatistics }) => ({ percentileStatistics, hour: Number(hour) }));
    return [
      {
        id  : 'Glucose Profile',
        data: hourlyRecords.map(({ percentileStatistics, hour }) => ({
          x: Number(hour),
          y: conversion.toDisplay(percentileStatistics['50']),
          hour,
        })),
      },
    ];
  }


  area({ series, xScale, yScale, ...props }) {
    const { conversion, standards } = this.props;
    const lowThreshold = conversion.toDisplay(standards.postMeal.lowThreshold);
    const highThreshold = conversion.toDisplay(standards.postMeal.highThreshold);
    const width = props.width - props.margin.right - props.margin.left;
    const maxY = yScale(999);
    const minY = yScale(0);
    const highY = yScale(highThreshold);
    const lowY = yScale(lowThreshold);

    const lineGenerator = line()
      .x((d) => xScale(d.data.x))
      .y((d) => yScale(d.data.y))
      .curve(curveMonotoneX);

    const upperArea = area()
      .x((d) => xScale(d.data.x))
      .y0((d) => yScale(d.data.y))
      .y1(() => yScale(0))
      .curve(curveMonotoneX);

    const lowerArea = area()
      .x((d) => xScale(d.data.x))
      .y0(() => yScale(999))
      .y1((d) => yScale(d.data.y))
      .curve(curveMonotoneX);

    return (
      <g>
        <clipPath id="clipPathHigh" clipPathUnits="userSpaceOnUse">
          <polygon
            points={`0,${maxY} ${width},${maxY} ${width},${highY} 0,${highY}`}
            fill="#000000"
          />
        </clipPath>
        <clipPath id="clipPathTarget" clipPathUnits="userSpaceOnUse">
          <polygon
            points={`0,${highY} ${width},${highY} ${width},${lowY} 0,${lowY}`}
            fill="#000000"
          />
        </clipPath>
        <clipPath id="clipPathLow" clipPathUnits="userSpaceOnUse">
          <polygon
            points={`0,${lowY} ${width},${lowY} ${width},${minY} 0,${minY}`}
            fill="#000000"
          />
        </clipPath>
        <path
          stroke={this.colors.line}
          style={{
            clipPath: 'url(#clipPathHigh)',
          }}
          d={upperArea(series[0].data)}
          fill={this.colors.high}
          strokeWidth={0.5}
        />
        <path
          stroke={this.colors.line}
          d={lineGenerator(series[0].data)}
          strokeWidth={0.5}
          fill="none"
          style={{
            clipPath: 'url(#clipPathTarget)',
          }}
        />
        <path
          stroke={this.colors.line}
          style={{
            clipPath: 'url(#clipPathLow)',
          }}
          d={lowerArea(series[0].data)}
          fill={this.colors.low}
          strokeWidth={0.5}
        />
      </g>
    );
  }


  renderTargetZone(props) {
    const { conversion, standards } = this.props;
    const lowThreshold = conversion.toDisplay(standards.postMeal.lowThreshold);
    const highThreshold = conversion.toDisplay(standards.postMeal.highThreshold);
    const width = props.width - props.margin.right - props.margin.left;
    const yTop = props.yScale(highThreshold);
    const yBottom = props.yScale(lowThreshold);

    return (
      <motionConfigContext.Consumer>
        { (springConfig) => (
          <SmartMotion
            key="bloodGlucoseConcentrationTargetZone"
            style={(spring) => ({
              width: spring(width, springConfig),
            })}
          >
            {(style) => (
              <g opacity={0.5}>
                <polygon
                  points={`0,${yTop} ${style.width},${yTop} ${style.width},${yBottom} 0,${yBottom}`}
                  fill={this.colors.target}
                />
              </g>
            )}
          </SmartMotion>
        )}
      </motionConfigContext.Consumer>
    );
  }


  render() {
    const { direction, conversion, standards } = this.props;
    const margin = {
      top   : 0,
      right : 0,
      bottom: 0,
      left  : 0,
    };
    /*
      IMPORTANT: Because Edge doesn't support `dominant-baseline` and RTL support is poor
      hardcoded styles overwrite ticks labels transform for current tickPadding and tickRotation values and
      must be correlated if change
     */
    return (
      <div className={styles.root}>
        <AutoSizer>
          {
              ({ height, width }) => (
                <Line
                  data={this.chartData}
                  height={height}
                  width={width}
                  margin={margin}
                  xScale={{ type: 'linear', min: 0, max: 23, reverse: direction === 'rtl' }}
                  yScale={{ type: 'linear',
                    min : conversion.toDisplay(standards.minValue),
                    max : conversion.toDisplay(standards.maxValue) }}
                  colors={[this.colors.stroke]}
                  lineWidth={2}
                  enableCrosshair={false}
                  curve="monotoneX"
                  layers={[
                    'areas',
                    this.renderTargetZone.bind(this),
                    this.area.bind(this),
                  ]}
                />
              )
            }
        </AutoSizer>
      </div>
    );
  }

}


export default withStyles(styles)(BloodGlucoseProfileMiniChart);
