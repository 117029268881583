exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3hv9H{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;margin-bottom:1rem}.chartContainer--in-progress ._3hv9H{color:#c9cfdc}._3uENW{text-align:center;font-weight:600;font-size:15px;margin-bottom:2rem}.chartContainer--in-progress ._3uENW{color:#c9cfdc}._2D4cq{margin:2rem;border-top:1px solid #e0e8f2}", ""]);

// exports
exports.locals = {
	"glucoseLevel__header": "_3hv9H",
	"glucoseLevel__verticalHeader": "_3uENW",
	"glucoseLevel__verticalHeader__hr": "_2D4cq"
};