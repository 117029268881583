exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1SsSD{height:.5in;margin:.25in 0;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;width:100%}._1SsSD .reportName{font-size:20pt;-ms-flex:1 1;flex:1 1}._1SsSD .reportName__title{border:1px solid #000;padding:.2em;display:inline-block;text-transform:capitalize}._1SsSD .reportInfo{width:3.75in;height:100%;font-size:9pt;font-weight:600;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:distribute;justify-content:space-around}._1SsSD .reportInfo__row{border-bottom:2px solid #000;display:-ms-flexbox;display:flex;padding-bottom:1pt}._1SsSD .reportInfo__rowTitle{width:20%;text-transform:uppercase}._1SsSD .reportInfo__rowContent{-ms-flex:1 1;flex:1 1}", ""]);

// exports
exports.locals = {
	"headerSection": "_1SsSD"
};