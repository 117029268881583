exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1fAi4{min-width:384px;max-height:533px}.PJvQf{width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}._3ekbQ{font-weight:700;max-width:450px;width:100%;padding:16px}._150IK{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;white-space:nowrap;font-size:12px;font-size:1.2rem}._1wI6C{color:#6f7ea2;margin-bottom:16px}html[dir=ltr] ._1wI6C{text-align:right}html[dir=rtl] ._1wI6C{text-align:left}._1wI6C>span{color:#0a385a}._3kLCl{overflow:hidden;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex:1 1;flex:1 1;min-width:90px}html[dir=ltr] ._3kLCl{margin-right:16px;text-align:right}html[dir=rtl] ._3kLCl{margin-left:16px;text-align:left}._3bCmx{background:#f5f9ff;padding:8px;border-radius:16px}.x5tkS{border-radius:8px;width:48px;background:grey;overflow:hidden;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._3bH-w{overflow:hidden;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex:2 1;flex:2 1}html[dir=ltr] ._3bH-w{margin-left:16px;text-align:left}html[dir=rtl] ._3bH-w{margin-right:16px;text-align:right}html[dir=ltr] ._1m_ml{margin-right:16px}html[dir=rtl] ._1m_ml{margin-left:16px}.MwScc{position:relative;min-height:18px;color:#6f7ea2;cursor:text}.MwScc.activeNoting{color:#30a8ff;cursor:pointer}.x5tkS>.MwScc{cursor:default}._3THtb{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}", ""]);

// exports
exports.locals = {
	"glucoseConcentrationLevels__widget": "_1fAi4",
	"glucoseConcentrationLevels__container": "PJvQf",
	"glucoseConcentrationLevels__content": "_3ekbQ",
	"glucoseConcentrationLevels": "_150IK",
	"glucoseConcentrationLevels__totalReadings": "_1wI6C",
	"glucoseConcentrationLevels__levelsContainer": "_3kLCl",
	"glucoseConcentrationLevels__barContainer": "_3bCmx",
	"glucoseConcentrationLevels__bar": "x5tkS",
	"glucoseConcentrationLevels__resultsContainer": "_3bH-w",
	"glucoseConcentrationLevels__resultsPercentage": "_1m_ml",
	"glucoseConcentrationLevels__barSegment": "MwScc",
	"glucoseConcentrationLevels__barSegmentResults": "_3THtb"
};