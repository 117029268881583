exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1kU53{color:#6f7ea2;font-size:15px;font-size:1.5rem;margin:5rem 0 2.5rem}._1dvs6{list-style:none}._1_9iF{padding:2rem;background:#fff;border:1px solid #e0e8f2;border-radius:8px;margin-bottom:1rem}.DwwF8{padding:0 2rem;border:none;background:rgba(0,0,0,0)}._2mGqz{min-height:40px;min-height:4rem}._3g7Ir{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;width:250px;width:25rem}._34kJ8{font-size:20px;font-size:2rem;font-weight:700;line-height:1}._23OQb{margin-top:.5rem;font-weight:400;color:#6f7ea2}._2bWZi{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:40px;width:4rem;height:40px;height:4rem;padding:5px;background:#fff;border:1px solid #e0e8f2;border-radius:8px;overflow:hidden}._16Yf9{width:auto;max-height:100%}._27nfI{display:block;color:#fff;font-size:18px;font-size:1.8rem;font-weight:700;line-height:1.8;height:34px;height:3.4rem}._3V_rT{height:0;overflow:hidden}._3V_rT .tag{font-size:13px;font-size:1.3rem;text-transform:uppercase;color:#6f7ea2;margin:0}._3V_rT tr:last-child{border-bottom:none}._2sVAi{display:block;height:15px;height:1.5rem;width:20px;width:2rem;margin-right:1.2rem;fill:#30a8ff}", ""]);

// exports
exports.locals = {
	"dataSourcesIntro": "_1kU53",
	"dataSources": "_1dvs6",
	"dataSource": "_1_9iF",
	"dataSource--standalone": "DwwF8",
	"dataSource__headerRow": "_2mGqz",
	"dataSource__status": "_3g7Ir",
	"dataSource__name": "_34kJ8",
	"dataSource__info": "_23OQb",
	"dataSource__logo": "_2bWZi",
	"dataSource__logo__img": "_16Yf9",
	"dataSource__logo__initials": "_27nfI",
	"dataSource__capabilities": "_3V_rT",
	"dataSource__capability__icon": "_2sVAi"
};