exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1hVj4{border-left:1px solid #ccc;padding-left:40px;padding-right:20px}._1hVj4 .targetsPicker{border-radius:5px;overflow:hidden}._1hVj4 .title{margin-bottom:44px;position:relative;-webkit-transition:color .2s;-o-transition:.2s color;transition:color .2s}._1hVj4 .collapseButton{position:absolute;right:0;border:none;background:none;padding:0;margin:0;cursor:pointer;-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg);-webkit-transition:-webkit-transform .2s;transition:-webkit-transform .2s;-o-transition:.2s transform;transition:transform .2s;transition:transform .2s,-webkit-transform .2s}._1hVj4 .targetsPicker--open .title{color:#30a8ff}._1hVj4 .targetsPicker--open .collapseButton{-webkit-transform:rotate(0);-ms-transform:rotate(0);transform:rotate(0)}._1tnEv{border-left:none}._2EUrZ{margin-bottom:60px}._2EUrZ,._3lit6{margin-left:20px;margin-right:20px}._3lit6{margin-bottom:40px;display:-ms-flexbox;display:flex;-ms-flex-pack:end;justify-content:flex-end}", ""]);

// exports
exports.locals = {
	"targetsPicker": "_1hVj4",
	"withoutBorder": "_1tnEv",
	"headerTargetRanges": "_2EUrZ",
	"locked": "_3lit6"
};