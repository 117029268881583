import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/withStyles';
import { round } from 'mathjs';
import AutoSizer from 'react-virtualized-auto-sizer';
import { motionConfigContext, SmartMotion } from '@nivo/core';
import { Line } from '@nivo/line';
import { area } from 'd3-shape';
import range from 'lodash/range';
import messages from 'modules/AmbulatoryGlucoseProfile/messages';
import * as patientResultsConstants from 'modules/PatientResults/constants';
import * as constants from '../constants';
import styles from './BloodGlucoseProfileAgpReportChart.pcss';


class BloodGlucoseProfileAgpReportChart extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const {
      highlightedHourlyRecords, isInProgress,
    } = props;

    if (
      highlightedHourlyRecords === state.highlightedHourlyRecords
        && isInProgress === state.isInProgress && !state.isInProgress
    ) {
      return null;
    }

    const data = [];

    for (let i = 0; i < 24; i++) {
      const hourlyRecord = highlightedHourlyRecords.records.find((record) => parseInt(record.hour, 10) === i);
      if (hourlyRecord) {
        data.push(hourlyRecord);
      } else {
        data.push({
          hour                : String(i).padStart(2, '0'),
          records             : null,
          percentileStatistics: {
            25: null,
            50: null,
            75: null,
          },
        });
      }
    }

    return {
      isInProgress: props.isInProgress,
      data,
      otherState  : data,
      highlightedHourlyRecords,
    };
  }


  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      minValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    direction   : PropTypes.string,
    showModalDay: PropTypes.bool,
    isInProgress: PropTypes.bool,
    maxValue    : PropTypes.number,
  };


  constructor(props) {
    super(props);
    this.state = {
      data                    : [],
      highlightedHourlyRecords: {},
      isInProgress            : props.isInProgress,
    };
    this.colors = {
      high  : '#f8ee20',
      target: '#a4a6a8',
      low   : '#ec1f28',
      iqr   : '#82a3ce',
    };
  }


  get chartData() {
    const { data } = this.state;
    const { conversion, showModalDay } = this.props;
    const lines = [];
    let records = [];
    data.forEach((hourlyRecord) => {
      const x = Number(hourlyRecord.hour) + 0.5;
      const { hour } = hourlyRecord;
      const fullDataInfo = {
        p25: conversion.toDisplay(hourlyRecord.percentileStatistics[25]),
        p50: conversion.toDisplay(hourlyRecord.percentileStatistics[50]),
        p75: conversion.toDisplay(hourlyRecord.percentileStatistics[75]),
      };

      if (hourlyRecord.records) {
        records = records.concat(
          hourlyRecord.records.map((record) => ({
            fullDataInfo,
            hour,
            minutes: record.time.minutes,
            y      : conversion.toDisplay(record.value),
            x      : record.time.hour + round(record.time.minutes / 60, 2),
          }))
        );
      }
      lines.push({
        fullDataInfo,
        hour,
        x,
        y  : conversion.toDisplay(hourlyRecord.percentileStatistics[50]),
        p25: conversion.toDisplay(hourlyRecord.percentileStatistics[25]),
        p75: conversion.toDisplay(hourlyRecord.percentileStatistics[75]),
      });
    });

    const firstLine = lines[0];
    const lastLine = lines[lines.length - 1];
    if (firstLine.y && lastLine.y) {
      const connectedLine = {
        y  : (firstLine.y + lastLine.y) / 2,
        p25: (firstLine.p25 + lastLine.p25) / 2,
        p75: (firstLine.p75 + lastLine.p75) / 2,
      };
      connectedLine.fullDataInfo = connectedLine;
      lines.splice(0, 0, {
        ...connectedLine,
        x: 0,
      });
      lines.push({
        ...connectedLine,
        x: 24,
      });
    }

    const chartData = [
      { id: 'records', data: records, color: 'transparent' },
    ];

    if (!showModalDay) {
      chartData.push({ id: 'lines', data: lines, color: 'black' });
    }
    return chartData;
  }


  getPointColor(line) {
    if (this.props.isInProgress) return constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.PLACEHOLDER;
    if (line.id === 'median') { return constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.MEDIAN; }
    if (line.id === 'records') { return constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.RECORDS; }
    return constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.PLACEHOLDER;
  }


  getRecordColor(y) {
    const { conversion, isInProgress } = this.props;
    if (isInProgress) return constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.PLACEHOLDER;
    if (y > patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].high) return this.colors.high;
    if (y < patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].target) return this.colors.low;
    return this.colors.target;
  }


  renderPoint({ size, color, datum }) {
    if (color !== constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.RECORDS) {
      return null;
    }
    const { y } = datum;
    const point = (
      <circle
        r={size / 2}
        fill={this.getRecordColor(y)}
        stroke="#7d7d7d"
        strokeWidth={1}
        style={{ pointerEvents: 'none' }}
      />
    );
    return (
      <g>
        { point }
      </g>
    );
  }


  renderDashedLine({ series, lineGenerator, xScale, yScale }) {
    const { isInProgress } = this.props;
    const styleById = {
      lines: {
        strokeWidth: 3,
      },
      default: {
        strokeWidth: 0,
      },
    };

    return series.filter((item) => item.id === 'lines').map(({ id, data, color }) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: d.data.y ? yScale(d.data.y) : null,
          }))
        )}
        fill="none"
        stroke={isInProgress ? constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.PLACEHOLDER : color}
        style={styleById[id] || styleById.default}
      />
    ));
  }


  renderAreaBetweenIQR({ series, xScale, yScale }) {
    const { isInProgress } = this.props;

    const outerPercentileGenerator = area()
      .x((d) => xScale(d.data.x))
      .y0((d) => yScale(d.data.p25))
      .y1((d) => yScale(d.data.p75))
      .defined((d) => (d.data.p25 || d.data.p75));

    return series.filter((item) => item.id === 'lines').map(({ id, data }) => (
      <path
        key={`area-${id}`}
        d={outerPercentileGenerator(data)}
        fill={isInProgress ? constants.BLOOD_GLUCOSE_PROFILE_AGP_CHART_COLORS.PLACEHOLDER : this.colors.iqr}
        fillOpacity={1}
        stroke={null}
        strokeWidth={0}
      />
    ));
  }


  renderTargetZone(props) {
    if (this.props.isInProgress) {
      return null;
    }
    const { conversion } = this.props;
    const lowThreshold = patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].target;
    const highThreshold = patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].high;
    const width = props.width - props.margin.right - props.margin.left;
    const yTop = props.yScale(highThreshold);
    const yBottom = props.yScale(lowThreshold);

    return (
      <motionConfigContext.Consumer>
        {
          (springConfig) => (
            <SmartMotion
              key="bloodGlucoseConcentrationTargetZone"
              style={(spring) => ({
                width: spring(width, springConfig),
              })}
            >
              {(style) => (
                <g>
                  <defs>
                    <filter x="0" y="0" width="1" height="1" id="solid">
                      <feFlood floodColor="white" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <rect
                    y={yTop}
                    x={-34}
                    width={style.width + 34}
                    height={yBottom - yTop}
                    stroke="#6fc47d"
                    strokeWidth={1}
                    fill="transparent"
                  />
                  <text
                    x={-28}
                    y={yTop + 14}
                    fontSize="10"
                    fill="#0A385A"
                    style={{ fontWeight: 'bold' }}
                  >
                    {patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].high}
                  </text>
                  <text
                    x={-22}
                    y={yBottom - 4}
                    fontSize="10"
                    fill="#0A385A"
                    style={{ fontWeight: 'bold' }}
                  >
                    {patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].target}
                  </text>
                  <text
                    x="0"
                    y={yTop + (yBottom - yTop) / 2}
                    style={{ transformOrigin: `0 ${yTop + (yBottom - yTop) / 2}px` }}
                    className="targetReportAgpLabel"
                    filter="url(#solid)"
                  >
                    <FormattedMessage {...messages.labels.targetRange} />
                  </text>
                  <text
                    x="84"
                    y={yTop + (yBottom - yTop) / 2 - 8}
                    style={{ transformOrigin: `0 ${yTop + (yBottom - yTop) / 2}px` }}
                    className="targetReportAgpLabel"
                    filter="url(#solid)"
                  >
                    {conversion.unitSymbol}
                  </text>
                </g>
              )}
            </SmartMotion>
          )
        }
      </motionConfigContext.Consumer>
    );
  }


  percentiles({ series, xScale, yScale }) {
    const { conversion, showModalDay } = this.props;
    if (showModalDay) {
      return null;
    }
    const linesSeries = series.find((item) => item.id === 'lines');
    let lastPoint = linesSeries.data[linesSeries.data.length - 1];

    if (!lastPoint || !lastPoint.position.y) {
      const recordsSeries = series.find((item) => item.id === 'records');
      lastPoint = recordsSeries.data[series[0].data.length - 1];
      if (!lastPoint) {
        return null;
      }
    }

    const values = {
      p25   : lastPoint.data.fullDataInfo.p25,
      median: lastPoint.data.y,
      p75   : lastPoint.data.fullDataInfo.p75,
    };

    const fontSizeWithGap = 16;

    if (values.p25 + conversion.toDisplay(fontSizeWithGap) > values.median) {
      values.p25 = values.median - conversion.toDisplay(fontSizeWithGap);
    }

    if (values.p75 - conversion.toDisplay(fontSizeWithGap) < values.median) {
      values.p75 = values.median + conversion.toDisplay(fontSizeWithGap);
    }


    return (
      <g opacity={1}>
        {
          lastPoint && (
            <>
              <rect
                y={0}
                x={xScale(24) + 8}
                width={10}
                height={12}
                fill={this.colors.iqr}
              />
              <rect
                y={12}
                x={xScale(24) + 8}
                width={10}
                height={3}
                fill="black"
              />
              <rect
                y={15}
                x={xScale(24) + 8}
                width={10}
                height={12}
                fill={this.colors.iqr}
              />
              <text
                y={6}
                x={xScale(24) + 20}
                fontSize="8"
              >75%
              </text>
              <text
                y={16}
                x={xScale(24) + 20}
                fontSize="8"
                fontWeight="bold"
              >50%
              </text>
              <text
                y={26}
                x={xScale(24) + 20}
                fontSize="8"
              >25%
              </text>
              <text
                x={xScale(24) + 8}
                y={yScale(values.p75) + 4}
              >75%
              </text>
              <text
                x={xScale(24) + 8}
                y={yScale(values.median) + 4}
                fontWeight="bold"
              >50%
              </text>
              <text
                x={xScale(24) + 8}
                y={yScale(values.p25) + 4}
              >25%
              </text>
            </>
          )
        }
      </g>
    );
  }


  render() {
    const { direction, conversion, standards, maxValue } = this.props;
    const margin = {
      top   : 30,
      right : direction === 'rtl' ? 70 : 40,
      bottom: 50,
      left  : direction === 'ltr' ? 70 : 40,
    };
    const axisY = {
      tickSize    : 0,
      tickPadding : 0,
      tickRotation: 0,
      tickValues  : [
        patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].veryLow,
        patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].veryHigh,
        patientResultsConstants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES[conversion.unit].maxValue,
      ],
    };

    return (
      <div className={`nivoChart ${styles.root}`}>
        <div
          className={`nivoChart__inner ${styles.root__inner}`}
        >
          <AutoSizer>
            {
              ({ height, width }) => (
                <Line
                  data={this.chartData}
                  height={height}
                  width={width}
                  margin={margin}
                  stacked={false}
                  xScale={{ type: 'linear', min: 0, max: 24, reverse: direction === 'rtl' }}
                  yScale={{
                    type: 'linear',
                    min : 0,
                    max : Math.max(conversion.toDisplay(standards.maxValue), maxValue),
                  }}
                  gridYValues={axisY.tickValues}
                  axisTop={null}
                  axisRight={direction === 'ltr' ? null : axisY}
                  axisLeft={direction === 'rtl' ? null : axisY}
                  axisBottom={{
                    tickSize  : 5,
                    tickValues: range(24.5),
                    format    : (value) => (value % 3 ? null : moment(value, 'hh').format('LT').toString().replaceAll(':00', '')),
                  }}
                  gridXValues={[0, 24]}
                  lineWidth={2}
                  pointSize={7}
                  tooltip={() => null}
                  useMesh
                  pointColor={(line) => this.getPointColor(line)}
                  pointSymbol={(props) => this.renderPoint(props)}
                  colors={(line) => line.color}
                  layers={[
                    'markers',
                    this.renderAreaBetweenIQR.bind(this),
                    this.renderTargetZone.bind(this),
                    this.percentiles.bind(this),
                    this.renderDashedLine.bind(this),
                    'grid', 'axes', 'areas', 'lines', 'slices', 'mesh', 'points',
                    'legends',
                  ]}
                  theme={{
                    grid: {
                      line: {
                        stroke     : '#bfc0c2',
                        strokeWidth: 0.5,
                      },
                    },
                  }}
                />
              )
            }
          </AutoSizer>
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(BloodGlucoseProfileAgpReportChart);
