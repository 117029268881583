exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1wIBZ{position:relative;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;background-color:#fff;border-radius:50%;width:40px;width:4rem;height:40px;height:4rem;overflow:hidden}._3Cgzp{background-color:rgba(0,0,0,0);border:1px dashed #c9cfdc}._1SfL3{width:auto;max-height:100%}._15HA3{display:block;color:#fff;font-size:15px;font-size:1.5rem;font-weight:700}", ""]);

// exports
exports.locals = {
	"avatar": "_1wIBZ",
	"avatar--inactive": "_3Cgzp",
	"avatar__img": "_1SfL3",
	"avatar__initials": "_15HA3"
};