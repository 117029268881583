exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2oabn{margin-top:32px;text-align:center;line-height:1.2}._2oXIv{margin-bottom:-16px}._2oXIv,._1Lfwb{position:relative;width:60px;width:6rem;height:60px;height:6rem}._1Lfwb{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;background-color:#c9cfdc;border-radius:8px;overflow:hidden}._2wVLq{width:auto;max-height:100%}.tqggK{display:block;color:#fff;font-size:32px;font-size:3.2rem;font-weight:700;height:41px;height:4.1rem}._1nO4y{color:#30a8ff;font-size:24px;font-size:2.4rem;font-weight:700;padding:3rem 0}@media (min-width:1024px){._1nO4y{font-size:3.6rem}}._3KWew{margin-top:56px;margin-bottom:40px;font-size:16px;font-size:1.6rem;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._1mH1o{width:32px;margin:4px;padding:0 0 0 10px}.NjFtD{margin-top:16px}._17A9X{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;margin-bottom:8px}", ""]);

// exports
exports.locals = {
	"root": "_2oabn",
	"avatar": "_2oXIv",
	"avatar__imgWrapper": "_1Lfwb",
	"avatar__img": "_2wVLq",
	"avatar__initials": "tqggK",
	"clinicName": "_1nO4y",
	"info": "_3KWew",
	"numberInput": "_1mH1o",
	"licenceButton": "NjFtD",
	"header": "_17A9X"
};