import { call, getContext, put, select, takeLatest, take, all, takeEvery } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import camelCase from 'lodash/camelCase';
import find from 'lodash/find';
import moment from 'moment';
import { LocalError } from 'helpers/errorTypes';
import { hybridDecrypt } from 'helpers/crypto';
import {
  mapContourCloudData,
  prepareContourCloudDataForImport,
} from 'helpers/externalDataSources';
import ApiService from 'services/ApiService';
import App from 'modules/App';
import CloudDrive from 'modules/CloudDrive';
import ContourCloud from 'modules/ContourCloud';
import Statistics from 'modules/Statistics';
import * as accountSelectors from 'modules/Account/selectors';
import * as accountConstants from 'modules/Account/constants';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import messages from './messages';


function* errorContourCloud(payload) {
  const { err, externalDataSourceId } = payload;
  const activeProfileType = yield select(accountSelectors.activeProfileType);
  if (activeProfileType !== accountConstants.PROFILE_TYPES.PWD) {
    return err;
  }
  const externalDataSources = yield select(selectors.dataSources);
  const dataSource = find(externalDataSources, { externalDataSourceId });
  if (dataSource.dataSourceProvider === constants.DATA_SOURCES_TYPE_NAME.CONTOUR_CLOUD) {
    if (err && err.status === 401) {
      yield put(App.actions.openModal(ContourCloud.constants.REAUTH_TO_CC_MODAL));
      const action = yield take([
        App.actionTypes.CLOSE_MODAL,
        ContourCloud.actionTypes.CONTOUR_CLOUD_REAUTH_SUCCESS,
      ]);
      if (action.type !== ContourCloud.actionTypes.CONTOUR_CLOUD_REAUTH_SUCCESS) {
        return err;
      }
    }
  }
  return err;
}

function* fetchDataSources() {
  try {
    const requestUrl = '/api/ExternalDataSources';
    const dataSources = yield call(ApiService.regionalRequest, requestUrl);
    yield put(actions.fetchDataSourcesSuccess(dataSources));
  } catch (err) {
    yield put(actions.fetchDataSourcesError(err));
    yield call(App.dispatchError, err);
  }
}


function* fetchConnectedDataSources() {
  try {
    const requestUrl = '/api/Account/me/externalDataSources';
    const connectedDataSources = yield call(ApiService.regionalRequest, requestUrl);
    yield put(actions.fetchConnectedDataSourcesSuccess(connectedDataSources));
  } catch (err) {
    yield put(actions.fetchConnectedDataSourcesError(err));
    yield call(App.dispatchError, err);
  }
}


function* fetchBGMExternalDataSources({
  sharingRequestId,
  externalDataSourceId,
  lastImportUTC,
  tokens = {},
}) {
  try {
    const options = {
      headers: {
        ExternalDataSourceAccessToken: tokens.accessToken,
      },
    };
    let requestUrl;
    if (!sharingRequestId) {
      requestUrl = `/api/ExternalDataSources/${externalDataSourceId}/bgm`;
    } else if (!tokens) {
      requestUrl = `/api/ExternalDataSources/${sharingRequestId}/${externalDataSourceId}/bgm`;
    } else {
      requestUrl = `/api/ExternalDataSourcesControllerNew/${sharingRequestId}/${externalDataSourceId}/bgm`;
    }

    if (lastImportUTC) {
      requestUrl += `?lastImportTimestamp=${lastImportUTC}`;
    }

    return yield call(ApiService.regionalRequest, requestUrl, options);
  } catch (err) {
    return yield call(errorContourCloud, {
      err,
      externalDataSourceId,
      onSuccess: call(fetchBGMExternalDataSources, { sharingRequestId,
        externalDataSourceId,
        lastImportUTC,
        tokens }),
    });
  }
}


function* fetchBGRelatedExternalDataSources({
  sharingRequestId,
  externalDataSourceId,
  externalDataSources,
  phiSet,
  tokens,
}) {
  try {
    const options = {
      headers: {
        ExternalDataSourceAccessToken: tokens.accessToken,
      },
    };
    let requestUrl;
    if (!sharingRequestId) {
      requestUrl = `/api/ExternalDataSources/${externalDataSourceId}/bgrelateddata`;
    } else if (!tokens) {
      requestUrl = `/api/ExternalDataSources/${sharingRequestId}/${externalDataSourceId}/bgrelateddata`;
    } else {
      requestUrl = `/api/ExternalDataSourcesControllerNew/${sharingRequestId}/${externalDataSourceId}/bgrelateddata`;
    }

    const dataSource = find(externalDataSources, { externalDataSourceId });
    if (dataSource) {
      const lastImportUTC = get(phiSet, `importsRelatedData.${camelCase(dataSource.dataSourceProvider)}.lastImportUTC`);
      if (lastImportUTC) {
        requestUrl += `?lastImportTimestamp=${lastImportUTC}`;
      }
    }

    return yield call(ApiService.regionalRequest, requestUrl, options);
  } catch (err) {
    return yield call(errorContourCloud, {
      err,
      externalDataSourceId,
      onSuccess: call(fetchBGRelatedExternalDataSources, {
        sharingRequestId,
        externalDataSourceId,
        externalDataSources,
        phiSet,
        tokens,
      }),
    });
  }
}


function* storeReadingsExternalDataSource({ externalDataSourceId, importData, importId, accessToken }) {
  try {
    const requestUrl = `/api/ExternalDataSources/${externalDataSourceId}/bgm`;
    return yield call(ApiService.regionalRequest, requestUrl, {
      method : 'POST',
      headers: {
        ExternalDataSourceAccessToken: accessToken,
      },
      body: {
        ...importData,
        importId,
      },
    });
  } catch (err) {
    return err;
  }
}


function* storeHealthDataExternalDataSource({ externalDataSourceId, healthData, accessToken }) {
  try {
    const requestUrl = `/api/ExternalDataSources/${externalDataSourceId}/healthData`;
    return yield call(ApiService.regionalRequest, requestUrl, {
      method : 'PUT',
      headers: {
        ExternalDataSourceAccessToken: accessToken,
      },
      body: {
        ...healthData,
      },
    });
  } catch (err) {
    return yield call(errorContourCloud, {
      err,
      externalDataSourceId,
      onSuccess: call(storeHealthDataExternalDataSource, {
        externalDataSourceId,
        healthData,
        accessToken,
      }),
    });
  }
}


function* assignToken({ payload }) {
  const { dataSourceExchangeToken, controlId, scope = 'Sharing' } = payload;
  try {
    const requestUrl = '/api/ExternalDataSources/oauth/AssignToken';
    const response = yield call(ApiService.regionalRequest, requestUrl, {
      method: 'POST',
      body  : {
        dataSourceExchangeToken,
        scope,
        controlId,
      },
    });
    yield put(actions.assignTokenSuccess(response));
  } catch (err) {
    yield put(actions.assignTokenError(err));
  }
}


function* setDataSourceExchangeToken({ payload }) {
  const { externalDataSourceId, encryptedDataSourceExchangeToken } = payload;
  try {
    const requestUrl = `/api/ExternalDataSources/${externalDataSourceId}/setDataSourceExchangeToken`;
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'POST',
      body  : {
        encryptedDataSourceExchangeToken,
      },
    });
    yield put(actions.setDataSourceExchangeTokenSuccess(externalDataSourceId, encryptedDataSourceExchangeToken));
  } catch (err) {
    yield put(actions.setDataSourceExchangeTokenError(err));
  }
}


function* getRedirectUrl() {
  const domain = yield getContext('domain');
  const getUrl = yield getContext('getUrl');
  return `${domain}${getUrl('data-source-auth')}`;
  // console.log('CHANGE REDIRECT URI');
  // return `https://dev.glucocontro.online${getUrl('data-source-auth')}`;
}


function* getContourCloudAuthUrl(redirectUri, state) {
  const apps = yield getContext('apps');
  const { contourCloud } = apps;
  return (
    'https://connectqa.ascensia.com/AscensiaConnect/OAuth2/Authorize'
    + `?client_id=${contourCloud}`
    + '&response_type=code'
    + '&scope=Contour'
    + `&state=${state}`
    + '&language_code=EN'
    + '&country_code=US'
    + `&redirect_uri=${redirectUri}`
  );
}


function* authorize({ payload }) {
  try {
    const { provider, authType } = payload;
    const account = yield select(accountSelectors.account);
    const { accountId } = account || {};
    const state = JSON.stringify({
      p: provider,
      t: authType,
      ...(accountId && { i: accountId }),
    });
    const redirectUrl = yield call(getRedirectUrl);
    let url;

    switch (provider) {
      case 'ContourCloud': {
        url = yield call(getContourCloudAuthUrl, redirectUrl, state);
        break;
      }
      default: {
        const err = new LocalError({ code: 'UnknownDataSource' });
        yield put(actions.authorizeError(err));
      }
    }

    const externalRedirect = yield getContext('externalRedirect');
    externalRedirect(url);
  } catch (err) {
    yield put(actions.authorizeError(err));
    yield call(App.dispatchError, err);
  }
}


function* connect({ payload }) {
  try {
    if (!process.env.BROWSER) {
      return;
    }

    const { authorizationCode, referenceId, dataSourceProvider } = payload;
    const redirectUrl = yield call(getRedirectUrl);
    const requestURL = '/api/ExternalDataSources/oauth/connect';
    const connectedDataSource = yield call(ApiService.regionalRequest, requestURL, {
      method: 'POST',
      body  : {
        authorizationCode,
        referenceId,
        dataSourceProvider,
        redirectUrl,
      },
    });

    yield put(actions.connectSuccess(connectedDataSource));
  } catch (err) {
    yield put(actions.connectError(err));
    yield call(App.dispatchError, err);
  }
}


function* disconnect({ payload }) {
  try {
    if (!process.env.BROWSER) {
      return;
    }

    const { accountExternalDataSourceId } = payload;
    const requestURL = '/api/ExternalDataSources/oauth/disconnect';
    yield call(ApiService.regionalRequest, requestURL, {
      method: 'POST',
      body  : {
        accountExternalDataSourceId,
      },
    });

    yield put(actions.disconnectSuccess(accountExternalDataSourceId));
  } catch (err) {
    yield put(actions.disconnectError(err));
    yield call(App.dispatchError, err);
  }
}


function* storeExternalDataSourcesReadings(readings, payload) {
  const {
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    successAction,
    storageProvider,
    accessToken,
    activePatient,
    activeClinicMembership,
    standards,
    passphrase,
    timeBeforeSync,
  } = payload;

  if (isEmpty(readings)) {
    return phiSet;
  }

  const importData = prepareContourCloudDataForImport(readings);
  yield put(
    CloudDrive.actions.storeReadings(
      importData,
      phiSet,
      phiSetDocumentId,
      { phiSetReferenceKey, storageProvider, accessToken },
      successAction,
      timeBeforeSync,
    ),
  );
  const storeResult = yield take([
    CloudDrive.actionTypes.STORE_READINGS_SUCCESS,
    CloudDrive.actionTypes.STORE_READINGS_ERROR,
  ]);
  if (storeResult.type === CloudDrive.actionTypes.STORE_READINGS_ERROR) {
    yield put(actions.syncError(storeResult.payload));
    return phiSet;
  }

  if (activeClinicMembership) {
    yield put(Statistics.actions.sendStatisticsForClinic(
      activePatient, phiSet, importData, standards, activeClinicMembership,
    ));
  } else {
    yield put(Statistics.actions.sendStatistics(activePatient, phiSet, importData, standards, passphrase));
  }

  const updatedPhiSet = get(storeResult, 'payload.updatedPhiSet');
  return updatedPhiSet;
}


function* storeExternalDataSourcesRelatedData(bgRelated, payload) {
  const {
    phiSet,
    phiSetDocumentId,
    phiSetReferenceKey,
    successAction,
    storageProvider,
    accessToken,
    timeBeforeSync,
  } = payload;

  if (!isEmpty(bgRelated)) {
    yield put(
      CloudDrive.actions.storeRelatedData(
        bgRelated,
        phiSet,
        phiSetDocumentId,
        { phiSetReferenceKey, storageProvider, accessToken },
        successAction,
        timeBeforeSync,
      ),
    );
    const storeResult = yield take([
      CloudDrive.actionTypes.STORE_RELATED_DATA_SUCCESS,
      CloudDrive.actionTypes.STORE_RELATED_DATA_ERROR,
    ]);
    if (storeResult.type === CloudDrive.actionTypes.STORE_RELATED_DATA_ERROR) {
      yield put(actions.syncError(storeResult.payload));
    }
  }
}


function mapDataFromExternalDataSources(successResults) {
  const readings = [];

  successResults.forEach((item) => {
    switch (item.dataSource.dataSourceProvider) {
      case constants.DATA_SOURCES_TYPE_NAME.CONTOUR_CLOUD:
        readings.push(mapContourCloudData(item.result));
        break;

      default:
        break;
    }
  });

  return flatten(readings);
}


function* sync({ payload }) {
  try {
    const {
      phiSet,
      externalDataSourcesIds,
      sharingRequestId,
      successRelatedDataAction,
      externalDataSourcesTokens = [],
      showErrorAlert = true,
    } = payload;

    const lastImportUTC = get(phiSet, 'imports.contourcloud.lastImportUTC');
    const externalDataSources = yield select(selectors.dataSources);

    const timeBeforeSync = +moment().locale('en').format('X');

    const callsExternalDataSources = yield externalDataSourcesIds.map(
      (externalDataSourceId) => call(fetchBGMExternalDataSources, {
        externalDataSourceId,
        sharingRequestId,
        lastImportUTC,
        tokens: find(externalDataSourcesTokens, { externalDataSourceId }),
      }),
    );
    const callsBgRelatedExternalDataSources = yield externalDataSourcesIds.map(
      (externalDataSourceId) => call(fetchBGRelatedExternalDataSources, {
        externalDataSourceId,
        sharingRequestId,
        externalDataSources,
        phiSet,
        tokens: find(externalDataSourcesTokens, { externalDataSourceId }),
      }),
    );

    const [results, resultsRelated] = yield all([
      all(callsExternalDataSources),
      all(callsBgRelatedExternalDataSources),
    ]);

    if (!results.length && !resultsRelated.length) {
      yield put(actions.syncSuccess(phiSet));
      return;
    }

    const successResults = [];
    const errorResults = [];

    const successResultsRelated = [];
    const errorResultsRelated = [];

    results.forEach((result, index) => {
      if (result.businessError) {
        errorResults.push({
          dataSource: find(externalDataSources, { externalDataSourceId: externalDataSourcesIds[index] }),
          result,
        });
      } else {
        successResults.push({
          dataSource: find(externalDataSources, { externalDataSourceId: externalDataSourcesIds[index] }),
          result,
        });
      }
    });

    resultsRelated.forEach((result, index) => {
      if (result.businessError) {
        errorResultsRelated.push({
          dataSource: find(externalDataSources, { externalDataSourceId: externalDataSourcesIds[index] }),
          result,
        });
      } else {
        successResultsRelated.push({
          dataSource: find(externalDataSources, { externalDataSourceId: externalDataSourcesIds[index] }),
          result    : result.bgRelatedData,
        });
      }
    });

    const readings = mapDataFromExternalDataSources(successResults);

    const updatedPhiSet = yield call(storeExternalDataSourcesReadings, readings, {
      ...payload,
      timeBeforeSync,
    });

    const callsStoreBgRelatedData = yield successResultsRelated.map(
      (bgRelatedData) => call(storeExternalDataSourcesRelatedData, bgRelatedData, {
        ...payload,
        successAction: successRelatedDataAction,
        timeBeforeSync,
      }),
    );

    yield all(callsStoreBgRelatedData);

    if (!isEmpty(errorResults) || !isEmpty(errorResultsRelated)) {
      if (showErrorAlert) {
        let errorMessage = messages.errors.fetchData;
        if (!isEmpty(errorResults) && get(errorResults[0],
          'result.businessError.code') === 'CannotGetMeterDataAssosciatedWithReading'
        ) {
          errorMessage = messages.errors.businessErrors.CannotGetMeterDataAssosciatedWithReading;
        }
        yield put(App.actions.setAlert({
          type         : 'error',
          message      : errorMessage,
          messageValues: {
            dataSource: 'ContourCloud', // TODO - change to items from api
          },
          actions: [{
            message: messages.buttons.tryAgain,
            action : actions.sync(payload),
          }],
        }));
      }
      yield put(actions.syncError(null, errorResults, errorResultsRelated));
    } else {
      yield put(actions.syncSuccess(updatedPhiSet));
    }
  } catch (err) {
    yield put(actions.syncError(err));
  }
}

function* storeReadings({ payload }) {
  try {
    const { importData, importId, accessToken } = payload;

    const externalDataSourcesIds = yield select(accountSelectors.externalDataSourcesIds);

    const callStoreReadings = yield externalDataSourcesIds.map(
      (externalDataSourceId) => call(storeReadingsExternalDataSource, {
        externalDataSourceId,
        importData,
        importId,
        accessToken,
      }),
    );

    const results = yield all(callStoreReadings);

    const successResults = [];
    const errorResults = [];

    results.forEach((result, index) => {
      if (result.businessError) {
        errorResults.push({ dataSourceId: externalDataSourcesIds[index], result });
      } else {
        successResults.push({ dataSourceId: externalDataSourcesIds[index], result });
      }
    });

    if (externalDataSourcesIds.length === successResults.length) {
      yield put(actions.storeReadingsSuccess());
    } else {
      yield put(actions.storeReadingsError());
    }

  } catch (err) {
    yield put(actions.storeReadingsError(err));
  }
}

function* storeHealthData({ payload }) {
  try {
    const { treatmentType, diabetesType, accessToken } = payload;
    const externalDataSourcesIds = yield select(accountSelectors.externalDataSourcesIds);

    const callStoreReadings = yield externalDataSourcesIds.map(
      (externalDataSourceId) => call(storeHealthDataExternalDataSource, {
        externalDataSourceId,
        healthData: {
          treatmentType,
          diabetesType,
        },
        accessToken,
      }),
    );

    const results = yield all(callStoreReadings);

    const successResults = [];
    const errorResults = [];

    results.forEach((result, index) => {
      if (result.businessError) {
        errorResults.push({ dataSourceId: externalDataSourcesIds[index], result });
      } else {
        successResults.push({ dataSourceId: externalDataSourcesIds[index], result });
      }
    });

    if (externalDataSourcesIds.length === successResults.length) {
      yield put(actions.storeHealthDataSuccess());
    } else {
      yield put(actions.storeHealthDataError());
    }

  } catch (err) {
    yield put(actions.storeHealthDataError(err));
  }
}


function* fetchVault() {
  try {
    const requestUrl = '/api/ExternalDataSources/vault';
    const vault = yield call(ApiService.regionalRequest, requestUrl);
    yield put(actions.fetchVaultSuccess(vault));
  } catch (err) {
    yield call(App.dispatchError, err, messages);
    yield put(actions.fetchVaultError(err));
  }
}


function* storeToken({ payload }) {
  const { secret, externalDataSourceId, controlId, scope = 'sharing' } = payload;
  try {
    const externalDataSources = yield select(selectors.dataSources);
    const dataSource = find(externalDataSources, { externalDataSourceId });
    if (!dataSource) {
      yield put(actions.storeTokenSuccess());
      return;
    }
    const body = {
      externalDataSourceId,
      controlId,
      scope,
    };
    if (secret) {
      body.payload = JSON.stringify({ PWDSecret: secret });
    }

    const requestUrl = '/api/ExternalDataSources/oauth/token';
    const response = yield call(ApiService.regionalRequest, requestUrl, {
      method: 'POST',
      body,
    });
    yield put(actions.storeTokenSuccess(response));
  } catch (err) {
    const result = yield call(errorContourCloud, {
      err,
      externalDataSourceId,
      onSuccess: call(storeToken, {
        externalDataSourceId,
        payload,
      }),
    });
    if (isEqual(result, err)) {
      yield call(App.dispatchError, err, messages);
      yield put(actions.storeTokenError(err));
    }
  }
}


function* getAccessToken({ payload }) {
  const { encryptedDataSourceExchangeToken, prvKeyObj } = payload;
  try {
    const dataSourceExchangeToken = yield call(hybridDecrypt, encryptedDataSourceExchangeToken, prvKeyObj);
    const response = yield call(ApiService.regionalRequest, '/api/ExternalDataSources/oauth/accesstoken', {
      method: 'POST',
      body  : {
        dataSourceExchangeToken,
      },
    });
    yield put(actions.getAccessTokenSuccess(response));
  } catch (err) {
    yield put(actions.getAccessTokenError(err));
  }
}


function* getPermanentRefreshToken({ payload }) {
  const { externalDataSourceId, accountExternalDataSourceId, payload: bodyPayload, scope = 'Self' } = payload;
  try {
    const response = yield call(ApiService.regionalRequest, '/api/ExternalDataSources/oauth/token', {
      method: 'POST',
      body  : {
        payload  : bodyPayload,
        controlId: accountExternalDataSourceId,
        externalDataSourceId,
        scope,
      },
    });
    yield put(actions.getPermanentRefreshTokenSuccess(response));
  } catch (err) {
    yield put(actions.getPermanentRefreshTokenError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* sagas() {
  yield takeLatest(actionTypes.FETCH_DATA_SOURCES, fetchDataSources);
  yield takeLatest(
    actionTypes.FETCH_CONNECTED_DATA_SOURCES,
    fetchConnectedDataSources,
  );
  yield takeLatest(actionTypes.AUTHORIZE, authorize);
  yield takeLatest(actionTypes.CONNECT, connect);
  yield takeLatest(actionTypes.DISCONNECT, disconnect);
  yield takeLatest(actionTypes.SYNC, sync);
  yield takeLatest(actionTypes.STORE_READINGS, storeReadings);
  yield takeLatest(actionTypes.STORE_HEALTH_DATA, storeHealthData);
  yield takeLatest(actionTypes.FETCH_VAULT, fetchVault);
  yield takeEvery(actionTypes.STORE_TOKEN, storeToken);
  yield takeEvery(actionTypes.GET_ACCESS_TOKEN, getAccessToken);
  yield takeEvery(actionTypes.GET_PERMANENT_REFRESH_TOKEN, getPermanentRefreshToken);
  yield takeEvery(actionTypes.ASSIGN_TOKEN, assignToken);
  yield takeEvery(actionTypes.SET_DATA_SOURCE_EXCHANGE_TOKEN, setDataSourceExchangeToken);
}

export default [sagas];
