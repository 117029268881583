exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".vge_3{position:relative;width:250px;width:25rem;height:250px;height:25rem;border:4rem solid rgba(48,168,255,.1);border-radius:50%}.vge_3._29Jmk{border-color:rgba(246,86,99,.1)}._3s1il{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;position:absolute;top:-27.5px;top:-2.75rem;left:-27.5px;left:-2.75rem;width:225px;width:22.5rem;height:225px;height:22.5rem;border:1.5rem solid #30a8ff;border-radius:50%}._29Jmk>._3s1il{border-color:#f65663}._1x2qY{width:80px;width:8rem;height:80px;height:8rem;fill:#30a8ff}._29Jmk ._1x2qY{fill:#f65663}._3KC66{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:250px;width:25rem;height:251px;height:25.1rem}._2rFom{width:225px;width:22.5rem;height:225px;height:22.5rem}._18Iuq{width:160px;width:16rem;height:150px;height:15rem;fill:#30a8ff}._2iKfy{margin-top:3rem;border:1px solid #e0e8f2;border-radius:5px}._3ti31{padding:1.5rem;border-bottom:1px solid #e0e8f2}.Di91p{padding:1.5rem 1.5rem 0}._32X7H{max-height:310px;max-height:31rem;overflow-y:auto;padding:0 1.5rem 1.5rem}", ""]);

// exports
exports.locals = {
	"indicatorBackground": "vge_3",
	"error": "_29Jmk",
	"indicator": "_3s1il",
	"indicatorIcon": "_1x2qY",
	"loaderContainer": "_3KC66",
	"loader": "_2rFom",
	"loaderIcon": "_18Iuq",
	"patients": "_2iKfy",
	"patients__head": "_3ti31",
	"patients__searchContainer": "Di91p",
	"patients__listContainer": "_32X7H"
};