import { v4 as uuid } from 'uuid';
import moment from 'moment';
import filter from 'lodash/filter';
import find from 'lodash/find';
import union from 'lodash/union';
import without from 'lodash/without';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import hasIn from 'lodash/hasIn';
import set from 'lodash/set';
import omit from 'lodash/omit';
import unset from 'lodash/unset';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import { DEFAULT_LOCALE, APP_LOCALE_LANGUAGES_MAP } from 'localeConstants';
import { registerAction, unregisterAction } from 'helpers/reducerTools';
import { SIGN_IN_SUCCESS, SIGN_OUT_SUCCESS } from 'modules/Account/actionTypes';
import * as actionTypes from './actionTypes';


const initialState = {
  reportsState              : null,
  printMode                 : false,
  isClientInitialized       : false,
  alerts                    : [],
  alertsTimeThreshold       : 0,
  dismissedAlerts           : [],
  systemAlerts              : [],
  systemAlertsSettings      : [],
  locale                    : DEFAULT_LOCALE,
  direction                 : 'ltr',
  regionName                : null,
  originalRegionName        : null,
  regions                   : [],
  countries                 : [],
  countrySettings           : {},
  devices                   : [],
  caseTypes                 : [],
  languages                 : [],
  translations              : {},
  localizationResources     : null,
  forms                     : {},
  openDropdownId            : null,
  openModalId               : null,
  openFloatingModals        : [],
  activeFloatingModalId     : null,
  route                     : { name: 'sign-in', action: 'PUSH' },
  isSignalRConnected        : false,
  signalRActiveNotifications: [],
  featureToggles            : [],
  fetching                  : [],
  downloading               : [],
  uploads                   : [],
  errors                    : [],
  wsState                   : 3,
  cookiesAllowed            : false,
};


export default function reducer(state = { ...initialState }, action = {}) {

  switch (action.type) {

    case actionTypes.SET_CLIENT_IS_INITIALIZED: {
      return {
        ...state,
        isClientInitialized: true,
      };
    }

    // REGION CONTEXT -------------------------------------------------------------------------------------------------

    case actionTypes.SET_ORIGINAL_REGION: {
      const { originalRegionName } = action.payload;
      const regionName = state.regionName || originalRegionName;
      return {
        ...state,
        originalRegionName,
        regionName,
      };
    }

    case actionTypes.SET_REGION_SUCCESS: {
      const { regionName } = action.payload;
      return {
        ...state,
        regionName,
      };
    }

    // LOCALE ----------------------------------------------------------------------------------------------------------

    case actionTypes.SET_LOCALE: {
      const { locale } = action.payload;
      const { languages } = state;
      const code = APP_LOCALE_LANGUAGES_MAP[locale] || locale;
      const language = find(languages, { code });
      if (!language) {
        return state;
      }
      const direction = language.isRtl ? 'rtl' : 'ltr';
      return {
        ...state,
        locale,
        direction,
      };
    }

    // ROUTE -----------------------------------------------------------------------------------------------------------

    case actionTypes.SET_ROUTE: {
      return {
        ...state,
        route: {
          printMode: false,
          ...initialState.route,
          ...action.payload,
        },
      };
    }

    // TRANSLATIONS ----------------------------------------------------------------------------------------------------

    case actionTypes.SET_TRANSLATIONS: {
      const { translations } = action.payload;
      return {
        ...state,
        translations,
      };
    }

    // ALERTS ----------------------------------------------------------------------------------------------------------

    case actionTypes.SET_ALERT: {
      const alert = {
        id       : uuid(),
        timestamp: +moment.utc().locale('en').format('X'),
        ...action.payload.alert,
      };
      return {
        ...state,
        alerts: [...state.alerts, alert],
      };
    }

    case actionTypes.DISPLAY_ALERTS: {
      return {
        ...state,
        alerts: [],
      };
    }

    case actionTypes.DISMISS_ALERT: {
      const { alert } = action.payload;
      const alertName = isObject(alert.message)
        ? `${alert.message.id}_${JSON.stringify(alert.messageValues)}`
        : alert.message;
      return {
        ...state,
        dismissedAlerts: [...state.dismissedAlerts, alertName],
      };
    }

    case actionTypes.DISMISS_SYSTEM_ALERT: {
      const { alert } = action.payload;
      const systemAlerts = filter(state.systemAlerts, (a) => a.alertId !== alert.alertId);
      return {
        ...state,
        systemAlerts,
      };
    }

    // FORMS -----------------------------------------------------------------------------------------------------------

    case actionTypes.CLEAR_FORM: {
      const forms = { ...state.forms };
      unset(forms, action.payload.formName);
      return {
        ...state,
        forms,
      };
    }

    case actionTypes.COMMIT_FORM: {
      const forms = { ...state.forms };
      const form = { ...get(forms, action.payload.formName) };
      form.processing = false;
      unset(form, 'originalValues');
      set(forms, action.payload.formName, form);
      return {
        ...state,
        forms,
      };
    }

    case actionTypes.RESET_FORM: {
      const forms = { ...state.forms };
      const form = { ...get(forms, action.payload.formName, { values: {} }) };
      form.processing = false;
      form.values = { ...form.values, ...form.originalValues };
      unset(form, 'originalValues');
      set(forms, action.payload.formName, form);
      return {
        ...state,
        forms,
      };
    }

    case actionTypes.SET_FORM_INPUT_VALUE: {
      const { input } = action.payload;
      const forms = { ...state.forms };
      const form = { ...get(forms, action.payload.formName, { values: {} }) };
      form.processing = false;
      // [input.id]: get(form.values, input.id, ''),
      const originalValues = { ...form.originalValues };
      if (!hasIn(originalValues, input.id)) {
        set(originalValues, [input.id], get(form.values, input.id, ''));
      } else if (originalValues[input.id] === input.value) {
        unset(originalValues, [input.id]);
      }
      form.originalValues = isEmpty(originalValues) ? undefined : originalValues;
      if (action.payload.input.value === undefined) {
        unset(form.values, input.id);
      } else {
        form.values = { ...form.values, [input.id]: input.value };
      }
      set(forms, action.payload.formName, form);
      return {
        ...state,
        forms,
      };
    }

    case actionTypes.SET_FORM_VALUES: {
      const forms = { ...state.forms };
      const form = { ...get(forms, action.payload.formName, { values: {} }) };
      form.processing = false;
      form.values = { ...form.values, ...action.payload.values };
      set(forms, action.payload.formName, form);
      return {
        ...state,
        forms,
      };
    }

    case actionTypes.UNSET_FORM_VALUES: {
      const forms = { ...state.forms };
      const form = { ...get(forms, action.payload.formName, { values: {} }) };
      form.processing = false;
      form.values = omit(form.values, action.payload.ids);
      set(forms, action.payload.formName, form);
      return {
        ...state,
        forms,
      };
    }

    case actionTypes.SET_FORM_ERRORS: {
      const forms = { ...state.forms };
      const form = { ...get(forms, action.payload.formName, { errors: {} }) };
      form.processing = false;
      form.errors = action.payload.errors;
      set(forms, action.payload.formName, form);
      return {
        ...state,
        forms,
      };
    }

    case actionTypes.SET_FORM_BE_ERRORS: {
      const forms = { ...state.forms };
      const form = { ...get(forms, action.payload.formName, { BEErrors: {} }) };
      form.processing = false;
      form.BEErrors = action.payload.errors;
      set(forms, action.payload.formName, form);
      return {
        ...state,
        forms,
      };
    }

    case actionTypes.CLEAR_FORM_ERROR: {
      const forms = { ...state.forms };
      const form = { ...get(forms, action.payload.formName, { errors: {} }) };
      form.processing = false;
      unset(form.errors, action.payload.error);
      set(forms, action.payload.formName, form);

      return {
        ...state,
        forms,
      };
    }

    case actionTypes.SET_FORM_CONTEXT_DATA: {
      const forms = { ...state.forms };
      const form = { ...get(forms, action.payload.formName, { contextData: {} }) };
      form.processing = false;
      form.contextData = action.payload.contextData;
      set(forms, action.payload.formName, form);
      return {
        ...state,
        forms,
      };
    }

    case actionTypes.START_FORM_PROCESSING: {
      const forms = { ...state.forms };
      const form = { ...get(forms, action.payload.formName, {}) };
      form.processing = true;
      set(forms, action.payload.formName, form);
      return {
        ...state,
        forms,
        alertsTimeThreshold: +moment.utc().locale('en').format('X'),
      };
    }

    // DROPDOWN --------------------------------------------------------------------------------------------------------

    case actionTypes.OPEN_DROPDOWN: {
      return {
        ...state,
        openDropdownId: action.payload.dropdownId,
      };
    }
    case actionTypes.CLOSE_DROPDOWN: {
      return {
        ...state,
        openDropdownId: null,
      };
    }

    // MODALS ----------------------------------------------------------------------------------------------------------

    case actionTypes.OPEN_MODAL: {
      return {
        ...state,
        openModalId: action.payload.modalId,
      };
    }
    case actionTypes.CLOSE_MODAL: {
      return {
        ...state,
        openModalId: null,
      };
    }

    // FLOATING MODALS ----------------------------------------------------------------------------------------------------------

    case actionTypes.OPEN_FLOATING_MODAL: {
      const { floatingModalId, relatedTo, place, position } = action.payload;
      const openFloatingModals = [...state.openFloatingModals];
      const idx = findIndex(openFloatingModals, { floatingModalId });
      if (idx < 0) {
        openFloatingModals.push({ floatingModalId, relatedTo, place, position });
      } else {
        openFloatingModals[idx] = { floatingModalId, relatedTo, place, position };
      }
      const activeFloatingModalId = floatingModalId;
      return {
        ...state,
        openFloatingModals,
        activeFloatingModalId,
      };
    }
    case actionTypes.ACTIVATE_FLOATING_MODAL: {
      const { floatingModalId: activeFloatingModalId } = action.payload;
      return {
        ...state,
        activeFloatingModalId,
      };
    }
    case actionTypes.CLOSE_FLOATING_MODAL: {
      const { floatingModalId } = action.payload;
      const openFloatingModals = filter(state.openFloatingModals, (ofm) => ofm.floatingModalId !== floatingModalId);
      return {
        ...state,
        openFloatingModals,
      };
    }

    // DICTIONARIES  ---------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_REGIONS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_REGIONS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_REGIONS),
      };
    }
    case actionTypes.FETCH_REGIONS_SUCCESS: {
      const { regions } = action.payload;
      return {
        ...state,
        regions,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_REGIONS),
      };
    }
    case actionTypes.FETCH_REGIONS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_REGIONS),
        errors  : registerAction(state.errors, actionTypes.FETCH_REGIONS),
      };
    }

    case actionTypes.FETCH_COUNTRIES: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_COUNTRIES),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_COUNTRIES),
      };
    }
    case actionTypes.FETCH_COUNTRIES_SUCCESS: {
      const { countries } = action.payload;
      return {
        ...state,
        countries,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_COUNTRIES),
      };
    }
    case actionTypes.FETCH_COUNTRIES_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_COUNTRIES),
        errors  : registerAction(state.errors, actionTypes.FETCH_COUNTRIES),
      };
    }

    case actionTypes.FETCH_COUNTRY_SETTINGS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_COUNTRY_SETTINGS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_COUNTRY_SETTINGS),
      };
    }
    case actionTypes.FETCH_COUNTRY_SETTINGS_SUCCESS: {
      const { countrySettings } = action.payload;
      return {
        ...state,
        countrySettings,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_COUNTRY_SETTINGS),
      };
    }
    case actionTypes.FETCH_COUNTRY_SETTINGS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_COUNTRY_SETTINGS),
        errors  : registerAction(state.errors, actionTypes.FETCH_COUNTRY_SETTINGS),
      };
    }

    case actionTypes.FETCH_DEVICES: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_DEVICES),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_DEVICES),
      };
    }
    case actionTypes.FETCH_DEVICES_SUCCESS: {
      const { devices, caseTypes } = action.payload;
      return {
        ...state,
        devices,
        caseTypes,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_DEVICES),
      };
    }
    case actionTypes.FETCH_DEVICES_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_DEVICES),
        errors  : registerAction(state.errors, actionTypes.FETCH_DEVICES),
      };
    }

    case actionTypes.FETCH_LANGUAGES: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_LANGUAGES),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_LANGUAGES),
      };
    }
    case actionTypes.FETCH_LANGUAGES_SUCCESS: {
      const { languages } = action.payload;
      return {
        ...state,
        languages,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_LANGUAGES),
      };
    }
    case actionTypes.FETCH_LANGUAGES_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_LANGUAGES),
        errors  : registerAction(state.errors, actionTypes.FETCH_LANGUAGES),
      };
    }

    case actionTypes.FETCH_LOCALIZATION_RESOURCES: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_LOCALIZATION_RESOURCES),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_LOCALIZATION_RESOURCES),
      };
    }
    case actionTypes.FETCH_LOCALIZATION_RESOURCES_SUCCESS: {
      const { localizationResources } = action.payload;
      return {
        ...state,
        localizationResources,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_LOCALIZATION_RESOURCES),
      };
    }
    case actionTypes.FETCH_LOCALIZATION_RESOURCES_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_LOCALIZATION_RESOURCES),
        errors  : registerAction(state.errors, actionTypes.FETCH_LOCALIZATION_RESOURCES),
      };
    }

    case actionTypes.SET_PRINT_MODE: {
      const { printMode } = action.payload;
      return {
        ...state,
        printMode,
      };
    }

    // DOWNLOAD --------------------------------------------------------------------------------------------------------

    case actionTypes.DOWNLOAD: {
      return {
        ...state,
        downloading: registerAction(state.downloading, action.payload.id),
      };
    }
    case actionTypes.DOWNLOAD_SUCCESS: {
      return {
        ...state,
        downloading: unregisterAction(state.downloading, action.payload.id),
      };
    }
    case actionTypes.DOWNLOAD_ERROR: {
      return {
        ...state,
        downloading: unregisterAction(state.downloading, action.payload.id),
        error      : action.error,
      };
    }

    // UPLOAD ----------------------------------------------------------------------------------------------------------

    case actionTypes.UPLOAD: {
      return {
        ...state,
        uploads: [...state.uploads, { progress: 0, meta: action.meta }],
      };
    }
    case actionTypes.UPLOAD_PROGRESS: {
      const { uploads } = state;
      const fileIdx = findIndex(uploads, (upload) => get(upload, 'meta.file') === get(action, 'meta.file'));
      if (fileIdx < 0) {
        return state;
      }
      uploads[fileIdx] = { progress: action.payload, meta: action.meta };

      return {
        ...state,
        uploads: [...uploads],
      };
    }
    case actionTypes.UPLOAD_SUCCESS: {
      const { uploads } = state;
      const fileIdx = findIndex(uploads, (upload) => get(upload, 'meta.file') === get(action, 'meta.file'));
      if (fileIdx >= 0) {
        uploads[fileIdx] = action.payload;
      } else {
        uploads.push(action.payload);
      }

      return {
        ...state,
        uploads: [...uploads],
      };
    }
    case actionTypes.UPLOAD_ERROR: {
      const { uploads } = state;
      const fileIdx = findIndex(uploads, (upload) => get(upload, 'meta.file') === get(action, 'meta.file'));
      if (fileIdx < 0) {
        return state;
      }
      uploads[fileIdx] = { ...uploads[fileIdx], meta: action.meta, error: action.payload };

      return {
        ...state,
        uploads: [...uploads],
        error  : action.error,
      };
    }
    case actionTypes.REMOVE_UPLOAD: {
      const { uploads } = state;
      return {
        ...state,
        uploads: filter(uploads, (upload) => upload.id !== action.payload.id),
      };
    }
    case actionTypes.CLEAR_UPLOADS: {
      return {
        ...state,
        uploads: [],
      };
    }

    // SIGNALR ---------------------------------------------------------------------------------------------------------

    case actionTypes.SIGNALR_SET_CONNECTED: {
      return {
        ...state,
        isSignalRConnected: true,
      };
    }

    case actionTypes.SIGNALR_SET_DISCONNECTED: {
      return {
        ...state,
        signalRActiveNotifications: [],
        isSignalRConnected        : false,
      };
    }

    // WEBSOCKET -------------------------------------------------------------------------------------------------------

    case actionTypes.WEBSOCKET_STORE_STATE: {
      return {
        ...state,
        wsState: action.payload.state,
      };
    }

    // BackOffice UPDATE -----------------------------------------------------------------------------------------------

    case actionTypes.SET_NEW_CONFIGURATION_VERSION: {
      const { countries, regions, countrySettings, localizationResources } = action.payload;
      return {
        ...state,
        countries,
        regions,
        countrySettings,
        localizationResources,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case SIGN_IN_SUCCESS: {
      return {
        ...state,
        forms: {},
      };
    }

    case SIGN_OUT_SUCCESS: {
      return {
        ...state,
        alerts              : [],
        alertsTimeThreshold : 0,
        dismissedAlerts     : [],
        systemAlerts        : [],
        systemAlertsSettings: [],
        forms               : {},
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_FEATURE_TOGGLE: {
      if (action.payload.value) {
        return {
          ...state,
          featureToggles: union(state.featureToggles, [action.payload.name]),
        };
      }
      return {
        ...state,
        featureToggles: without(state.featureToggles, action.payload.name),
      };
    }

    // SYSTEM ALERTS ---------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS),
      };
    }
    case actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS_SUCCESS: {
      const { systemAlertsSettings } = action.payload;
      return {
        ...state,
        systemAlertsSettings,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS),
      };
    }
    case actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS),
        errors  : registerAction(state.errors, actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS),
      };
    }


    case actionTypes.FETCH_SYSTEM_ALERTS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_SYSTEM_ALERTS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_SYSTEM_ALERTS),
      };
    }
    case actionTypes.FETCH_SYSTEM_ALERTS_SUCCESS: {
      const { systemAlerts } = action.payload;
      return {
        ...state,
        systemAlerts,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_SYSTEM_ALERTS),
      };
    }
    case actionTypes.FETCH_SYSTEM_ALERTS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_SYSTEM_ALERTS),
        errors  : registerAction(state.errors, actionTypes.FETCH_SYSTEM_ALERTS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_ALLOW_COOKIES: {
      return {
        ...state,
        cookiesAllowed: true,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default:
      return state;

  }
}
